var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "MPRN card border-primary font-large-1" },
    [
      _c("ValidationProvider", {
        attrs: { rules: "required", name: "MPRN", immediate: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ valid, errors }) {
              return [
                _c("b-input", {
                  staticClass: "mprn-number",
                  attrs: {
                    state: errors[0] ? false : valid ? true : null,
                    disabled: !_vm.editing,
                    type: "number",
                    placeholder: "Enter MPRN number.",
                  },
                  model: {
                    value: _vm.meter.mprn,
                    callback: function ($$v) {
                      _vm.$set(_vm.meter, "mprn", $$v)
                    },
                    expression: "meter.mprn",
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: "inputLiveFeedback" } },
                  [_vm._v("\n      " + _vm._s(errors[0]) + "\n    ")]
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      rules: "required",
                      name: "Postcode",
                      immediate: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ valid, errors }) {
                          return [
                            _c("b-form-input", {
                              attrs: {
                                id: "Postcode",
                                disabled: !_vm.editing,
                                state: errors[0] ? false : valid ? true : null,
                                placeholder: "Enter postcode",
                              },
                              model: {
                                value: _vm.meter.postcode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.meter, "postcode", $$v)
                                },
                                expression: "meter.postcode",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "inputLiveFeedback" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(errors[0]) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }