<template>
  <div class="ContactDetails">
    <b-row>
      <b-col
        class="detail-options"
        cols="6"
      >
        <b>Main Contact:</b>
      </b-col>

      <b-col
        class="detail-options"
        cols="6"
      >
        <b>{{ primaryContactDetails.title || "" }} {{ primaryContactDetails.full_name || "" }}</b>
      </b-col>
    </b-row>
    <b-row>

      <b-col
        class="detail-options"
        cols="6"
      >

        <b>Next Callback:</b>

      </b-col>

      <b-col
        class="detail-options"
        cols="6"
      >
        <b v-if="latestCallback">
          {{ latestCallback }}
        </b>
        <b v-else>No Callback Found</b>

      </b-col>
    </b-row>
  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  BAvatar, BAvatarGroup, BButton, BCol, BFormGroup, BRow, BTooltip,
} from 'bootstrap-vue'

import Button from '@/views/components/button/Button'

import { getUserData } from '@/auth/utils'
import axios from 'axios'
import store from '@/store'
import { bus } from '@/main'

export default {
  name: 'ContactDetails',
  components: {
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
    BAvatar,
    BAvatarGroup,
    BTooltip,
    ToastificationContent,
    Button,
  },
  directives: {},
  data() {
    return {
      title: 'Contact',
      CallButton: false,
      transitions: {
        showNumber: true,
        showOutcome: true,
      },
      ts: 0,
      datetime: null,
      company_type: null,
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        minDate: new Date(),
        weekNumbers: false,
        static: true,
      },
      // Map
      center: { lat: 10.0, lng: 10.0 },
      markers: [
        { position: { lat: 10.0, lng: 10.0 } },
        { position: { lat: 11.0, lng: 11.0 } },
      ],
      select1: [],
      outcomes: null,
      select2: [],
      notInterested: [
        { label: 'Too Many Calls', value: 1 },
        { label: 'Use Other Broker', value: 2 },
        { label: 'Just Agreed A Contract', value: 3 },
        { label: 'A new contract recently live', value: 4 },
        { label: 'Call Closer to the time', value: 5 },
        { label: 'Customer Requests Removal', value: 6 }, // Show dialog for data removal request
      ],
      avatars: [
        { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
        { avatar: "['fas', 'phone']", fullName: 'Add Collaborator' },
      ],
    }
  },
  computed: {
    activeUserInfo() {
      return getUserData
    },

    latestCallback() {
      console.log(this.ts)
      return store.getters['company/getLatestCallback']
    },

    primaryContactDetails() {
      return store.getters['company/getPrimaryContact']
    },
    primaryContactNumber() {
      return store.getters['company/getPrimaryContactNumber']
    },

    getOutcomes() {
      const Outcomes = store.getters['company/getOutcomeList']
      return Outcomes
    },
    recordContactDetails() {
      console.log(this.ts)
      return store.getters['company/getCompany']
    },

  },
  created() {
    const self = this
    bus.$on('prospect:saveCallback', () => {
      store.dispatch('company/setCallbacks')
    })
  },
  methods: {
    getOutcomeList(id = null) {
      if (id != null) {
        return this.getOutcomes.filter(outcome => outcome.parent_id == id)
      }
      return this.getOutcomes.filter(outcome => outcome.parent_id == null)
    },
    saveCallback() {
      const self = this
      if (self.datetime === null) {
        self.showToast('danger', 'You need to update your call back!', 'Error', 'AlertOctagonIcon')
      } else {
        axios.defaults.headers.common.Authorization = `Bearer ${document.querySelector('meta[name="csrf-token"]').getAttribute('content')}`
        axios.post('/api/callbacks', {
          company_id: this.recordContactDetails.id,
          id: this.activeUserInfo().sub,
          callback: this.datetime,
          type: 1,
        }).then(response => {
          // TODO: Add validation for Blank
          if (self.select1 > 0) {
            self.saveOutcomes()
          } else {
            self.showToast('primary', 'Call Back Updated', 'Notification', 'BellIcon')
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },

    saveOutcomes() {
      const self = this
      if (this.datetime === null) {
        self.showToast('danger', 'You need to update your call back!', 'Error', 'AlertOctagonIcon')
      } else {
        axios.defaults.headers.common.Authorization = `Bearer ${document.querySelector('meta[name="csrf-token"]').getAttribute('content')}`
        axios.post('/api/outcomes', {
          company_id: this.recordContactDetails.id,
          id: this.activeUserInfo().sub,
          // outcome: this.saveOutcomes(),
          outcome: this.select1,
          suboutcome: this.select2,
          type: 1,
        }).then(response => {
          self.showToast('primary', 'Call Outcome Logged & Call Back Updated')
        }).catch(error => {
          console.log(error)
        })
      }
    },
    showToast(variant, text = '', title = 'Notification', icon = 'BellIcon', timeout = 2000) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        timeout,
      })
    },
    checkSelection() {
      return this.getOutcomeList(this.select1)
    },
  },

}

</script>

<style scoped>

</style>
