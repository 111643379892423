<template>
    <div class="SendLOA">
        <b-card>
            <b-row>
                <v-select
                    v-model="selectContact"
                    :options="contacts"
                    :reduce="full_name=>full_name.id"
                    class="selectOptions col-12"
                    close-on-select
                    label="full_name"
                    placeholder="Select Contact to Send Document to."
                    searchable
                />
                <!--                <v-select v-model = "selectContact" :options = "Contacts" class = "selectOptions col-12" closeOnSelect placeholder = "Select Contact to Send DocGen to." searchable>-->
                <!--                </v-select>-->
            </b-row>
            <b-row>
                <v-select
                    v-model="selectDocument"
                    :options="LoaType"
                    class="selectOptions col-12"
                    close-on-select
                    placeholder="Select Document Template"
                    searchable
                />
            </b-row>
            <b-row>
                <b-button
                    class="text-center"
                    @click="SendViaEmail">

                    <!--                    <font-awesome-icon :icon="['fas', 'envelope-open-text']"/>-->
                    Generate Document
                </b-button>

            </b-row>
        </b-card>

    </div>

</template>
<style lang="scss">
@import 'Generate';
</style>

<script>
import vSelect from 'vue-select'
import {BButton, BCol, BFormSelect, BRow,} from 'bootstrap-vue'
import store from '@/store'
import axios from 'axios'

export default {
    name: 'Generate',
    components: {
        BRow,
        BCol,
        BButton,
        BFormSelect,
        vSelect,
    },

    data() {
        return {

            selectDocument: [],
            LoaType: [
                {label: 'Level 1 Letter of Authority', value: 1},
                {label: 'Level 2 Letter of Authority', value: 2},
                // {label: 'E-ON Level 1 Letter of Authority', value: 3}, //this brings the "notInterested" options into view
                // {label: 'E-ON Level 2 Letter of Authority', value: 4},
                // {label: 'Exclusive Letter of Authority', value: 5},

            ],
            selectContact: [],

        }
    },
    computed: {
        company() {
            return this.$store.state.company.companyDetails.business_name
        },
        templates() {
            return Object.values(store.getters['loa/getTemplates']).map(doc => ({
                value: doc.templateId,
                label: doc.name,
            }))
        },
        contacts() {
            return store.getters['company/getContacts']
        },

    },
    mounted() {
        store.dispatch('loa/setLOATemplates')
    },
    methods: {
        SendViaEmail() {
            const self = this
            axios.post('/document', {
                type: self.selectDocument.value,
                contact: this.selectContact,
            }).then(response => {
                const linkSource = `data:application/pdf;base64,${response.data}`
                const downloadLink = document.createElement('a')
                const contactName = self.contacts.filter(contact => contact.id === self.selectContact)
                const fileName = `${self.company} - ${self.selectDocument.label}.pdf`
                downloadLink.href = linkSource
                downloadLink.download = fileName
                downloadLink.click()
            }).catch(error => console.log(error))
        },
        SendViaDocusign() {
        },
    },
}

</script>
