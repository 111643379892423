var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "audio-player-root" } }, [
    _c("div", [
      _c("audio", { ref: "player", attrs: { id: _vm.playerid } }, [
        _c("source", { attrs: { src: _vm.url, type: "audio/mpeg" } }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "w-3/4 bg-gray-200 border border-gray-300 px-2 pt-2 mt-4 shadow-md",
        staticStyle: { margin: "auto" },
      },
      [
        _c(
          "div",
          {
            staticClass: "inline-flex flex-wrap w-full max-w-5xl",
            attrs: { id: "player-row" },
          },
          [
            _c(
              "div",
              { staticClass: "flex-initial pr-3", attrs: { id: "button-div" } },
              [
                _c(
                  "svg",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isPlaying,
                        expression: "!isPlaying",
                      },
                    ],
                    staticClass: "play-button text-gray-400",
                    class: {
                      "text-orange-600": _vm.audioLoaded,
                      "hover:text-orange-400": _vm.audioLoaded,
                      "cursor-pointer": _vm.audioLoaded,
                    },
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 20 20",
                      fill: "currentColor",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleAudio()
                      },
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d: "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z",
                        "clip-rule": "evenodd",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPlaying,
                        expression: "isPlaying",
                      },
                    ],
                    staticClass:
                      "play-button text-orange-400 hover:text-orange-400 cursor-pointer",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 20 20",
                      fill: "currentColor",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleAudio()
                      },
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z",
                        "clip-rule": "evenodd",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex-grow bg-white border border-blue-200",
                attrs: { id: "progress-bar" },
              },
              [
                _c(
                  "div",
                  { staticClass: "overlay-container relative w-full h-full" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.playbackTime,
                          expression: "playbackTime",
                        },
                      ],
                      staticClass: "slider w-full h-full",
                      attrs: {
                        type: "range",
                        min: "0",
                        max: _vm.audioDuration,
                        id: "position",
                        name: "position",
                      },
                      domProps: { value: _vm.playbackTime },
                      on: {
                        __r: function ($event) {
                          _vm.playbackTime = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.audioLoaded,
                            expression: "!audioLoaded",
                          },
                        ],
                        staticClass:
                          "w-full absolute top-0 bottom-0 right-0 left-0 px-2 pointer-events-none",
                        staticStyle: { color: "#94bcec" },
                      },
                      [
                        _vm._v(
                          "\n                        Loading please wait...\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.audioLoaded,
                            expression: "audioLoaded",
                          },
                        ],
                        staticClass:
                          "flex w-full justify-between absolute top-0 bottom-0 right-0 left-0 px-2 pointer-events-none",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-sm",
                            staticStyle: { color: "#94bcec" },
                            domProps: { innerHTML: _vm._s(_vm.elapsedTime()) },
                          },
                          [_vm._v(" 00:00 ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "text-sm",
                            staticStyle: { color: "#94bcec" },
                            domProps: { innerHTML: _vm._s(_vm.totalTime()) },
                          },
                          [_vm._v(" 00:00 ")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }