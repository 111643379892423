export default {

    SET_LOADING(state, newLoadingStatus) {
        state.loadingStatus = newLoadingStatus
    },
    SET_PROVIDER_INFO(state, providers) {
        state.providers = providers
    },
    SET_SECTOR_INFO(state, sectors) {
        state.sectors = sectors
    },
    SET_SECTOR_CHART_DATA(state, data) {
        state.charts.sector = data
    },
    SET_PROVIDER_CHART_DATA(state, data) {
        state.charts.provider = data
    },
    SET_TOTAL_DATA(state, data) {
        state.totals = data
    },
    SET_BATCH_INFO(state, data) {
        state.batchinfo = data.formData.BatchInfo;
    },
    SET_FILE_INFO(state, response) {
        state.file = response.file;
        state.headers = response.headers;

        state.records = response.records.map(vs => Object.fromEntries(vs.map((v, i) => [response.headers[i], v]))).slice(0, 2);
    }
}
