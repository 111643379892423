var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddSite" },
    [
      _c(
        "b-card",
        {
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        disabled: !_vm.siteValid,
                        variant: !_vm.siteValid ? "outline-primary" : "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addSite()
                        },
                      },
                    },
                    [_vm._v("\n        Add Site\n      ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("SiteDetails", {
            ref: "siteinfo",
            on: { SiteValid: _vm.siteValid },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }