var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "AddContact" }, [
    _c(
      "div",
      { staticClass: "vs-row" },
      [
        _c(
          "form-wizard",
          [
            _c("vs-input", { attrs: { label: "Title" } }),
            _vm._v(" "),
            _c("vs-input", { attrs: { label: "First Name" } }),
            _vm._v(" "),
            _c("vs-input", { attrs: { label: "Last Name" } }),
            _vm._v(" "),
            _c("vs-input", { attrs: { label: "Position" } }),
            _vm._v(" "),
            _c("vs-input", { attrs: { label: "Landline" } }),
            _vm._v(" "),
            _c("vs-input", { attrs: { label: "Mobile" } }),
            _vm._v(" "),
            _c("vs-input", { attrs: { label: "Email Address" } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }