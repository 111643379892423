<template>
  <b-container class="MPRN card border-primary font-large-1">
    <ValidationProvider
                v-slot="{ valid, errors }"
                rules="required"
                name="MPRN"
                immediate
              >
      <b-input
        v-model="meter.mprn"
        :state = "errors[0] ? false : (valid ? true : null)"
        :disabled="!editing"
        type="number"
        class="mprn-number"
        placeholder="Enter MPRN number."
      />
      <b-form-invalid-feedback id="inputLiveFeedback">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </ValidationProvider>

    <b-form-group>
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                v-slot="{ valid, errors }"
                rules="required"
                name="Postcode"
                immediate
              >
               <b-form-input
                    id = "Postcode"
                    :disabled="!editing"
                    v-model = "meter.postcode"
                    :state = "errors[0] ? false : (valid ? true : null)"
                    placeholder = "Enter postcode"
                />
                <b-form-invalid-feedback id="inputLiveFeedback">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </b-col>
          </b-row>
    </b-form-group>
    <!--    {{ meter.mprn }}-->

  </b-container>
</template>

<script>
import { BCol, BContainer } from 'bootstrap-vue'
import store from '@/store'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {

  name: 'MPRN',
  components: {
    BCol,
    BContainer,
    ValidationObserver,
    ValidationProvider,
  },
  props:{
    editVal:{
      type: Boolean
    }
  },
  data() {
    return {
      editing: false,
    }
  },
  mounted() {
    this.editing = this.editVal;
  },
  computed: {
    meter() {
      return store.getters['company/getMeters']
    },
  },
}
</script>

<style lang = "scss">

@import "MPRN";
</style>
