<template>
  <b-form-row class="mpanBottom">
    <b-input-group>

      <template #append>
        <ValidationProvider
          v-slot="{ valid, errors }"
          rules="required"
          name="Area"
          immediate
        >
        <!--                        Distribution Area-->
        <b-input
          v-model="Area"
          :state = "errors[0] ? false : (valid ? true : null)"
          :disabled="!editing"
          class="area"
          maxlength="2"
          placeholder="Area"
          @change="checkArea"
        />
        <b-form-invalid-feedback id="inputLiveFeedback">
          {{ errors[0] }}
        </b-form-invalid-feedback>
        </ValidationProvider>
        <!--                        Unique Ref p1  - Digits 3,4,5,6 of the MPAN bottom line-->

        <ValidationProvider
          v-slot="{ valid, errors }"
          rules="required"
          name="UP1"
          immediate
        >
        <b-input
          v-model="UP1"
          :state = "errors[0] ? false : (valid ? true : null)"
          :disabled="!editing"
          class="UP1"
          maxlength="4"
          placeholder="UP1"
          @change="checkUP1"
        />
        <b-form-invalid-feedback id="inputLiveFeedback">
          {{ errors[0] }}
        </b-form-invalid-feedback>
        </ValidationProvider>
        <!--                        Unique Ref p2 - Digits 7,8,9,10 of the MPAN bottom line -->

        <ValidationProvider
          v-slot="{ valid, errors }"
          rules="required"
          name="UP2"
          immediate
        >
          <b-input
            v-model="UP2"
            :state = "errors[0] ? false : (valid ? true : null)"
            :disabled="!editing"
            class="UP2"
            maxlength="4"
            placeholder="UP2"
            @change="checkUP2"
          />
          <b-form-invalid-feedback id="inputLiveFeedback">
          {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
        <!--                        Check Code  -  Didgets 11,12,13 of the MPAN bottom line -->
        <ValidationProvider
          v-slot="{ valid, errors }"
          rules="required"
          name="CheckCode"
          immediate
        >
          <b-input
            v-model="CheckCode"
            :state = "errors[0] ? false : (valid ? true : null)"
            :disabled="!editing"
            class="CheckCode"
            maxlength="3"
            placeholder="CheckCode"
            @change="checkCode"
          />
          <b-form-invalid-feedback id="inputLiveFeedback">
          {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </template>
    </b-input-group>

  </b-form-row>
</template>

<script>
import {
  BCol, BContainer, BFormRow, BInputGroup, BRow,
} from 'bootstrap-vue'
import store from '@/store'
import { bus } from '@/main'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {

  name: 'MpanBottom',
  components: {
    BRow,
    BCol,
    BFormRow,
    BInputGroup,
    BContainer,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent
  },
  props:{
    editVal:{
      type: Boolean
    }
  },
  data() {
    return {
      editing: false,
      Area: '',
      UP1: '',
      UP2: '',
      CheckCode: '',
    }
  },
  computed: {
    meter() {
      return store.getters['company/getMeters']
    },
  },
  watch: {
    editing(newVal) {
      if (newVal === false) {
        this.meter.mpan = this.Area.concat(this.UP1, this.UP2, this.CheckCode)
      }
    },
    Area(newVal) {
      this.meter.area = '';
      this.meter.mpan = '';
      if (newVal != '') {
        this.meter.area = newVal;
        this.meter.mpan = newVal.concat(this.UP1, this.UP2, this.CheckCode);
      }
    },
    UP1(newVal) {
      this.meter.mpan = '';
      if (newVal != '') {
        this.meter.mpan = this.Area.concat(newVal, this.UP2, this.CheckCode);
    }
    },
    UP2(newVal) {
      this.meter.mpan = '';
      if (newVal != '') {
        this.meter.mpan = this.Area.concat(this.UP1, newVal, this.CheckCode);
      }
    },
    CheckCode(newVal) {
      this.meter.mpan = '';
      if (newVal != '') {
        this.meter.mpan = this.Area.concat(this.UP1, this.UP2, newVal);
      }
    },
  },
  mounted() {
    this.editing = this.editVal;
    bus.$on('Meter:Save', () => {
      // TODO: Send variables to VueX
    })
    this.Area = this.meter.mpan.slice(0, 2) || ''
    this.UP1 = this.meter.mpan.slice(2, 6) || ''
    this.UP2 = this.meter.mpan.slice(6, 10) || ''
    this.CheckCode = this.meter.mpan.slice(10, 13) || ''
  },
  methods:{
    checkArea() {
        if (this.Area.length < 2) {
          let msg = "Area must be of length 2 !!"
          this.Area = '';
          this.getErrors(msg);
          return false;
        }
    },
    checkUP1() {
      if (this.UP1.length < 4) {
          let msg = "UP1 must be of length 4 !!"
          this.UP1 = '';
          this.getErrors(msg);
          return false;
      }
    },
    checkUP2() {
      if (this.UP2.length < 4) {
          let msg = "UP2 must be of length 4 !!"
          this.UP2 = '';
          this.getErrors(msg);
          return false;
      }
    },
    checkCode() {
      if (this.CheckCode.length < 3) {
          let msg = "Checkcode must be of length 3 !!"
          this.CheckCode ='';
          this.getErrors(msg);
          return false;
      }
    },
    getErrors(msg) {
        this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Form Submission Error!',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: msg
        },
      })
    }
  }
}
</script>

<style lang = "scss">

@import "mpanBottom";
</style>
