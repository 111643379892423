var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-row",
    { staticClass: "mpanBottom" },
    [
      _c("b-input-group", {
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c("ValidationProvider", {
                  attrs: { rules: "required", name: "Area", immediate: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ valid, errors }) {
                        return [
                          _c("b-input", {
                            staticClass: "area",
                            attrs: {
                              state: errors[0] ? false : valid ? true : null,
                              disabled: !_vm.editing,
                              maxlength: "2",
                              placeholder: "Area",
                            },
                            on: { change: _vm.checkArea },
                            model: {
                              value: _vm.Area,
                              callback: function ($$v) {
                                _vm.Area = $$v
                              },
                              expression: "Area",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "inputLiveFeedback" } },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n      "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: { rules: "required", name: "UP1", immediate: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ valid, errors }) {
                        return [
                          _c("b-input", {
                            staticClass: "UP1",
                            attrs: {
                              state: errors[0] ? false : valid ? true : null,
                              disabled: !_vm.editing,
                              maxlength: "4",
                              placeholder: "UP1",
                            },
                            on: { change: _vm.checkUP1 },
                            model: {
                              value: _vm.UP1,
                              callback: function ($$v) {
                                _vm.UP1 = $$v
                              },
                              expression: "UP1",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "inputLiveFeedback" } },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n      "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: { rules: "required", name: "UP2", immediate: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ valid, errors }) {
                        return [
                          _c("b-input", {
                            staticClass: "UP2",
                            attrs: {
                              state: errors[0] ? false : valid ? true : null,
                              disabled: !_vm.editing,
                              maxlength: "4",
                              placeholder: "UP2",
                            },
                            on: { change: _vm.checkUP2 },
                            model: {
                              value: _vm.UP2,
                              callback: function ($$v) {
                                _vm.UP2 = $$v
                              },
                              expression: "UP2",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "inputLiveFeedback" } },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: {
                    rules: "required",
                    name: "CheckCode",
                    immediate: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ valid, errors }) {
                        return [
                          _c("b-input", {
                            staticClass: "CheckCode",
                            attrs: {
                              state: errors[0] ? false : valid ? true : null,
                              disabled: !_vm.editing,
                              maxlength: "3",
                              placeholder: "CheckCode",
                            },
                            on: { change: _vm.checkCode },
                            model: {
                              value: _vm.CheckCode,
                              callback: function ($$v) {
                                _vm.CheckCode = $$v
                              },
                              expression: "CheckCode",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "inputLiveFeedback" } },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }