<template>
    <div class = "Sites">

        <b-card
            v-for = "site in sites"
            :id = "'site-'+site.id"
            :key = "site.id"
            class = "m-0"
            no-body
        >

            <b-card-header
                class = "p-05"
                header-tag = "header"
                role = "tab"
            >
                <b-button-group
                    class = "btn-block"
                >
                    <b-button
                        v-b-toggle = "'accordion-'+site.id"
                        block
                        variant = "outline-primary"
                        @click = "scrollToSite('site-'+site.id)"
                    >{{ site.address1 }}<span
                        v-if = "site.address2"
                    > ,{{ site.address2 }}</span><span v-if = "site.address3">,{{
                            site.address3
                                                                              }}</span>,{{ site.postcode }}
                    </b-button>

                    <b-button
                        aria-label = "Remove"
                        variant = "outline-danger"
                        class = "delete-site"
                        :disabled = "isDeletable(site)"
                        @click = "deleteSite(site)"
                    >
                        <font-awesome-icon :icon = "['fas', 'trash']" />
                    </b-button>
                </b-button-group>
            </b-card-header>
            <b-collapse
                :id = "'accordion-'+site.id"
                :name = "'sites-accordion-'+site.id"
                accordion = "Sites"
                role = "tabpanel"
            >
                <Meters :meters = "site.meters" />
            </b-collapse>

        </b-card>

    </div>

</template>

<style lang = "scss">

@import "_Site.scss";
</style>

<script>
import {
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCol,
    BCollapse,
    BFormGroup,
    BRow,
    ButtonGroupPlugin,
    VBToggle,
    VBTooltip,
} from 'bootstrap-vue'
import Meters from '@/views/components/SitesMeters/Meters/Meters'
import store from '@/store'

import Ripple from 'vue-ripple-directive'

export default {
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    components: {
        BRow,
        BCardBody,
        BCol,
        BCard,
        BCardHeader,
        BFormGroup,
        BButton,
        VBTooltip,
        BCollapse,
        Meters,
        VBToggle,
        ButtonGroupPlugin,

    },
    data() {
        return {}
    },
    computed: {
        sites() {
            return store.getters['company/getSites']
        },
    },

    methods: {
        scrollToSite(site) {
            const el = document.getElementById(site)
            el.scrollTop = el.scrollHeight
        },
        async deleteSite(site) {
            const result = await this.$bvModal.msgBoxConfirm(
                `Do you wish to delete the following site:${site.address1}`,
                {
                    title: 'Confirm Site Deletion',
                    okVariant: 'danger',
                    okTitle: 'Delete Site',
                    cancelTitle: 'Cancel',
                },
            ).then(response => {
                if (response) {
                    store.dispatch('company/removeSite', site.id)
                }
            })
        },
        isDeletable(site) {
            return (typeof site.meters === "undefined" || site.meters.length > 0 || site.primary === 1)
        },
    },
}
</script>
