import state from './moduleDataManagerState.js'
import mutations from './moduleDataManagerMutations.js'
import actions from './moduleDataManagerActions.js'
import getters from './moduleDataManagerGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
  getters
}
