var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ContractRecord" },
    [
      _c(
        "b-row",
        {
          attrs: { slot: "no-body" },
          on: { click: _vm.openContractRecord },
          slot: "no-body",
        },
        [
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "4" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.contractData.supplier.name) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "3" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.contractData.start_date ?? "Unknown") +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "3", icon: "filter" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.contractData.end_date) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "2", icon: "filter" },
            },
            [
              _c(
                "b-button",
                { attrs: { variant: "transparent" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "handshake"], size: "2x" },
                  }),
                  _vm._v(" "),
                  _c("b-badge", { attrs: { variant: "secondary" } }, [
                    _vm._v("External"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }