var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddMeter" },
    [
      _c(
        "b-card",
        {
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        disabled: !_vm.meterValid,
                        variant: !_vm.meterValid
                          ? "outline-primary"
                          : "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addMeter()
                        },
                      },
                    },
                    [_vm._v("\n        Add Meter\n      ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("MeterDetails", {
            ref: "meterinfo",
            on: { MeterValid: _vm.meterValid },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }