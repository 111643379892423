var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { className: "MeterDetails" } },
    [
      _c(
        "ValidationObserver",
        { ref: "MeterObserver" },
        [
          _c(
            "b-form-group",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("label", { attrs: { htmlFor: "input-none" } }, [
                      _vm._v("Select Site:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "required",
                          name: "Site",
                          immediate: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    name: "site",
                                    options: _vm.sites,
                                    clearable: false,
                                    "close-on-select": "",
                                    label: "address1",
                                    state: errors.length > 0 ? false : null,
                                  },
                                  model: {
                                    value: _vm.MeterDetails.SiteID,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.MeterDetails, "SiteID", $$v)
                                    },
                                    expression: "MeterDetails.SiteID",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    attrs: {
                                      state: errors.length > 0 ? false : null,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(errors[0]) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.MeterDetails.SiteID
            ? [
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("label", { attrs: { htmlFor: "input-none" } }, [
                            _vm._v("Meter Type:"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required",
                                name: "Site",
                                immediate: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            reduce: (type) => type.id,
                                            options: _vm.MeterTypes,
                                            clearable: false,
                                            label: "title",
                                            state:
                                              errors.length > 0 ? false : null,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "option",
                                                fn: function (option) {
                                                  return [
                                                    _c(
                                                      "b-avatar",
                                                      {
                                                        attrs: {
                                                          size: "24",
                                                          variant:
                                                            option.variant,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: [
                                                                "fas",
                                                                option.icon,
                                                              ],
                                                              size: "2x",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(option.title) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.MeterDetails.Type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.MeterDetails,
                                                "Type",
                                                $$v
                                              )
                                            },
                                            expression: "MeterDetails.Type",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3254925883
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.MeterDetails.Type
                  ? _c(
                      "b-form-group",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: "required",
                                    name: "Meter",
                                    immediate: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ valid, errors }) {
                                          return [
                                            _c("identifier", {
                                              attrs: { editing: true },
                                            }),
                                            _vm._v(" "),
                                            _c("b-form-invalid-feedback", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(errors[0]) +
                                                  "\n              "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2134586203
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }