export default {
    companyDetails: [],
    collaborators: [],
    siteDetails: [
        {
            address1: null,
            address2: null,
            address3: null,
            city: null,
            company_id: null,
            county: null,
            created_at: null,
            id: null,
            locality: null,
            'no-site': '0',
            postcode: null,
            primary: 1,
            town: null,
            updated_at: null,
        },
    ],
    company_types: [],
    contactDetails: [],
    fileDetails: [],
    type: null,
    meters: [],
    outcomes: [],
    notes: [],
    loadingStatus: false,

}
