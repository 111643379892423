var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addCollaborators" },
    [
      _c("v-select", {
        staticClass: "selectUsers w-full",
        attrs: {
          options: _vm.users,
          reduce: (user) => user.id,
          selectable: (user) => user.disabled,
          label: "name",
          placeholder: "Select User(s) to Collaborate with.",
          "vs-autocomplete": "",
        },
        on: {
          input: function ($event) {
            return _vm.selectCollab()
          },
        },
        model: {
          value: _vm.selectedCollab,
          callback: function ($$v) {
            _vm.selectedCollab = $$v
          },
          expression: "selectedCollab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }