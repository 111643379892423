<template>
  <div class="Identifier">
    <MPAN v-if="Object.keys(meter).includes('mpan')" :editVal="editing"/>
    <MPRN v-if="Object.keys(meter).includes('mprn')" :editVal="editing"/>
    <SPID v-if="Object.keys(meter).includes('spid')" :editVal="editing"/>
  </div>
</template>

<script>
import MPAN from '@/views/services/broker/electric/identifier/MPAN'
import MPRN from '@/views/services/broker/gas/identifier/MPRN'
import SPID from '@/views/services/broker/water/identifier/SPID'
import store from '@/store'

export default {
  name: 'Identifier',
  components: {
    MPAN,
    MPRN,
    SPID,
  },
  computed: {
    meter() {
      return store.getters['company/getMeters']
    },
  },
  props:{
    editing:{
      Type:Boolean,
      default:false
    }
  }
}
</script>

<style lang = "scss">

@import "_Identifiers.scss";
</style>
