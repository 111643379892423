

import Vue from 'vue'

export default {
    SET_LOADING (state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },
    SET_TEMPLATES (state, companyDetails) {
        state.documentTemplates = companyDetails
    },
}

