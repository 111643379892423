var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-0", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "3" },
                  },
                  [
                    _c("label", [_vm._v("Show")]),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "per-page-selector d-inline-block mx-50",
                      attrs: { options: _vm.pageOptions, clearable: false },
                      on: {
                        "option:selected": function ($event) {
                          return _vm.changePageCount($event)
                        },
                      },
                      model: {
                        value: _vm.queryBody.per_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryBody, "per_page", $$v)
                        },
                        expression: "queryBody.per_page",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v("entries")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showFilter
                  ? _c("b-col", { attrs: { cols: "12", md: "9" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-end",
                        },
                        [
                          _c("v-selectize", {
                            staticClass: "w-50 m-1",
                            attrs: {
                              options: _vm.companyList,
                              reduce: (val) => val.value,
                              "key-by": "value",
                              label: "label",
                              keys: ["label", "value"],
                            },
                            on: { input: _vm.onSelectCompanyChange },
                            model: {
                              value: _vm.dropdownCompanyFilter,
                              callback: function ($$v) {
                                _vm.dropdownCompanyFilter = $$v
                              },
                              expression: "dropdownCompanyFilter",
                            },
                          }),
                          _vm._v(" "),
                          _vm.dropdownCompanyFilter.value !== null ||
                          _vm.company_id !== null
                            ? _c("v-selectize", {
                                staticClass: "w-50 m-1",
                                attrs: {
                                  options: _vm.contactList,
                                  reduce: (val) => val.value,
                                  "key-by": "value",
                                  label: "label",
                                  keys: ["label", "value"],
                                },
                                on: { input: _vm.onSelectCompanyContactChange },
                                model: {
                                  value: _vm.dropdownCompanyContactFilter,
                                  callback: function ($$v) {
                                    _vm.dropdownCompanyContactFilter = $$v
                                  },
                                  expression: "dropdownCompanyContactFilter",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-selectize", {
                            staticClass: "w-50 m-1",
                            attrs: {
                              options: _vm.statusOptions,
                              reduce: (val) => val.value,
                              "key-by": "value",
                              label: "label",
                              keys: ["label", "value"],
                            },
                            on: { input: _vm.onSelectChange },
                            model: {
                              value: _vm.dropdownFilter,
                              callback: function ($$v) {
                                _vm.dropdownFilter = $$v
                              },
                              expression: "dropdownFilter",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-dropdown", {
                            staticClass: "chart-dropdown",
                            attrs: {
                              "no-caret": "",
                              right: "",
                              size: "sm",
                              text: "Select Date Range",
                              "toggle-class": "p-0 mb-25 w-50",
                              variant: "transparent",
                            },
                          }),
                          _vm._v(" "),
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            attrs: {
                              config: _vm.dateTimeConfig,
                              placeholder: "DD-MM-YYYY H-i-S",
                            },
                            on: { input: _vm.onDateChange },
                            model: {
                              value: _vm.dateRange,
                              callback: function ($$v) {
                                _vm.dateRange = $$v
                              },
                              expression: "dateRange",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-container table-log-component" },
          [
            _c("b-table", {
              attrs: {
                busy: _vm.isTableLoading,
                items: _vm.itemsData,
                responsive: "true",
                fields: _vm.fields,
                "primary-key": "id",
                "no-local-sorting": "",
                "show-empty": "",
                "empty-text": "No matching records found",
              },
              on: {
                "sort-changed": _vm.customSorting,
                "row-clicked": _vm.onRowClicked,
              },
              scopedSlots: _vm._u([
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center text-primary my-2" },
                        [_c("b-spinner", { staticClass: "align-middle" })],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(properties.to)",
                  fn: function (row) {
                    return [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(row.item.properties.to) },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(properties.from)",
                  fn: function (row) {
                    return [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(row.item.properties.from),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mx-2 mb-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center justify-content-sm-start",
                    attrs: { cols: "12", sm: "6" },
                  },
                  [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(
                        "Showing " +
                          _vm._s(_vm.pageMeta.from) +
                          " to " +
                          _vm._s(_vm.pageMeta.to) +
                          " of " +
                          _vm._s(_vm.totalRows) +
                          " entries"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center justify-content-sm-end",
                    attrs: { cols: "12", sm: "6" },
                  },
                  [
                    _c("b-pagination", {
                      staticClass: "mb-0 mt-1 mt-sm-0",
                      attrs: {
                        "total-rows": _vm.totalRows,
                        "per-page": _vm.queryBody.per_page,
                        "first-number": "",
                        "last-number": "",
                        "prev-class": "prev-item",
                        "next-class": "next-item",
                      },
                      on: { input: _vm.pagination },
                      scopedSlots: _vm._u([
                        {
                          key: "prev-text",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronLeftIcon", size: "18" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "next-text",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronRightIcon", size: "18" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.queryBody.page,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryBody, "page", $$v)
                        },
                        expression: "queryBody.page",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "base-modal",
        {
          attrs: {
            id: "sms-details",
            "hide-footer": "",
            size: "lg",
            title: "SMS Details",
          },
        },
        [_c("SmsDetails", { attrs: { sid: _vm.selectedSID } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }