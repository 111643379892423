var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SitesMeters" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [_c("AddSiteMeter")], 1),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c(
                "vue-perfect-scrollbar",
                {
                  ref: "refChatLogPS",
                  staticClass: "scroll-area",
                  attrs: { settings: _vm.perfectScrollbarSettings },
                },
                [_c("Sites")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }