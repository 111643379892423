export default [
    // =============================================================================
    // Data Manager /apps/data-manager/
    // =============================================================================
    {
        name: 'data-dashboard',
        path: '/apps/data-manager/',
        component: () => import('@/views/apps/data-manager/DataDashboard'),
        meta: {
            authRequired: true,
            breadcrumb: [
                {text: 'Data Manager', active: true}
            ],
            pageTitle: 'Data Manager',
            rule: 'editor',
            no_scroll: true,
            action: 'view',
            resource: 'datamanager'
            // action: "import",
            // resource: "files"
        }
    }
]
