<template>
    <vue-perfect-scrollbar
        v-if = "notes"
        ref = "notescroller"
        :settings = "perfectScrollbarSettings"
        class = "scrollable-container media-list scroll-area note-scroller"
        tagname = "div"
    >
        <div ref = "notes" class = "ViewNotes">
            <single-note v-for = "(note,index) in notes" :key = "index" :value = "note"></single-note>
        </div>
    </vue-perfect-scrollbar>
</template>
<script>
import SingleNote from "./single-note/single-note";

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import {nextTick, ref,} from '@vue/composition-api'

export default {
    name: "view-notes",
    components: {
        SingleNote,
        VuePerfectScrollbar
    },
    data() {
        return {
            items: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
        }
    },
    computed: {
        notes() {
            return this.$store.getters["company/getNotes"];
        }
    },
    watch: {
        notes(newValue, oldValue) {

            var self = this;
            nextTick(() => {
                self.scrollToBottomInNotes()
            })
        },
    },
    mounted() {
        const self = this;
        this.$refs.notes.scrollTop = this.$refs.notes.scrollHeight;
        nextTick()
    },
    setup() {
        const notescroller = ref(null)
        const scrollToBottomInNotes = () => {
            const scrollEl = notescroller.value.$el || notescroller.value
            scrollEl.scrollTop = scrollEl.scrollHeight
        }
        return {
            notescroller,
            scrollToBottomInNotes
        }
    },
    methods: {}
    // watch: {
    //     notes: function() {
    //         this.$refs.notes.scrollTop = this.$refs.notes.scrollHeight;
    //     }
    // }

}
</script>

<style lang = "scss">
@import "./_view-notes";
</style>
