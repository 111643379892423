<template>
    <b-card class="FileUpload">
        <div>Upload Files:</div>
        <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings"
            class="file-upload-scroll scroll-area">
            <div class="upload-block" @click="selectFile">
                <input type="file" ref="fileInput" @change="onFileSelected" multiple hidden />
                <feather-icon icon="UploadCloudIcon" size="8x" class="d-block m-auto" />
                <span class="upload-help">Click or Drag & Drop to upload files</span>
            </div>
            <div v-if="fileRecords.length > 0" class="file-preview-container">
                <div v-for="(file, index) in fileRecords" :key="index" class="file-preview">
                    <div class="file-info" style="display: flex; align-items: center; justify-content: space-between;">
                        <span style="margin-right: 10px;">{{ file.name }}</span>
                        <b-progress :value="file.progress" max="100" style="flex-grow: 1; margin: 0 10px;" />
                        <b-button v-if="!file.sorted" @click="openSortAndSaveModal(file)" variant="secondary"
                            style="margin-right: 10px;">
                            Sort&Save
                        </b-button>
                        <span v-else class="sorted-text" style="margin-right: 10px;">
                            Sorted
                        </span>
                        <span @click="deleteFile(file)"
                            style="cursor: pointer; color: inherit; font-size: 1.2em; padding: 0;">
                            &times; <!-- Cross character -->
                        </span>
                    </div>

                </div>
            </div>
        </vue-perfect-scrollbar>

        <template #footer>
            <b-button block @click="uploadFiles">Upload</b-button>
        </template>

        <!-- Notification Modal -->
        <base-modal id="notification-modal" hide-footer title="Notification" @hide="clearNotification">
            <div>
                <h5 class="title">{{ notificationTitle }}</h5>
                <p class="msg">{{ notificationMessage }}</p>
            </div>
            <template #modal-footer>
                <b-button @click="closeNotification">Okay</b-button>
                <b-button variant="secondary" @click="closeNotification">Close</b-button>
            </template>
        </base-modal>



        <SortSave />
    </b-card>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BCard, BButton, BProgress } from 'bootstrap-vue';
import featherIcon from '@core/components/feather-icon/FeatherIcon';
import SortSave from './SortSave/SortSave.vue';
import { bus } from '@/main';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import BaseModal from "@/views/components/modal/BaseModal.vue";


export default {
    name: 'FileUpload',
    components: {
        BaseModal,
        BCard,
        BButton,
        BProgress,
        featherIcon,
        SortSave,
        VuePerfectScrollbar,
        ToastificationContent
    },
    mounted() {
        this.company_id = this.$route.params.clientid ? parseInt(this.$route.params.clientid, 10) : null;
        console.log("Company ID:", this.company_id); // Log to verify
        bus.$on('SaveSortModal:saveData', newData => {
            console.debug('Got Data from SaveSort Modal');

            // Find the corresponding file in fileRecords using the fileid
            const savedFile = this.fileRecords.find(file => file.id === newData.fileId);

            // Ensure the file was found, then map the modal data to this file
            if (savedFile) {
                savedFile.metadata = { ...newData.obj.data }; // Store modal data with the file
                this.$set(savedFile, 'sorted', true);

                console.debug('Updated file with modal data and marked as sorted:', savedFile);
            } else {
                console.warn('No matching file found for fileid:', newData.fileid);
            }
        });
    },
    beforeDestroy() {
        bus.$off('SortSave:dataSelected', this.handleDocTypeSelected);
    },
    data() {
        return {
            notificationTitle: '',
            notificationMessage: '',
            showNotification: false,
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
                suppressScrollX: true,
            },
            computeRefresh: false,
            componentKey: 0,
            tenancy_direction: [{
                text: 'Incoming',
                value: 'incoming',
            }, {
                text: 'Outgoing',
                value: 'outgoing',
            }],

            bill_date: [{
                text: 'Bill Date',
                value: 'bill_date',

            }],

            utility_type: [{
                text: 'Gas',
                value: 'gas',
            },
            {
                text: 'Electric',
                value: 'electric',
            },
            {
                text: 'Water',
                value: 'water',

            }],
            loa_vers: [{
                text: 'Version 1',
                value: 1,
            }, {
                text: 'Version 2',
                value: 2,
            }],
            docs: [{
                text: 'Consumption Data (HH)',
                value: 'hh',
            }, {
                text: 'Utility Bill(s)',
                value: 'bill',
            }, {
                text: 'Letter Of Authority',
                value: 'loa',
            }, {
                text: 'Change of Tenancy',
                value: 'tenancy',
            }, {
                text: 'Other',
                value: 'other',
            }],
            saveData: {
                DocType: null,
                Meter: null,
                Direction: null,
                filename: null,
                Description: null,
                Version: null,
                UtilityType: null,
                BillDate: null,
            },
            // monthSelect: {
            //     plugins: [
            //         new monthSelectPlugin({
            //             shorthand: true,
            //         })
            //     ]
            // },

            //
            configMonthYPicker: {
                dateFormat: 'M-Y',
                weekNumbers: false,
                showMonths: true,
                shorthand: true,
                altFormat: 'F Y',
                monthSelectorType: 'static',

            },

            selectedFile: [],
            recordData: [],
            fileRecords: [],
            uploadUrl: '/api/file-upload',
            uploaded: true,
            // TimeStamp: this.value.created_at,
            TimeStamp: '12/11/2022 15:00pm',
            // Name: this.value.created_by
            Name: 'BOB SMITH',
        }
    },
    computed: {
        sites() {
            return store.getters['company/getSites']
        },
        company() {
            return store.getters['company/getCompany']
        },
        meters() {
            switch (this.saveData.UtilityType) {
                case 'gas':
                case 'electric':
                case 'water':

                    return this.company.all_meters.filter(meter => meter.type === this.saveData.UtilityType)
                default:
                    return this.company.all_meters
            }
        },

    },
    methods: {
        uploadEvent(eventName, data) {
            console.log('UPLOAD EVENT ', eventName, data)
            switch (eventName) {
                case 'upload':
                    this.dataUploadComplete(data)
            }
        },
        dataUploadComplete(upload) {
            console.log(upload)
            const response = JSON.parse(upload[0].request.response)
            if (response.success) {
                bus.$emit('fileUploadComplete', response)
                // TODO: Add toaster event and bus!
            }
        },
        getHH(meters) {
            return meters.filter(meter => {
                if (meter && meter.profile) {
                    return meter.profile == '00'
                }
            })
        },
        fileName(file) {
            const savedFile = this.recordData.find(record => record.obj === file.id)

            if (savedFile) {
                if (savedFile.data.filename !== '') {
                    return savedFile.data.filename
                }
            }
            return file.name()
        },
        selectFile() {
            this.$refs.fileInput.click();
        },
        onFileSelected(event) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`; // Generate a unique ID

                this.fileRecords.push({
                    id: uniqueId, // Add the unique ID

                    file,
                    progress: 0,
                    name: file.name,
                    date: new Date(),
                });
            }
        },
        async uploadFiles() {
            for (const record of this.fileRecords) {
                const formData = new FormData();

                // Append metadata from SortSave modal (now stored in record.metadata)
                formData.append('data', JSON.stringify({
                    company_id: this.company_id,
                    filename: record.metadata.filename,  // Metadata from modal
                    DocType: record.metadata.DocType,    // Metadata from modal
                    UtilityType: record.metadata.UtilityType,
                    Entity: record.metadata.Entity,
                    Version: record.metadata.Version,
                    Direction: record.metadata.Direction,
                    BillDate: record.metadata.BillDate,
                }));

                formData.append('user', JSON.parse(localStorage.getItem('userData')).sub);
                formData.append('file', record.file);  // The file itself

                // Try uploading the file with metadata

                await this.uploadFile(formData, record);  // Assuming this returns the API response




            }

            // Clear file records after all uploads
            this.fileRecords = [];
        }


        ,
        showNotificationModal() {
            this.$bvModal.show('notification-modal');
        },
        closeNotification() {
            this.$bvModal.hide('notification-modal');
        },
        clearNotification() {
            this.notificationTitle = '';
            this.notificationMessage = '';
        },
        async uploadFile(formData, record) {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this.uploadUrl, true);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    record.progress = Math.round((event.loaded / event.total) * 100);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    console.log('Upload successful:', xhr.response);
                    this.notificationTitle = 'Success';
                    this.notificationMessage = 'File(s) uploaded successfully';
                    this.showNotificationModal();
                    bus.$emit('fileManager:fileUploaded')

                } else {
                    console.error('Upload failed:', xhr.statusText);
                    this.notificationTitle = 'Error';
                    this.notificationMessage = 'Upload error';
                    this.showNotificationModal();

                }
            };

            xhr.onerror = () => {
                console.error('Upload error:', xhr.statusText);
                this.notificationTitle = 'Error';
                this.notificationMessage = 'Upload error';
                this.showNotificationModal();
            };

            xhr.send(formData);
        },
        deleteFile(file) {
            this.fileRecords = this.fileRecords.filter((record) => record.file !== file.file);
        },
        openSortAndSaveModal(file) {
            this.selectedFile = file;
            bus.$emit('SaveSortModal:prepareData', file, this.saveData);
            this.$bvModal.show('SortSave');

        }
        ,
        handleDocTypeSelected(docType, file) {
            const index = this.fileRecords.findIndex(record => record.file === file.file);
            if (index !== -1) {
                this.fileRecords[index].docType = docType; // Update the docType for the file
            }
        },
        resetDoc() {
            this.saveData = {
                DocType: this.saveData.DocType,
                Entity: null,
                BillDate: null,
                Direction: null,
                Description: null,
                Version: null,
            }
        },
    },
};
</script>

<style lang="scss">
@import "./_FileUpload.scss";

.upload-block {
    cursor: pointer;
    text-align: center;
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

.file-preview-container {
    margin-top: 10px;
}

.file-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.upload-help {
    display: block;
    margin-top: 10px;
}

.file-type {
    font-size: 0.9em;
    color: #666;
    margin-left: 10px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.upload-block {

    /* Adjust width as needed */
    padding: 10px;
    /* Add some padding */
    text-align: center;
    /* Center text and icon */
    border: 1px dashed #ccc;
    /* Optional: add a border */
    border-radius: 5px;
    /* Optional: add rounded corners */
    cursor: pointer;
    /* Change cursor to pointer */
}

.upload-block .upload-help {
    font-size: 0.8em;
    /* Smaller font size */
    color: #666;
    /* Optional: change text color */
}
</style>
