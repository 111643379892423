<template>
    <div class="addCollaborators">

        <v-select v-model="selectedCollab" :options="users"
                  :reduce="user => user.id"
                  :selectable="user => user.disabled"
                  class="selectUsers w-full"
                  label="name"
                  placeholder="Select User(s) to Collaborate with."
                  vs-autocomplete
                  @input="selectCollab()"
        >
        </v-select>
    </div>

</template>


<script>
import Button from "@/views/components/button/Button";
import {bus} from "@/main";
import store from "@/store"
import {BAvatar, BAvatarGroup, BButton, BCol, BIcon, BIconPlus, BRow, BTooltip, VBTooltip} from 'bootstrap-vue'
import userStoreModule from "@/views/apps/user/userStoreModule";
import vSelect from 'vue-select'
import {getUserData} from "@/auth/utils";


export default {
    components: {
        Button,
        BRow,
        BCol,
        BButton,
        BAvatar,
        BAvatarGroup,
        BTooltip,
        BIcon,
        BIconPlus,
        vSelect
    },
    created() {
        store.dispatch("userList/setUsers");
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    name: "addCollaborator",
    methods: {
        getUserList(id = null) {
            if (id != null) {
                var users = store.getters["user/fetchUsers"]
                return users.filter((user) => userStoreModule);
            }
        },

        getCollaborators() {
            return store.getters["company/getCollaborators"];
        },
        selectCollab() {
            console.clear();
            store.dispatch("company/addCollaborator", {collab: this.selectedCollab, user: this.activeUserInfo.sub});
            bus.$emit("collaborator:add", this.selectedCollab);
        }
    },
    filters: {},
    computed: {
        activeUserInfo() {
            return getUserData()
        },
        users() {
            var users = store.getters['userList/getUsers'];
            var collabs = this.getCollaborators();
            users.forEach((user) => {
                if (collabs == "") {
                    user.disabled = true;
                } else if (collabs.some(collab => collab.id === user.id)) {
                    user.disabled = false;
                } else {
                    user.disabled = true;
                }
            })
            return users;
        }
    },
    data() {
        return {
            selectedCollab: [],

        }
    }
}
</script>

<style lang="scss">
@import "./_addCollaborator";

</style>
