var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100", class: [_vm.skinClasses], attrs: { id: "app" } },
    [
      _c(_vm.layout, { tag: "component" }, [_c("router-view")], 1),
      _vm._v(" "),
      _vm.isUserLoggedIn ? _c("AutoLogout") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-container" },
        [_c("ProspectModal", { ref: "details" })],
        1
      ),
      _vm._v(" "),
      _vm.enableScrollToTop ? _c("scroll-to-top") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }