<template>
    <base-modal
        id="ProspectModal"
        ref="modal"
        :active.sync="ProspectModal"
        :title="`${record.business_name} (${record.public_id})`"
        centered
        class="ProspectModal"
        size="xl"
        @close="closeModal"
    >

        <!--    region Default-->
        <div class="ProspectModal">
            <!--        <button @click="showTab('home')">Animate</button>-->
            <!--        <span v-if="prospectLoading">Loading.......</span>-->
            <div
                class="text-center"
                style="margin:0auto;"
            >
                <b-spinner
                    v-if="prospectLoading"
                    label="Loading..."
                    style="width: 3rem; height: 3rem; vertical-align: middle;"
                    variant="primary"
                />
            </div>

            <transition
                v-if="!prospectLoading"
                class="SitesMeters"
                mode="out-in"
                name="slide-fade"
            >
                <!--                    <ProspectDetails :company="company" :site="primarysite" v-if="transitions.showHome" @change-tab="showTab"></ProspectDetails>-->
                <ProspectDetails
                    v-if="transitions.showHome"
                    @change-tab="showTab"
                />
                <ProspectContacts
                    v-if="transitions.showProspectContacts"
                    :key="ts"
                    :company_id="record.id"
                    @change-tab="showTab"
                />
                <DocGen
                    v-if="transitions.showDocGen"
                    :key="ts"
                    @change-tab="showTab"
                />
                <SitesMeters
                    v-if="transitions.showSitesMeters"
                    :key="ts"
                    @change-tab="showTab"
                />
                <FileManager
                    v-if="transitions.showFileManager"
                    :key="ts"
                    @change-tab="showTab"
                />
                <Log
                    v-if="transitions.showLog"
                    :key="ts"
                    @change-tab="showTab"
                />
                <CallLogs
                    v-if="transitions.showCallLog"
                    :key="ts"
                    :company_id="prospectDetails.id"
                    @change-tab="showTab"
                />
                <SMSLogs
                    v-if="transitions.showSMSLog"
                    :key="ts"
                    :company_id="prospectDetails.id"
                    @change-tab="showTab"
                />

            </transition>

            <base-modal
                id="modal-phone"
                centered
                hide-footer
                title="Call & SMS"
                @hide="onHide"
            >
                <SoftPhone :contact="contact"/>
            </base-modal>
        </div>
        <!--    endregion-->
        <b-button
            v-if="transitions.showHome==false"
            class="back-button btn-icon rounded-circle"
            size="md"
            variant="primary"
            @click="goHome"
        >
            <font-awesome-icon
                :icon="['fas', 'chevron-left']"
            />
        </b-button>
        <div slot="modal-footer">

            <!--                <b-button color="primary" variant="primary" class="">Save & Close-->

            <!--                </b-button>-->
        </div>
    </base-modal>
</template>

<style lang="scss">
@import "_ProspectModal";

</style>
<script>

/**
 TODO:
 COSMETICS
 1. Style back button to match
 */
import store from '@/store'
import Button from '@/views/components/button/Button'
import {bus} from '@/main'
import {BButton, BCard, BCol, BDropdown, BDropdownItem, BModal, BRow, BSpinner,} from 'bootstrap-vue'
import SoftPhone from '@/views/components/SoftPhone/SoftPhone'
import ProspectDetails from './ProspectDetails/ProspectDetails'
import ProspectContacts from './ProspectContacts/ProspectContacts'
import DocGen from '@/views/components/DocGen/DocGen.vue'
import SitesMeters from '../SitesMeters/SitesMeters'
import FileManager from '../FileManager/FileManager'
import Log from '../Log/Log'
import CallLogs from '../CallLogs/CallLogs.vue'
import SMSLogs from '../SMSLogs/SMSLogs.vue'

import moment from 'moment'
import Vuelidate from "vuelidate";
import BaseModal from "@/views/components/modal/BaseModal.vue";

export default {
    name: 'ProspectModal',
    components: {
        BaseModal,
        BButton,
        BCard,
        BCol,
        BDropdown,
        BDropdownItem,
        BModal,
        BRow,
        Vuelidate,
        SoftPhone,
        Button,
        DocGen,
        ProspectDetails,
        ProspectContacts,
        SitesMeters,
        FileManager,
        Log,
        BSpinner,
        CallLogs,
        SMSLogs,
    },
    watch: {
        // whenever active changes, this function will run
        ProspectModal() {
            document.body.style.overflow = this.ProspectModal ? 'hidden' : 'scroll'
        },
    },
    data() {
        return {
            home: true,
            record: {
                id: null,
                business_name: ' ',
                public_id: ' ',
            },
            ProspectModal: false,
            contact: null,
            transitions: {
                showHome: true,
                showProspectContacts: false,
                showDocGen: false,
                showSitesMeters: false,
                showFileManager: false,
                showLog: false,
                showCallLog: false,
                showSMSLog: false,
            },
            company: [],
            sites: [],
            ts: 0,
            primarysite: [],

        }
    },
    computed: {
        prospectLoading() {
            return store.getters['company/getStatus']
        },
        prospectDetails() {
            return store.getters['company/getCompany']
        },
    },
    mounted() {
        // TODO: Only run this when modal opened?
        const self = this
        bus.$on('prospectModel:tabChange', transitions => {
            self.home = transitions.showHome
        })
        bus.$on('open:prospectModel', record => {
            store.dispatch('company/setCompany', record.id)
            self.record = record
            self.company = record
            self.ProspectModal = true
            this.$bvModal.show("ProspectModal")
        })
        bus.$on('phone:Call', contact => {
            self.CallButton = !self.CallButton
            self.contact = contact
            self.$bvModal.show('modal-phone')
        })
        bus.$on('open:prospectModelHome', () => {
            self.goBack()
        })
        // bus.$on("open:prospectModel",function(prospect) {
        //     self.ts += 1;
        //     var url = "/api/companies/" + prospect.id;
        //     axios.get(url)
        //         .then(function (response) {
        //             self.company = response.data;
        //             url = url + "/sites/";
        //             axios.get(url)
        //                 .then(function (response) {
        //                         self.sites = response.data;
        //                         self.primarysite = response.data.filter(function (site) {
        //                             return site.primary > 0;
        //                         })[0];
        //                     }
        //                 ).catch(err => console.log("error:", err.message));
        //             bus.$emit("open:prospectModelHome",self.company);
        //             // ===STILL TO INCLUDE===
        //             // Contacts
        //             // Contact_Numbers
        //
        //
        //             // Meters -
        //             //
        //             // electric_meters, gas_meters, water_meters
        //             // Contracts with a column 1 - Current 0 - other (future or past)
        //
        //         });
        // });
    },
    methods: {
        validateState(name) {
            if (this.$v[name]) {
                const { $dirty, $error } = this.$v[name]
                return $dirty ? !$error : null
            }
        },
        goHome() {
            bus.$emit('open:prospectModelHome')
        },
        closeModal() {
            this.isSlide = true;
            if (this.prospectDetails.callbacks.length > 0) {
                var callback = this.prospectDetails.callbacks.reduce((a, b) => {
                    return moment(a.callback, "DD-MM-YYYY hh:mm") > moment(b.callback, "DD-MM-YYYY hh:mm") ? a.callback : b.callback;
                })
                if (moment(callback.callback, "DD-MM-YYYY hh:mm").diff(moment()) > 0) {
                    this.record.complete = true;
                }
            }
            setTimeout(() => {
                this.isSlide = false;
            }, 0)
            store.dispatch('company/resetCompany')
            this.ProspectModal = false
            bus.$emit("calendar:refetchEvents");
        },
        onHide(evt) {
            if (evt.trigger === 'backdrop') {
                evt.preventDefault()
            }
        },
        goBack() {
            self.ts += 1
            this.showTab('home')
        },
        showTab(tab) {
            self.ts += 1
            switch (tab) {
                case 'home':
                    this.transitions.showProspectContacts = false
                    this.transitions.showDocGen = false
                    this.transitions.showSitesMeters = false
                    this.transitions.showHome = true
                    this.transitions.showFileManager = false
                    this.transitions.showLog = false
                    this.transitions.showCallLog = false
                    this.transitions.showSMSLog = false
                    break
                case 'ProspectContacts':
                    this.transitions.showHome = false
                    this.transitions.showDocGen = false
                    this.transitions.showSitesMeters = false
                    this.transitions.showProspectContacts = true
                    this.transitions.showFileManager = false
                    this.transitions.showLog = false
                    this.transitions.showCallLog = false
                    this.transitions.showSMSLog = false
                    break
                case 'DocGen':
                    this.transitions.showHome = false
                    this.transitions.showProspectContacts = false
                    this.transitions.showSitesMeters = false
                    this.transitions.showDocGen = true
                    this.transitions.showFileManager = false
                    this.transitions.showLog = false
                    this.transitions.showCallLog = false
                    this.transitions.showSMSLog = false
                    break
                case 'SitesMeters':
                    this.transitions.showHome = false
                    this.transitions.showProspectContacts = false
                    this.transitions.showDocGen = false
                    this.transitions.showSitesMeters = true
                    this.transitions.showFileManager = false
                    this.transitions.showLog = false
                    this.transitions.showCallLog = false
                    this.transitions.showSMSLog = false
                    break
                case 'FileManager':
                    this.transitions.showHome = false
                    this.transitions.showProspectContacts = false
                    this.transitions.showDocGen = false
                    this.transitions.showSitesMeters = false
                    this.transitions.showFileManager = true
                    this.transitions.showLog = false
                    this.transitions.showCallLog = false
                    this.transitions.showSMSLog = false
                    break
                case 'Log':
                    this.transitions.showHome = false
                    this.transitions.showProspectContacts = false
                    this.transitions.showDocGen = false
                    this.transitions.showSitesMeters = false
                    this.transitions.showFileManager = false
                    this.transitions.showLog = true
                    this.transitions.showCallLog = false
                    this.transitions.showSMSLog = false
                    break
                case 'CallLog':
                    this.transitions.showHome = false
                    this.transitions.showProspectContacts = false
                    this.transitions.showDocGen = false
                    this.transitions.showSitesMeters = false
                    this.transitions.showFileManager = false
                    this.transitions.showLog = false
                    this.transitions.showCallLog = true
                    this.transitions.showSMSLog = false
                    break
                case 'SMSLog':
                    this.transitions.showHome = false
                    this.transitions.showProspectContacts = false
                    this.transitions.showDocGen = false
                    this.transitions.showSitesMeters = false
                    this.transitions.showFileManager = false
                    this.transitions.showLog = false
                    this.transitions.showCallLog = false
                    this.transitions.showSMSLog = true
                    break
            }
            bus.$emit('prospectModel:tabChange', this.transitions)
        },
    },
}

</script>
