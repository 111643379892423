import axios from 'axios'

export default {
    async setProviders({commit, dispatch, state}) {

        let url = "/api/providers/";
        return axios.get(url)
            .then(function (response) {
                commit('SET_PROVIDER_INFO', response.data);
            }).catch(err => console.log("error:", err.message));
    },
    async setSectors({commit, dispatch, state}) {

        let url = "/api/sectors/";
        return axios.get(url)
            .then(function (response) {
                commit('SET_SECTOR_INFO', response.data);
            }).catch(err => console.log("error:", err.message));
    },
    async setSectorChartData({commit, dispatch, state}) {
//TODO: May need re-labelling?
        let url = "/api/reports/data-manager/sector";
        return axios.get(url)
            .then(function (response) {
                commit('SET_SECTOR_CHART_DATA', response.data);
            }).catch(err => console.log("error:", err.message));
    },
    async setProviderChartData({commit, dispatch, state}) {
//TODO: May need re-labelling?
        let url = "/api/reports/data-manager/provider";
        return axios.get(url)
            .then(function (response) {
                commit('SET_PROVIDER_CHART_DATA', response.data);
            }).catch(err => console.log("error:", err.message));
    },
    async setTotalData({commit, dispatch, state}) {
//TODO: May need re-labelling?
        let url = "/api/reports/data-manager/totals";
        return axios.get(url)
            .then(function (response) {
                commit('SET_TOTAL_DATA', response.data);
            }).catch(err => console.log("error:", err.message));
    }
}
