<template>
    <b-modal ref="bModal"
             v-bind="$attrs">
        <slot></slot>
    </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue';

export default {
    components: {
        BModal,
    },
    mounted() {
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (bvEvent.trigger === "backdrop") {
                bvEvent.preventDefault();
            }
        })
    },
    methods: {
        show() {
            this.$refs.bModal.show();
        },
        toggle() {
            this.$refs.bModal.toggle(); // Forward toggle call to b-modal
        },
        hide() {
            this.$refs.bModal.hide();
        }
    }
};
</script>
