export default [
    {
        path: '/apps/calendar',
        name: 'apps-calendar',
        component: () => import('@/views/apps/calendar/Calendar.vue'),
        meta: {
            resource: 'calendar'
        }
    },

    // *===============================================---*
    // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
    // *===============================================---*
    {
        path: '/apps/email',
        name: 'apps-email',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',

        },
    },
    {
        path: '/apps/email/:folder',
        name: 'apps-email-folder',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
            if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: '/apps/email/label/:label',
        name: 'apps-email-label',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
            if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
            else next({ name: 'error-404' })
        },
    },

    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/todo',
        name: 'apps-todo',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
        },
    },
    {
        path: '/apps/todo/:filter',
        name: 'apps-todo-filter',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
            if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: '/apps/todo/tag/:tag',
        name: 'apps-todo-tag',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
            if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
            else next({ name: 'error-404' })
        },
    },

    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/chat',
        name: 'apps-chat',
        component: () => import('@/views/apps/chat/Chat.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
    },

    // *===============================================---*
    // *--------- ECOMMERCE  ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/e-commerce/shop',
        name: 'apps-e-commerce-shop',
        component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
        meta: {
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
            pageTitle: 'Shop',
            breadcrumb: [
                {
                    text: 'ECommerce',
                },
                {
                    text: 'Shop',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/e-commerce/wishlist',
        name: 'apps-e-commerce-wishlist',
        component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
        meta: {
            pageTitle: 'Wishlist',
            contentClass: 'ecommerce-application',
            breadcrumb: [
                {
                    text: 'ECommerce',
                },
                {
                    text: 'Wishlist',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/e-commerce/checkout',
        name: 'apps-e-commerce-checkout',
        component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
        meta: {
            pageTitle: 'Checkout',
            contentClass: 'ecommerce-application',
            breadcrumb: [
                {
                    text: 'ECommerce',
                },
                {
                    text: 'Checkout',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/e-commerce/:slug',
        name: 'apps-e-commerce-product-details',
        component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
        meta: {
            pageTitle: 'Product Details',
            contentClass: 'ecommerce-application',
            breadcrumb: [
                {
                    text: 'ECommerce',
                },
                {
                    text: 'Shop',
                    active: true,
                },
                {
                    text: 'Product Details',
                    active: true,
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/users/list',
        name: 'apps-users-list',
        component: () => import('@/views/apps/user/users-list/UsersList.vue'),
        meta: {
            // resource: 'manage-users-teams',
            // action: 'users'

            resource: 'manage-users-teams',
            action: 'role'
        }
    },
    {
        path: '/apps/users/view/:id',
        name: 'apps-users-view',
        component: () => import('@/views/apps/user/users-view/UsersView.vue'),
        meta: {
            // resource: 'users',
            // action: 'view'
            resource: 'manage-users-teams',
            action: 'role'
        }
    },
    {
        path: '/apps/users/edit/:id',
        name: 'apps-users-edit',
        component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
        meta: {
            // resource: 'users',
            // action: 'edit'
            resource: 'manage-users-teams',
            action: 'role'
        }
    },
    // *===============================================---*
    // *--------- ROLES ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/roles/list',
        name: 'apps-roles-list',
        component: () => import('@/views/apps/roles/List.vue'),
        meta: {
            resource: 'manage-users-teams',
            action: 'role'
        }
    },
    {
        path: '/apps/roles/view/:id',
        name: 'apps-roles-view',
        component: () => import('@/views/apps/roles/View.vue'),

        meta: {
            // resource: 'roles',
            // action: 'view'
            resource: 'manage-users-teams',
            action: 'role'
        }
    },
    {
        path: '/apps/roles/edit/:id',
        name: 'apps-roles-edit',
        component: () => import('@/views/apps/roles/Edit.vue'),
        meta: {
            // resource: 'roles',
            // action: 'edit'
            resource: 'manage-users-teams',
            action: 'role'
        }
    },
    // *===============================================---*
    // *--------- Teams ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/teams/list',
        name: 'apps-teams-list',
        component: () => import('@/views/apps/team/List.vue'),
        meta: {
            resource: 'manage-users-teams',
            action: 'team'
        }
    },

    // *===============================================---*
    // *--------- Questions ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/questions/list',
        name: 'apps-questions-list',
        component: () => import('@/views/apps/questions/List.vue'),
        meta: {
            resource: 'managesettings',
            action: 'leadquestions'
        }
    },
    // *===============================================---*
    // *--------- Company Settings ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/company-settings',
        name: 'apps-company-settings',
        component: () => import('@/views/apps/settings/CompanySettings.vue'),
        meta: {
            resource: 'managesettings',
            action: 'companysettings'
        }
    },
    // *===============================================---*
    // *--------- Activity Logs ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/activity-logs',
        name: 'apps-logs-list',
        component: () => import('@/views/apps/activity-logs/ActivitiyLogs.vue'),
        meta: {
            resource: 'reports',
            action: 'allactivities'
        }
    },
    {
        path: '/apps/activity-logs/all-activities',
        name: 'apps-logs-activities',
        component: () => import('@/views/apps/activity-logs/Activities.vue'),
        meta: {
            resource: 'reports',
            action: 'allactivities'
        }
    },
    {
        path: '/apps/activity-logs/change-logs',
        name: 'apps-logs-modification',
        component: () => import('@/views/apps/activity-logs/ModificationLogs.vue'),
        meta: {
            resource: 'reports',
            action: 'changelogs'
        }
    },
    {
        path: '/apps/all-change-logs',
        name: 'apps-logs-all-modifications',
        component: () => import('@/views/apps/activity-logs/AllModificationLogs.vue'),
        meta: {
            resource: 'reports',
            action: 'changelogs'
        }
    },
    {
        path: '/apps/manage-kpis',
        name: 'apps-manage-kpis',
        component: () => import('@/views/apps/sales-engine/client/client-record-page/KpiList.vue'),
        meta: {
            resource: 'managesettings',
            action: 'accesskpis'
        }
    },
    // *===============================================---*
    // *-------------- Reports & Analytics ---------------*
    // *===============================================---*
    {
        path: '/apps/telephony-sms',
        name: 'apps-telephony-sms',
        component: () => import('@/views/apps/reports/telephonySms.vue'),
        meta: {
            // resource: 'telephony-and-sms',
            // action: 'list'
            resource: 'managesettings',
            action: 'companysettings'
        }
    },
    {
        path: '/apps/call-activity',
        name: 'apps-call-activity',
        component: () => import('@/views/apps/reports/callActivity.vue'),
        meta: {
            // resource: 'reports',
            // action: 'call-activity'
            resource: 'managesettings',
            action: 'companysettings'
        }
    },
    {
        path: '/apps/sms-activity',
        name: 'apps-sms-activity',
        component: () => import('@/views/apps/reports/smsActivity.vue'),
        meta: {
            // resource: 'reports',
            // action: 'sms-activity'
            resource: 'managesettings',
            action: 'companysettings'
        }
    },
    {
        path: '/apps/system-activity',
        name: 'apps-system-activity',
        component: () => import('@/views/apps/reports/systemActivity.vue'),
        meta: {
            resource: 'reports',
            action: 'system-activity'
        }
    },
    {
        path: '/apps/user-performance',
        name: 'apps-user-performance',
        component: () => import('@/views/apps/reports/userPerformance.vue'),
        meta: {
            resource: 'reports',
            action: 'user-performance'
        }
    },

    // Invoice
    {
        path: '/apps/invoice/list',
        name: 'apps-invoice-list',
        component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    },
    {
        path: '/apps/invoice/preview/:id',
        name: 'apps-invoice-preview',
        component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    },
    {
        path: '/apps/invoice/add/',
        name: 'apps-invoice-add',
        component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    },
    {
        path: '/apps/invoice/edit/:id',
        name: 'apps-invoice-edit',
        component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    },
]
