<template>
  <div className="MeterDetails">
    <!--  FIRST:-->
    <!--  &lt;!&ndash;    Import the site dropdown list for this client&ndash;&gt;-->
    <!--  THEN:-->

    <!--  &lt;!&ndash;    Add Meter, Select utility then show either mpan/mprn/spit vue files&ndash;&gt;-->
    <!--  FINALLY:-->
    <!--  the main vue will pull in the AddContract on the AddSiteMeter vue (main vue)-->

    <ValidationObserver
      ref="MeterObserver"
    >
      <b-form-group>
        <b-row>
          <b-col cols="4">
            <label htmlFor="input-none">Select Site:</label>
          </b-col>
          <b-col cols="8">
            <ValidationProvider
              #default="{ errors }"
              rules="required"
              name="Site"
              immediate
            >
              <v-select
                v-model="MeterDetails.SiteID"
                name="site"
                :options="sites"
                :clearable="false"
                close-on-select
                label="address1"
                :state="errors.length > 0 ? false:null"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>
        </b-row>
      </b-form-group>
      <template v-if="MeterDetails.SiteID">
        <b-form-group>
          <b-row>
            <b-col>
              <label htmlFor="input-none">Meter Type:</label>
            </b-col>
            <b-col cols="8">
            <ValidationProvider
              #default="{ errors }"
              rules="required"
              name="Site"
              immediate
            >
              <v-select
                v-model="MeterDetails.Type"
                :reduce="type => type.id"
                :options="MeterTypes"
                :clearable="false"
                label="title"
                :state="errors.length > 0 ? false:null"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  <b-avatar
                    size="24"
                    :variant="option.variant"
                  >
                    <font-awesome-icon
                      :icon="['fas', option.icon]"
                      size="2x"
                    />
                  </b-avatar>
                  {{ option.title }}
                </template>
              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group v-if="MeterDetails.Type">
          <b-row>
            <b-col cols="12">

              <ValidationProvider
                v-slot="{ valid, errors }"
                rules="required"
                name="Meter"
                immediate
              >
                <identifier :editing="true"/>

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form-group>
      </template>
    </ValidationObserver>
  </div>
</template>
<style lang = "scss">
@import '_MeterDetails';

</style>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import './Rules/Validation'
import { required } from '@validations'
// import draggable from 'vuedraggable'
// import Prism from 'vue-prism-component'
import {
  BButton, BCard, BCardBody, BCardHeader, BCol, BCollapse, BFormGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
// import Meters from "@/views/components/SitesMeters/Meters/Meters";
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import Identifier from '@/views/services/identifiers/Identifiers.vue'
import { bus } from '@/main'

export default {
  name: 'AddMeter',
  directives: {
    Ripple,
  },
  components: {
    Identifier,
    // draggable,
    // Prism,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    VBTooltip,
    BCollapse,
    BCardHeader,
    BCardBody,

  },
  props: [],

  data() {
    // TODO: Move MeterTypes to DB?
    return {
      readonly: false,
      MeterDetails: {
        SiteID: null,
        Type: null,
      },
      MeterTypes: [
        {
          id: 1,
          title: 'Water',
          icon: 'tint',
          variant: 'light-info',
        },
        {
          id: 2,
          title: 'Electric',
          icon: 'bolt',
          variant: 'light-warning',
        },
        {
          id: 3,
          title: 'Gas',
          icon: 'burn',
          variant: 'light-danger',
        },
      ],
    }
  },
  computed: {
    sites() {
      return store.getters['company/getSites']
    },
    // company() {
    //   this.computeRefresh
    //   return store.getters['company/getCompany']
    // },
  },
  watch: {
    'MeterDetails.Type': function (typeID) {
      switch (true) {
              case typeID != null: // checks length of address 1
                  bus.$emit('MeterDetails:MeterValid', true) // return this for valid address
                  break
              default:
                  bus.$emit('MeterDetails:MeterValid', false) // return this if invalid
                  break
      }
      bus.$emit('Meter:Edit')
      switch (typeID) {
        case 2:
          store.commit('company/SET_METER_INFO', {
            id: null,
            profile: '',
            mtc: '',
            llf: '',
            mpan: '',
            area: '',
            created_at: null,
            updated_at: null,
            current_contract_id: null,
            name: null,
            value: null,
            type: 'electric',
          })
          break
        case 3:
          store.commit('company/SET_METER_INFO', {
            id: null,
            mprn: null,
            postcode: null,
            created_at: null,
            updated_at: null,
            current_contract_id: null,
            name: null,
            value: null,
            type: 'gas',
          })
          break
        case 1:
          store.commit('company/SET_METER_INFO', {
            id: null,
            spid: null,
            spid_type: null,
            created_at: null,
            updated_at: null,
            current_contract_id: null,
            name: null,
            value: null,
            type: 'water',
          })
          break
      }
    },
  },
  methods: {
        validateState(name) {
            // debugger;
            if (this[name]) {
                const {$dirty, $error} = this[name]
                return $dirty ? !$error : null
            }
        },
    },
}
</script>
