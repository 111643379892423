var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MeterRecord" },
    [
      _c(
        "b-row",
        {
          attrs: { slot: "no-body" },
          on: {
            click: function ($event) {
              return _vm.openMeterRecord(_vm.meter.id)
            },
          },
          slot: "no-body",
        },
        [
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "2" } },
            [
              _vm.meter.mprn
                ? _c(
                    "b-avatar",
                    { attrs: { size: "36", variant: "light-danger" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "burn"], size: "2x" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.meter.mpan
                ? _c(
                    "b-avatar",
                    { attrs: { size: "36", variant: "light-warning" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "bolt"], size: "2x" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.meter.spid
                ? _c(
                    "b-avatar",
                    { attrs: { size: "36", variant: "light-info" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "tint"], size: "2x" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "3" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.meter.mprn) +
                  "\n            " +
                  _vm._s(_vm.meter.mpan) +
                  "\n            " +
                  _vm._s(_vm.meter.spid) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "2" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.currentContract.end_date
                      ? _vm.formatDate(_vm.currentContract.end_date)
                      : "Unknown"
                  ) +
                  " "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "2" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.currentContract.consumption
                      ? _vm.currentContract.consumption + " kWh"
                      : "Unknown"
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "3" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.currentContract.supplier.name
                      ? _vm.currentContract.supplier.name
                      : "Unknown"
                  ) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }