<template>
    <div class="MeterRecord">
        <b-row slot="no-body" @click="openMeterRecord(meter.id)">
            <b-col class="d-flex align-items-center" cols="2">
                <b-avatar v-if="meter.mprn" size="36" variant="light-danger">
                    <font-awesome-icon :icon="['fas', 'burn']" size="2x" />
                </b-avatar>
                <b-avatar v-if="meter.mpan" size="36" variant="light-warning">
                    <font-awesome-icon :icon="['fas', 'bolt']" size="2x" />
                </b-avatar>
                <b-avatar v-if="meter.spid" size="36" variant="light-info">

                    <font-awesome-icon :icon="['fas', 'tint']" size="2x" />
                </b-avatar>
            </b-col>
            <b-col class="d-flex align-items-center" cols="3">
                {{ meter.mprn }}
                {{ meter.mpan }}
                {{ meter.spid }}
            </b-col>
            <b-col class="d-flex align-items-center" cols="2">
                {{ currentContract.end_date ? formatDate(currentContract.end_date) :
            'Unknown' }} </b-col>
            <b-col class="d-flex align-items-center" cols="2">
                {{ currentContract.consumption ? currentContract.consumption + ' kWh' : 'Unknown' }}
            </b-col>
            <b-col class="d-flex align-items-center" cols="3">
                {{ currentContract.supplier.name ? currentContract.supplier.name : 'Unknown' }}
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss">
@import "MeterRecord";
</style>

<script>
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BCollapse,
    BFormGroup,
    BRow,
    VBToggle,
    VBTooltip,
} from 'bootstrap-vue';
import MPAN from '@/views/services/broker/electric/identifier/MPAN';
import FontAwesome from 'vue-fontawesome';
import MeterRecord from "@/views/components/SitesMeters/Meters/MeterRecordPage/MeterRecordPage.vue";
import axios from "axios";
import store from "@/store";
import moment from 'moment';

export default {
    name: "Meters",
    computed: {
        company() {
            return store.getters['company/getCompany']
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return 'Unknown';
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = String(d.getFullYear()).slice(-2); // Keep only the last two digits
            return `${day}/${month}/${year}`;
        },
        getCurrentContract() {
            const queryParams = {
                params: { current: true, meter_id: Number(this.meterid) },
            };
            axios.get('/api/contracts', queryParams)
                .then((response) => {
                    this.currentContract = response.data;
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        },
        openMeterRecord(id) {
            const self = this;
            store.dispatch('company/setMeters', this.meterid).then(async response => {
                await this.$router.push("/apps/sales-engine/meter/" + this.meterid);
            });
        },
        getVariant(date) {
            var now = moment();
            var datecheck = moment(date, "DD/MM/YYYY");
            var datediff = datecheck.diff(now, "days");
            if (datediff < 0) {
                return "danger";
            } else if (datediff < 30 && datediff > 0) {
                return "warning";
            } else {
                return "success";
            }
        }
    },
    components: {
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        VBTooltip,
        BCollapse,
        BCard,
        BCardHeader,
        BCardBody,
        VBToggle,
        MPAN,
        BBadge,
        FontAwesome,
        MeterRecord,
        BAvatar
    },
    props: ["meterid"],
    mounted() {
        var self = this;
        if (typeof this.meterid !== undefined) {
            let url = "/api/meters/" + this.meterid;
            axios.get(url)
                .then(function (response) {
                    self.meter = response.data.meter;
                    self.getCurrentContract(); // Use self to reference the correct context
                }).catch(err => console.log("error:", err.message));
        }
    },
    data() {
        return {
            meter: [],
            title: "MeterRecord",
            home: true,
            date: "25/11/2021",
            currentContract: {
                start_date: '',
                end_date: '',
                is_annual: '',
                consumption: '',
                supplier: {
                    name: '',
                }
            }
        }
    }
}
</script>
