var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "FileList" },
    [
      _vm.files
        ? [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { cols: "5" } }),
                _vm._v(" "),
                _c("b-col", { attrs: { cols: "3", "text-aligh": "right" } }, [
                  _vm._v("\n                Uploaded By\n            "),
                ]),
                _vm._v(" "),
                _c("b-col", { attrs: { cols: "3" } }, [
                  _vm._v("\n                Uploaded On\n            "),
                ]),
                _vm._v(" "),
                _c("b-col", { attrs: { cols: "1" } }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.files, function (file) {
              return _c(
                "b-row",
                {
                  key: file.id,
                  staticClass:
                    "border grid-box-item file-row min-height: 50px; @clic",
                },
                [
                  _c(
                    "b-col",
                    {
                      attrs: { cols: "5" },
                      on: {
                        click: function ($event) {
                          return _vm.FileItem(file)
                        },
                      },
                    },
                    [
                      _c("b-row", [
                        _c(
                          "strong",
                          { staticStyle: { "padding-right": "10px" } },
                          [
                            _vm._v(
                              _vm._s(file.name || "") + "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v("(" + _vm._s(file.size) + ")"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      attrs: { cols: "3" },
                      on: {
                        click: function ($event) {
                          return _vm.FileItem(file)
                        },
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-avatar", {
                            attrs: {
                              slot: "avatar",
                              "v-b-tooltip": file.user.name,
                              text: file.user.initials,
                            },
                            slot: "avatar",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      attrs: { cols: "3" },
                      on: {
                        click: function ($event) {
                          return _vm.FileItem(file)
                        },
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", {
                            staticClass: "timestamp text-muted",
                            domProps: { textContent: _vm._s(file.created_at) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("b-row", [
                        _vm.canPermittedByRoleOrUser("delete", "files")
                          ? _c(
                              "button",
                              {
                                staticClass: "close remove",
                                attrs: {
                                  "aria-label": "Remove",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(file.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { "aria-hidden": "true" } },
                                  [_vm._v("×")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ]
        : [_vm._v("\n        Loading....\n    ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }