<template>

  <div>
    <b-card no-body class="mb-0">

      <div class="m-2">
        <b-row>
          <b-col cols="6" md="6">
            <div class="text-center" v-if="loading">
              <b-spinner label="Loading..." style="width: 3rem; height: 3rem; vertical-align: middle;"
                variant="primary" />
            </div>
            <div v-else>
              <div v-if="recordingUrl !== null">
                <!-- Audio element with source -->
                <audio ref="audioPlayer" controls :src="recordingUrl"></audio>
              </div>
              <div v-else>
                <b-alert show variant="danger">Call recording is not available.</b-alert>
              </div>
            </div>
            <!-- <div
            class="w-3/4 bg-gray-200 border border-gray-300 px-2 pt-2 mt-4 shadow-md"
            style="margin: auto;"
            >
              <div id="player-row" class="inline-flex flex-wrap w-full max-w-5xl">
                  <div id="button-div" class="flex-initial pr-3">
                      <svg
                          @click="toggleAudio()"
                          v-show="!isPlaying"
                          class="play-button text-gray-400"
                          :class="{ 'text-orange-600': audioLoaded, 'hover:text-orange-400': audioLoaded, 'cursor-pointer': audioLoaded }"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                      >
                          <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                              clip-rule="evenodd"
                          />
                      </svg>
                      <svg
                          @click="toggleAudio()"
                          v-show="isPlaying"
                          class="play-button text-orange-400 hover:text-orange-400 cursor-pointer"                            
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                      >
                          <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clip-rule="evenodd"
                          />
                      </svg>
                  </div>
              </div>
            </div> -->
          </b-col>
          <b-col cols="6" md="6">
            <!-- <a
          :href="recordingUrl"
          v-text="label"
          @click.prevent="downloadItem(recordingUrl)" /> <a
          :href="recordingUrl"
          v-text="label"
          @click.prevent="downloadItem(recordingUrl)" />-->
            <b-button v-if="canPermittedByRoleOrUser('delete-call', 'calls')" @click="deleteCall"
              variant="outline-secondary" class="float-right m-1 btn btn-secondary">
              Delete
            </b-button>
            <b-button v-if="canPermittedByRoleOrUser('download-call', 'calls')"
              @click.prevent="fetchAndDownloadRecording" variant="outline-secondary"
              class="float-right m-1 btn-primary">
              <feather-icon icon="DownloadIcon" size="16" />
            </b-button>
          </b-col>
        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, BAlert } from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios';
import moment from 'moment-timezone'
import { bus } from "@/main";
import Swal from 'sweetalert2'
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {
  name: 'CallDetails',
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BAlert,
    vSelect,
  },
  props: {
    sid: {
      type: String,
      required: false,
    },
  },
  mounted() {
    this.loading = true;
    axios
      .get('/api/call-recording/' + this.sid)
      .then((response) => {
        console.log(response);
        this.recordingUrl = response.data.recording_url; // .replace(".mp3", ".wav")

        this.audio = new Audio(this.recordingUrl);
        // audio.play();
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error fetching user data:', error);
      })
      .finally(() => { this.loading = false; });
  },

  data() {
    return {
      recordingUrl: null,
      label: 'Download Recording',
      audio: null,
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
      duration: null,
      loading: false,
    }
  },
  methods: {
    async fetchAndDownloadRecording() {
      try {
        const response = await axios.get(`/api/call-recording-download`, { responseType: 'blob' });
        const blob = new Blob([response.data], { type: 'audio/mpeg' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a hidden link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'recording.mp3');

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error fetching recording:', error);
      }
    },
    async downloadItem() {
      // Fetch the audio file as a Blob
      const audioResponse = await axios.get(this.recordingUrl, { responseType: 'blob' });
      const blob = new Blob([audioResponse.data], { type: 'audio/mpeg' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a hidden link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'recording.mp3');

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      // // Create a hidden link element
      // const link = document.createElement('a');
      // link.href = this.recordingUrl;
      // link.setAttribute('download', 'recording.mp3');

      // // Append the link to the body
      // document.body.appendChild(link);

      // // Programmatically click the link to trigger the download
      // link.click();

      // // Remove the link from the document
      // document.body.removeChild(link);
      // axios.get('/api/call-recording-download')
      //   .then(response => {
      //     console.log(response);
      //     // const blob = new Blob([response.data], { type: 'audio/mpeg' })
      //     // const link = document.createElement('a')
      //     // link.href = URL.createObjectURL(blob)
      //     // link.download = 'recording'
      //     // link.click()
      //     // URL.revokeObjectURL(link.href)
      //   }).catch(console.error)
      // axios.get(url, { responseType: 'blob' })
      //   .then(response => {
      //     const blob = new Blob([response.data], { type: 'application/pdf' })
      //     const link = document.createElement('a')
      //     link.href = URL.createObjectURL(blob)
      //     link.download = 'recording'
      //     link.click()
      //     URL.revokeObjectURL(link.href)
      //   }).catch(console.error)
    },
    toggleAudio() {
      if (this.audio.paused) {
        this.audio.play();
        this.isPlaying = true;
      } else {
        this.audio.pause();
        this.isPlaying = false;
      }
    },
    deleteCall() {
      axios
        .delete('/api/delete-call/' + this.sid)
        .then((response) => {
          // console.log(response);
          // this.sms_details = response.data;
          Swal.fire({
            title: 'Action Successful!',
            text: response.data.message,
            icon: 'success',
            timer: 3000
          });
          bus.$emit("close:callDetailsModel", response);
        })
        .catch((error) => {
          // Handle errors here
          console.error('Error fetching user data:', error);
        });
    }
  },
  setup() {
    const { canPermittedByRoleOrUser } = useAclUtils()

    return {
      // ACL
      canPermittedByRoleOrUser,
    }
  }
}
</script>