var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "NotesAdd" },
    [
      _c(
        "b-input-group",
        { staticClass: "col input-group-merge" },
        [
          _c("b-form-input", {
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.saveNote.apply(null, arguments)
              },
            },
            model: {
              value: _vm.note,
              callback: function ($$v) {
                _vm.note = $$v
              },
              expression: "note",
            },
          }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.saveNote },
                },
                [_vm._v("Save Note")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }