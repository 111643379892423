import Vue from 'vue'
import axios from 'axios'

export default {

    SET_FILE_INFO(state, files) {
        state.fileDetails = files
    },
    SET_COMPANY_TYPES(state) {
        axios.get('/api/company_type').then(type => {
            state.company_types = type.data
        })
    },
    SET_LOADING(state, newLoadingStatus) {
        state.loadingStatus = newLoadingStatus
    },
    SET_COMPANY_INFO(state, companyDetails) {
        Object.assign(state.companyDetails, companyDetails);

    },
    SET_CONTACT_INFO(state, contactDetails) {
        const index = state.contactDetails.findIndex(item => item.id === contactDetails.id);
        if (index !== -1) {
            // If the item is found, update it
            state.contactDetails.splice(index, 1, contactDetails);
        } else {
            state.contactDetails = contactDetails
        }
    },
    SET_OUTCOMES(state, outcomes) {
        state.outcomes = outcomes

    },
    SET_SITE_INFO(state, siteDetails) {
        const index = state.siteDetails.findIndex(item => item.id === siteDetails.id);
        if (index !== -1) {
            // If the item is found, update it
            state.siteDetails.splice(index, 1, siteDetails);
        } else if (siteDetails.length === 0 || siteDetails === undefined) {
            state.siteDetails = [
                {
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    company_id: null,
                    county: null,
                    created_at: null,
                    id: null,
                    locality: null,
                    'no-site': '0',
                    postcode: null,
                    primary: 1,
                    town: null,
                    updated_at: null,
                },
            ]
        } else {
            state.siteDetails = siteDetails
        }
    },
    SET_METER_INFO(state, meters) {
        state.meters = meters
    },
    SET_NOTICES(state, notices) {
        state.notices = notices
    },
    SET_COMPANY_NOTICES(state, company_notices) {
        state.company_notices = company_notices
    },
    UPDATE_CONTACTS(state, contacts) {
        const self = this
        const promiseArray = contacts.map(contact => {
            let url = `/api/companies/${state.companyDetails.id}/contacts`
            if (contact.id !== null) {
                url = `${url}/${contact.id}`
                return axios.put(url, contact).then(response => {
                    const index = state.contactDetails.findIndex(cont => cont.id === contact.id)
                    state.contactDetails[index] = response.data
                }) // Update Contact
            }
            return axios.post(url, contact).then(response => {
                state.contactDetails.push(response.data)
            }) // Create New Contact
        })
    },
    SET_COMPANY_TYPE(state, type) {
        state.companyDetails.company_type_name = type
    },
    SET_COLLABORATOR_INFO(state, collab) {
        state.collaborators = collab
    },
    UPDATE_COLLABORATOR_INFO(state, data) {
        const url = `/api/companies/${state.companyDetails.id}/collaborators/`
        // console.log(data.owner);
        // alert("Look at console now!");
        return axios.post(url, {uid: data.collab.id, user: data.owner})
            .then(response => {
                state.collaborators = response.data
                // state.collaborators.push(response.data);
            }).catch(err => console.log('error:', err.message))
    },
    DELETE_COLLABORATOR_INFO(state, data) {
        const url = `/api/companies/${state.companyDetails.id}/collaborators/${data.collab.pivot.id}`
        // console.log(data.owner);
        // alert("Look at console now!");
        return axios.post(url, {
            uid: data.collab.pivot.id,
            user: data.owner,
            _method: 'DELETE',
        })
            .then(response => {
                // state.collaborators = state.collaborators.filter(collab => collab.pivot.id!=data.collab);
                state.collaborators = state.collaborators.filter(collab => {
                    if (collab.pivot.id > 0) {
                        console.log(collab)
                        return data.collab != collab.pivot.id
                    }
                })
            }).catch(err => console.log('error:', err.message))
    },
    DELETE_SITE_INFO(state, site) {
        const url = `/api/companies/${state.companyDetails.id}/sites/${site}`
        // console.log(data.owner);
        // alert("Look at console now!");
        return axios.post(url, {
            _method: 'DELETE',
        })
            .then(response => {
                // state.collaborators = state.collaborators.filter(collab => collab.pivot.id!=data.collab);
                state.siteDetails = response.data
            }).catch(err => console.log('error:', err.message))
    },
    DELETE_CONTACT(state, contact) {
        const url = `/api/companies/${state.companyDetails.id}/contacts/${contact}`
        const index = state.contactDetails.findIndex(c => c.id === contact)
        axios.delete(url).then(() => {
            state.contactDetails.splice(index, 1)
        })
    },
    ADD_SITE_INFO(state, data) {
        const url = `/api/companies/${state.companyDetails.id}/sites`
        // console.log(data.owner);
        // alert("Look at console now!");
        return axios.post(url, data)
            .then(response => {
                // state.collaborators = state.collaborators.filter(collab => collab.pivot.id!=data.collab);
                state.siteDetails = response.data
            }).catch(err => console.log('error:', err.message))
    },
    ADD_METER_INFO(state, data) {
        const url = `/api/companies/${state.companyDetails.id}/meters`
        return axios.post(url, data)
            .then(response => {
                state.siteDetails = response.data.sites
                state.latest_meter = response.data.latest_meter_added;
            }).catch(err => {
                // console.log('error:', err.response);
                state.errors = err.response;
            })
    },
    ADD_NOTICE(state, data) {
        const url = `/api/notice`
        return axios.post(url, data)
            .then(response => {
                if (data.company_id != 0) {
                    state.company_notices = response.data
                } else {
                    state.notices = response.data
                }
            }).catch(err => {
                 console.log('error:', err.response);
            })
    },
    SET_CONTACTS(state, contacts) {
        contacts.forEach(contact => {
            contact.show_contacts = false; // Add show_contacts property to each contact
        });
        state.contactDetails = contacts;
    },
    SET_NOTES(state, notes) {
        state.notes = notes
    },
    postCollaborator(state, collab) {
        const url = `/api/companies/${state.companyDetails.id}/collaborators/`
        return axios.post(url, {uid: collab})
            .then(response => {
            }).catch(err => console.log('error:', err.message))
    },
    RESET_COMPANY(state) {
        Object.assign(state, {
            companyDetails:
                {
                    sector: {
                        id: 0,
                    },
                },
            siteDetails: [
                {
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    company_id: null,
                    county: null,
                    created_at: null,
                    id: null,
                    locality: null,
                    'no-site': '0',
                    postcode: null,
                    primary: 1,
                    town: null,
                    updated_at: null,
                },
            ],
            contactDetails: [],
            fileDetails: [],
            type: null,
            meters: [],
            notes: [],
        })
    },
    UPDATE_STATUS_CHAT(state, obj) {
        obj.rootState.AppActiveUser.status = obj.value
    },

    SET_CALLBACK_INFO(state, callbackInfo) {
        state.companyDetails.callbacks = callbackInfo
    },
    // API AFTER
    SEND_CHAT_MESSAGE(state, payload) {
        if (payload.chatData) {
            // If there's already chat. Push msg to existing chat
            state.chats[Object.keys(state.chats).find(key => Number(key) === payload.id)].msg.push(payload.msg)
        } else {
            // Create New chat and add msg
            const chatId = payload.id
            Vue.set(state.chats, [chatId], {
                isPinned: payload.isPinned,
                msg: [payload.msg],
            })
        }
    },
    UPDATE_CHAT_CONTACTS(state, chatContacts) {
        state.chatContacts = chatContacts
    },
    UPDATE_CHATS(state, chats) {
        state.chats = chats
    },
    SET_CHAT_SEARCH_QUERY(state, query) {
        state.chatSearchQuery = query
    },
    MARK_SEEN_ALL_MESSAGES(state, payload) {
        payload.chatData.msg.forEach(msg => {
            msg.isSeen = true
        })
    },
    TOGGLE_IS_PINNED(state, payload) {
        state.chats[Object.keys(state.chats).find(key => Number(key) === payload.id)].isPinned = payload.value
    },
}
