<template>
  <div v-if="warningZone">
    Are you still with us?
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useJwt from '@core/auth/jwt/useJwt'

export default {
  name: 'AutoLogout',
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false, // Default to false, only show when warning timer triggers
    }
  },
  mounted() {
    this.events.forEach(event => {
      window.addEventListener(event, this.resetTimer)
    })
    this.setTimers()
  },
  beforeDestroy() {
    this.events.forEach(event => {
      window.removeEventListener(event, this.resetTimer)
    })
    this.clearTimers()
  },
  methods: {
    showToast(variant, text = '', title = 'Notification', icon = 'BellIcon', timeout = 60000) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      }, {
        timeout,
      })
    },
    setTimers() {
      // Start the warning timer for 4 minutes
      this.warningTimer = setTimeout(() => {
        this.warningZone = true
        this.showToast('warning', 'You are about to be signed out')
      }, 4 * 60 * 1000) // 4 minutes

      // Start the logout timer for 5 minutes
      this.logoutTimer = setTimeout(this.logoutUser, 5 * 60 * 1000) // 5 minutes
    },
    warningMessage() {
      // This function is not used anymore since warning is shown directly in setTimers
      // Keeping it for potential future use or refactoring
    },
    logoutUser() {
      // Show a final toast notification before logging out
      //this.showToast("danger", "Asta la vista")

      // Remove tokens and user data from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')

      // Reset abilities and redirect to login page
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'auth-login' })
    },
    resetTimer() {
      // Clear and reset timers on user activity
      this.clearTimers()
      this.setTimers()
      this.$toast.clear()  // This clears all toasts. If you need more control, you might need to track and clear specific toasts.

    },
    clearTimers() {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
    }
  },
}
</script>

<style scoped></style>
