<template>
    <div class="Notes col-12">

        <div class="NoteView">
            <!--            <vue-perfect-scrollbar                v-if = "items.length"             :settings = "perfectScrollbarSettings" class = "scrollable-container media-list scroll-area" tagname = "div">-->


            <ViewNotes v-if="canPermittedByRoleOrUser('view', 'notes')" :id="company_id" :key="timestamp" ref="notes"
                type="companies"></ViewNotes>
            <!--            </vue-perfect-scrollbar>-->
        </div>


        <div class="NoteAdd">
            <AddNotes v-if="canPermittedByRoleOrUser('add', 'notes')" :company_id="company_id" @added-note="NoteAdded">
            </AddNotes>
        </div>

    </div>
</template>

<script>
import AddNotes from "./add-notes/add-notes";
import ViewNotes from "./view-notes/view-notes";
import { bus } from "@/main";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {
    name: "Notes",
    components: {
        AddNotes,
        ViewNotes,
        VuePerfectScrollbar

    },
    props: {
        company_id: {
            type: String,
            required: true,
        },
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        }
    },
    methods: {
        NoteAdded() {
            bus.$emit("Notes:add");
        }
    },
    data() {
        return {
            timestamp: 0,
            items: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
                suppressScrollX: true
            },

        }
    },
    mounted() {
        console.log('Company ID:', this.company_id);  // Check if it's correctly passed
    },
    setup() {
        const { canPermittedByRoleOrUser } = useAclUtils()

        return {
            // ACL
            canPermittedByRoleOrUser,
        }
    },
}
</script>

<style lang="scss" scoped></style>
