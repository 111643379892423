import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// region Custom Modules
import moduleCompany from '@/views/apps/sales-engine/store/Company/moduleCompany'
import moduleLOA from '@/views/apps/sales-engine/store/LOA/moduleLOA'
import moduleDataManager from '@/views/apps/data-manager/store/DataManager/moduleDataManager'
import moduleUser from "@/views/apps/sales-engine/store/User/moduleUser";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
Vue.use(createPersistedState)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        'app-ecommerce': ecommerceStoreModule,
        company: moduleCompany,
        loa: moduleLOA,
        dataManager: moduleDataManager,
        userList: moduleUser
    },
    plugins: [createPersistedState({
        storage: {
            getItem: key => localStorage.getItem(key),
            setItem: (key, value) => localStorage.setItem(key, value, {expires: 3, secure: true}),
            removeItem: key => localStorage.removeItem(key)
        }
    })],
    strict: process.env.DEV
})
