<template>

    <section class="MeterRecordPage">
        <b-row class="match-height">
            <b-col md="10" xl="10"></b-col>
            <b-col md="2" xl="2">
                <div>
                    <b-button class="float-right" variant="primary" v-if="!currentData" v-b-modal.addContract>
                        <feather-icon icon="AlignJustifyIcon" />
                    </b-button>
                    <base-modal id="addContract" hide-footer size="lg" title="Add Contract">
                        <AddContract @save-success="hideModal" :meter_id="Number($route.params.meterid)"
                            v-if="!currentData" />
                    </base-modal>
                </div>
            </b-col>
        </b-row>
        <b-row class="match-height"><b-col md="12" xl="12">&nbsp;</b-col></b-row>
        <b-row class="match-height">
            <b-col md="4" xl="4">

                <b-card>
                    <Identifiers :editing="false"></Identifiers>
                    <br />
                    <!-- <b-button variant="primary" v-b-modal.addConsumptionLog>
                        <span class="text-nowrap">Add Consumption Log</span>
                    </b-button> -->
                    <!-- <base-modal id ="addConsumptionLog"
                    size="lg"
                    hide-footer
                    title="Add Comsumption Log">
                        <AddContract
                        @save-success="hideModal"
                        :meter_id="Number($route.params.meterid)"
                        />
                    </base-modal> -->
                </b-card>

            </b-col>

            <b-col md="4" xl="4">

                <!--            Current Contract Info-->
                <b-card class="card">
                    <b-card-title>Current Contract Info</b-card-title>
                    <!-- <b-button
                        variant="primary"
                        v-if="!currentData"
                        v-b-modal.addContract
                    >
                    <span class="text-nowrap">Add Contract</span>
                    </b-button>
                    <base-modal id ="addContract"
                    size="lg"
                    hide-footer
                    title="Add Contract">
                        <AddContract
                        @save-success="hideModal"
                        :meter_id="Number($route.params.meterid)"
                        v-if="!currentData"
                        />
                    </base-modal> -->
                    <div v-if="currentData">
                        <contractDetails :contract="currentData"></contractDetails>
                    </div>

                </b-card>

            </b-col>

            <b-col md="4" xl="4">
                <b-card class="card">
                    <b-row>
                        <b-col>
                            Consumption Log
                        </b-col>

                    </b-row>
                </b-card>
            </b-col>

        </b-row>


        <b-row class="match-height">
            {{
            /**
            TODO: SPLIT THIS into two, add on the left (pull from Add Site Modal), site list on right (site & meter list
            already done)
            */
            }}


            <!--            Sites& Meters Here-->
            <b-col lg="6">
                <b-card class="card">
                    <h4>Future Contracts</h4>

                    <b-row>
                        <b-col class="d-flex align-items-center" cols="4">
                            Supplier

                        </b-col>
                        <b-col class="d-flex align-items-center" cols="3">
                            CSD
                        </b-col>
                        <b-col class="d-flex align-items-center" cols="3" icon="filter">
                            CED
                        </b-col>

                        <b-col class="d-flex align-items-center" cols="2" icon="filter">
                            Deal

                            <!--                            <b-badge variant="warning">View Sale</b-badge>-->
                            <!--                            <i-if sale="true">-->
                            <!--                                <SaleRecord></SaleRecord>-->
                            <!--                            </i-if>-->
                            <!--                            No Sale = External-->
                        </b-col>
                    </b-row>
                    <b-card v-for="(future, index) in futureData" :key="index"
                        class="prospect-record-list b-card vx-row w-full">
                        <ContractRecord class="contract-record" :contractData="future"></ContractRecord>
                    </b-card>

                </b-card>
            </b-col>
            <b-col lg="6">
                <b-card class="card">
                    <h4>Historic Contracts</h4>

                    <b-row>
                        <b-col class="d-flex align-items-center" cols="4">
                            Supplier

                        </b-col>
                        <b-col class="d-flex align-items-center" cols="3">
                            CSD
                        </b-col>
                        <b-col class="d-flex align-items-center" cols="3" icon="filter">
                            CED
                        </b-col>

                        <b-col class="d-flex align-items-center" cols="2" icon="filter">
                            Deal

                            <!--                            <b-badge variant="warning">View Sale</b-badge>-->
                            <!--                            <i-if sale="true">-->
                            <!--                                <SaleRecord></SaleRecord>-->
                            <!--                            </i-if>-->
                            <!--                            No Sale = External-->
                        </b-col>
                    </b-row>

                    <b-card v-for="(past, index) in pastData" :key="index"
                        class="prospect-record-list b-card vx-row w-full">
                        <ContractRecord class="contract-record" :contractData="past"></ContractRecord>
                    </b-card>
                    <base-modal id="viewContract" size="xl">
                        <viewContract></viewContract>
                    </base-modal>

                </b-card>
            </b-col>



        </b-row>


    </section>

</template>
<style lang="scss">
@import "MeterRecordPage";
</style>

<script>
import {
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BCollapse,
    BFormGroup,
    BModal,
    BRow,
    VBToggle,
    VBTooltip
} from 'bootstrap-vue';
import FontAwesome from 'vue-fontawesome';
import Collaborator from "@/views/components/Collaborator/Collaborator";
import ContractRecord from "@/views/components/Contracts/ContractRecord";
import Identifiers from "@/views/services/identifiers/Identifiers";
import store from "@/store";
import viewContract from "@/views/services/broker/contracts/viewContract/viewContract";
import ContractDetails from "@/views/services/broker/contracts/contractDetails/contractDetails";
import AddContract from "@/views/services/broker/contracts/addContract/addContract";
import axios from 'axios';
import { bus } from '@/main'
import BaseModal from "@/views/components/modal/BaseModal.vue";

export default {
    name: "Meters",
    components: {
        BaseModal,
        ContractDetails,
        Collaborator,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        VBTooltip,
        BCollapse,
        BCard,
        BCardHeader,
        BCardBody,
        VBToggle,
        Identifiers,
        BBadge,
        FontAwesome,
        ContractRecord,
        viewContract,
        AddContract,
        BModal

    },
    async mounted() {
        console.clear();
        const self = this
        let companyName = self.$store.getters["company/getCompany"].business_name;
        if (this.$route.params.meterid) {
            let url = "/api/meters/" + self.$route.params.meterid;
            await axios.get(url)
                .then(function (response) {
                    self.$route.meta.pageTitle = "Meters";
                    self.$route.meta.breadcrumb[0].text = companyName;
                    self.$route.meta.breadcrumb[1].text = response.data.site.address1 + " " + response.data.site.postcode;
                }).catch(err => console.log("error:", err.message));
        }

        this.getCurrentContract();
        this.getFutureContracts();
        this.getHistoricContracts();
    },
    computed: {
        meter() {
            return store.getters["company/getMeters"];
        },
        // loading() {
        //     return store.getters['company/getStatus']
        // },
        company() {
            return store.getters['company/getCompany']
        },
    },
    data() {
        return {
            meters: [],
            page: 1,
            title: "MeterRecordPage",
            record: {
                id: null,
                business_name: " ",
                public_id: " ",
            },
            home: true,
            currentData: {
                start_date: '',
                end_date: '',
                is_annual: '',
                consumption: '',
                supplier: {
                    name: '',
                }
            },
            pastData: [],
            futureData: [],
            responseData: []
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('addContract');
            setTimeout(this.getCurrentContract(), 1000);
        },
        checkProperties(obj) {
            for (var key in obj) {
                if (obj[key] !== null && obj[key] != "")
                    return false;
            }
            return true;
        },
        getCurrentContract() {
            const queryParams = {
                params: { current: true, meter_id: Number(this.$route.params.meterid) },
            };
            axios.get('/api/contracts', queryParams)
                .then((response) => {
                    this.currentData = response.data;
                })
                .catch((error) => {
                    // Handle errors here
                    console.error('Error fetching data:', error);
                })
        },
        getFutureContracts() {
            const queryParams = {
                params: { future: true, meter_id: Number(this.$route.params.meterid) },
            };
            this.getAllContracts(queryParams);
        },
        getHistoricContracts() {
            const queryParams = {
                params: { past: true, meter_id: Number(this.$route.params.meterid) },
            };
            this.getAllContracts(queryParams);
        },
        getAllContracts(queryParams) {
            axios.get('/api/contracts', queryParams)
                .then((response) => {
                    if (queryParams.params.past) {
                        this.pastData = response.data;
                    }

                    if (queryParams.params.future) {
                        this.futureData = response.data;
                    }

                    this.responseData = response.data;

                })
                .catch((error) => {
                    // Handle errors here
                    console.error('Error fetching data:', error);
                })
        },

    }


}
</script>
