import { render, staticRenderFns } from "./AddContact.vue?vue&type=template&id=242b51aa"
import script from "./AddContact.vue?vue&type=script&lang=js"
export * from "./AddContact.vue?vue&type=script&lang=js"
import style0 from "./AddContact.vue?vue&type=style&index=0&id=242b51aa&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/dev21enduapp/dev.endu.app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('242b51aa')) {
      api.createRecord('242b51aa', component.options)
    } else {
      api.reload('242b51aa', component.options)
    }
    module.hot.accept("./AddContact.vue?vue&type=template&id=242b51aa", function () {
      api.rerender('242b51aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/src/views/components/ProspectModal/ProspectContacts/AddContact/AddContact.vue"
export default component.exports