<template>
    <div class="DocGen">
        <b-row>

            <b-col cols="12">

                <Generate/>

            </b-col>

            <!--<b-col cols="6">-->
            <!--    <UploadLOA></UploadLOA>-->

            <!--</b-col>-->

        </b-row>
    </div>

</template>
<style lang="scss">
@import 'DocGen';
</style>

<script>

import {BButton, BCol, BRow} from 'bootstrap-vue'
import Generate from "@/views/components/DocGen/Generate/Generate.vue";

export default {
    name: 'DocGen',
    components: {
        Generate,
        BRow,
        BCol,
        BButton,
    },
}
</script>
