var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ContactDetails" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "8" } }, [
            _c(
              "h1",
              [
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "phone"] },
                  }),
                  _vm._v(" "),
                  _c("b", {
                    domProps: { textContent: _vm._s(_vm.primaryContactNumber) },
                  }),
                ],
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "callBackButton",
                  attrs: { variant: "primary" },
                  on: { click: _vm.makeCall },
                },
                [_vm._v("Make a Call\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "8" } }),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              !_vm.showSmsDiv
                ? _c(
                    "b-button",
                    {
                      staticClass: "callBackButton",
                      attrs: { variant: "primary" },
                      on: { click: _vm.showSms },
                    },
                    [_vm._v("Send SMS\n            ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSmsDiv
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "Enter number" },
                            model: {
                              value: _vm.to_number,
                              callback: function ($$v) {
                                _vm.to_number = $$v
                              },
                              expression: "to_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "textarea",
                              "max-rows": "6",
                              placeholder: "Enter something...",
                              rows: "3",
                            },
                            model: {
                              value: _vm.smsContent,
                              callback: function ($$v) {
                                _vm.smsContent = $$v
                              },
                              expression: "smsContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "callBackButton",
                              attrs: {
                                disabled:
                                  _vm.to_number == null ||
                                  _vm.smsContent == null,
                                variant: "primary",
                              },
                              on: { click: _vm.sendSMS },
                            },
                            [_vm._v("Submit\n                    ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "vx-row detail-options" },
        [
          _c("b-col", { attrs: { cols: "5" } }, [
            _c("b", [_vm._v("Main Contact:")]),
          ]),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "7" } }, [
            _c("b", [
              _vm._v(
                _vm._s(_vm.primaryContactDetails.title) +
                  " " +
                  _vm._s(_vm.primaryContactDetails.forename) +
                  " " +
                  _vm._s(_vm.primaryContactDetails.last_name) +
                  " " +
                  _vm._s(_vm.primaryContactNumber)
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "vx-row detail-options" },
        [
          _c("b-col", { attrs: { cols: "5" } }, [
            _c("b", [_vm._v("Reference:")]),
          ]),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "7" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.recordContactDetails.public_id) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "vx-row detail-options" },
        [
          _c("b-col", { attrs: { cols: "5" } }, [
            _c("b", [_vm._v("Next Callback:")]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "7" } },
            [
              _c("flat-pickr", {
                staticClass: "form-control col-12",
                attrs: {
                  config: _vm.configdateTimePicker,
                  placeholder: "Set a Callback",
                },
                model: {
                  value: _vm.latestCallback,
                  callback: function ($$v) {
                    _vm.latestCallback = $$v
                  },
                  expression: "latestCallback",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "12" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "vx-row detail-options" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("b", [_vm._v("Call Outcome:")]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "w-full", attrs: { cols: "12" } },
            [
              _c("v-select", {
                staticClass: "selectOptions w-full",
                attrs: {
                  options: _vm.getOutcomeList(),
                  reduce: (outcome) => outcome.id,
                  label: "outcome",
                  placeholder: "Select Relevant Outcomes",
                  "vs-autocomplete": "",
                },
                on: { input: _vm.checkSelection },
                model: {
                  value: _vm.select1,
                  callback: function ($$v) {
                    _vm.select1 = $$v
                  },
                  expression: "select1",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.checkSelection().length !== 0
            ? _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    staticClass: "selectOptions w-full",
                    attrs: {
                      options: _vm.checkSelection(),
                      reduce: (outcome) => outcome.id,
                      label: "outcome",
                      multiple: "",
                      placeholder: "Select Relevant Outcomes",
                      "vs-autocomplete": "",
                    },
                    model: {
                      value: _vm.select2,
                      callback: function ($$v) {
                        _vm.select2 = $$v
                      },
                      expression: "select2",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "callBackButton",
                  attrs: { variant: "primary" },
                  on: { click: _vm.saveCallback },
                },
                [_vm._v("Save CallBack & Call Outcome\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }