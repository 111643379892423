import ability from './ability'

export const canNavigate = to => {
    return to.matched.some(function (route) {
        if (ability.can("admin", 'superuser')) {
            return true;
        }
        if (route.meta.resource === "default") {
            return true;
        }
        return ability.can(route.meta.action || 'view', route.meta.resource)
    });
};
export const _ = undefined

