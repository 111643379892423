<template>

  <div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col cols="12" md="12" v-if="showFilter">
            <div class="d-flex align-items-center justify-content-end">
              <v-selectize v-if="company_id == null" :options="companyList" v-model="dropdownCompanyFilter"
                :reduce="val => val.value" key-by="value" label="label" :keys="['label', 'value']"
                @input="onSelectCompanyChange" class="w-25 m-1" />

              <v-selectize v-if="dropdownCompanyFilter.value !== '' || company_id !== null" :options="contactList"
                v-model="dropdownCompanyContactFilter" :reduce="val => val.value" key-by="value" label="label"
                :keys="['label', 'value']" @input="onSelectCompanyContactChange" class="w-25 m-1" />


              <!-- Phone Number Filter -->
              <v-selectize v-if="dropdownCompanyFilter.value !== '' || company_id !== null"
                v-model="selectedPhoneNumber" :options="phoneNumbers" :reduce="val => val" label="label" value="value"
                @input="onSelectPhoneNumberChange" class="w-25 m-1" />


              <v-selectize :options="agentOptions" v-model="dropdownFilterUser" :reduce="val => val.sub" key-by="sub"
                label="label" :keys="['label', 'sub', 'phone_number']" @input="onSelectUserChange" class="w-25 m-1" />

              <v-selectize :options="statusOptions" v-model="dropdownFilter" :reduce="val => val.value" key-by="value"
                label="label" :keys="['label', 'value']" @input="onSelectChange" class="w-25 m-1" />

              <b-dropdown class="chart-dropdown" no-caret right size="sm" text="Select Date Range"
                toggle-class="p-0 mb-25 w-50" variant="transparent" />
              <flat-pickr v-model="dateRange" class="form-control" :config="dateTimeConfig"
                placeholder="DD-MM-YYYY H-i-S" v-on:input="onDateChange" />

            </div>
          </b-col>
        </b-row>

        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="queryBody.per_page" :options="pageOptions" :clearable="false"
              @option:selected="changePageCount($event)" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>
          <!-- Per Page -->
        </b-row>

      </div>
      <div class="table-container table-log-component">
        <b-table :busy="isTableLoading" :items="itemsData" responsive="true" :fields="fields" primary-key="id"
          no-local-sorting @sort-changed="customSorting" show-empty empty-text="No matching records found"
          @row-clicked="onRowClicked">
          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template v-slot:cell(properties.to)="row">
            <div v-html="row.item.properties.to"></div>
          </template>
          <template v-slot:cell(properties.from)="row">
            <div v-html="row.item.properties.from"></div>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ pageMeta.from }} to {{ pageMeta.to }} of {{ totalRows }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="queryBody.page" :total-rows="totalRows" :per-page="queryBody.per_page"
              @input="pagination" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>


        </b-row>
      </div>
    </b-card>
      <base-modal id="call-details" hide-footer size="lg" title="Call Details">
      <CallDetails :sid="selectedSID" />
      </base-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import axios from 'axios';
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component';
import CallDetails from "@/views/components/CallDetails/CallDetails.vue";
import Swal from 'sweetalert2';
import { bus } from "@/main";
import VSelectize from '@isneezy/vue-selectize'
import 'selectize/dist/css/selectize.default.css'
import BaseModal from "@/views/components/modal/BaseModal.vue";
// import dateTimePicker from "@/views/forms/form-element/date-time-picker/DateTimePicker.vue";
export default {
  name: 'CallLogs',
  components: {
      BaseModal,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    flatPickr,
    CallDetails,
    VSelectize,
  },
  props: {
    filterParams: {
      type: [Object, String, Number], // Allow Object, String, or Number
      required: false,
    },
    showFilter: {
      type: Boolean,
      required: false,
    },
    userType: {
      type: String,
      required: false,
    },
    company_id: {
      type: Number,
      required: false,
    },
    // from_number:{
    //   type: String,
    //   required:false,
    // },
  },
  mounted() {
    let self = this;
    let userData = JSON.parse(localStorage.getItem('userDetails'));
    this.from_number = userData.phone_number;
    bus.$on("close:callDetailsModel", function (response) {
      self.getData(self.queryBody);
      self.$bvModal.hide("call-details");
    });
    axios
      .get('/api/list-users/status?status=active')
      .then((response) => {
        //   console.log(response);
        let result = response.data;
        //   console.log(result);
        this.agentOptions = [
          { "phone_number": null, "sub": null, "label": "Select Agent" },
          ...result.data.map((item) => ({
            "phone_number": item.phone_number, "sub": item.sub, "label": item.name
          }))
        ];
        //   console.log(this.agentOptions);
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error fetching user data:', error);
      })
      .finally(() => {
        this.isTableLoading = false;
      });
    axios.get("/api/companies?all")
      .then(function (response) {
        console.log(response);
        let result = response.data;
        self.companyList = [
          { value: '', label: 'Please select a company' },
          ...result.data.map((item) => ({
            value: item.id, label: item.business_name
          }))
        ];
      });
    if (this.company_id !== null) {
      axios.get(`/api/companies/${this.company_id}/contacts`)
        .then(function (response) {
          let result = response.data;
          self.contactList = [
            {
              value: null, label: "Please select one contact", phone_number: null
            },
            ...result.map((item) => ({
              value: item.id, label: item.full_name, phone_number: item.mobile
            }))
          ];
          self.phoneNumbers = [
            { value: null, label: 'Select Phone', phone_number: null },
            ...result.map(item => ({
              value: item.id, label: item.mobile, phone_number: item.mobile
            }))
          ];
        });
    }
    // if(this.filterParams)
    // {
    //   this.queryBody.query=this.filterParams;
    // }
    // if(this.userType)
    // {
    //   this.queryBody.user_type=this.userType;
    // }
    if (this.from_number) {
      this.queryBody.from_number = this.from_number;
    }
    this.getData(this.queryBody);
  },

  data() {
    return {
      companyList: [{ value: '', label: 'Select Company' }],
      contactList: [{ value: '', label: 'Select Contact' }],
      phoneNumbers: [{ value: '', label: 'Select Phone' }],
      selectedPhoneNumber: { value: '', label: 'Select Phone' }, // Add this property
      agentOptions: [{ value: '', label: 'Select Agent' }],
      dropdownFilterUser: { value: '', label: 'Select Agent' },
      dropdownCompanyFilter: { value: '', label: 'Select Company' },
      dropdownCompanyContactFilter: { value: '', label: 'Select Contact' },
      apiUrl: (this.company_id !== null) ? '/api/client-call-logs' : '/api/call-logs',
      dateRange: this.getRange(),
      dateTimeConfig: {
        maxDate: 'today',
        altFormat: 'M j, Y H:i:S',
        altInput: true,
        dateFormat: 'Y-m-d H:i:S',
        enableTime: false,
        static: true,
        position: 'above left',
        mode: 'range',
      },

      itemsData: [],
      fields: [
        // { key: 'properties.date', label:'Date',sortable:true},
        // { key: 'properties.time', label:'Time',sortable:true},
        // { key: 'properties.performed_by', label:'Performed By'},
        { key: 'sid', label: 'SID' },

        { key: 'date', label: 'Date' },
        { key: 'from_number', label: 'From', tdClass: 'width-adjustment' },
        { key: 'to_number', label: 'To', tdClass: 'width-adjustment' },
        { key: 'duration', label: 'Time' },

        // { key: 'properties.performed_by', label:'Performed By'},
        { key: 'status', label: 'Status' },

        // { key: 'description', label:'Title'},
        // { key: 'properties.where', label:'URL'},
        // { key: 'properties.from', label:'From',tdClass: 'width-adjustment'},
        // { key: 'properties.to', label:'To',tdClass: 'width-adjustment'},
      ],
      queryBody: {
        company_id: this.company_id,
        from_number: null,
        start_date: new Date(),
        end_date: new Date(),
        status: 'all',
        to_number: null,
        page: 1,
        per_page: 10,
      },
      pageOptions: [10, 25, 50, 100],
      lastPage: 1,
      totalRows: 1,
      filter: '',
      statusOptions: [
        { value: "all", label: 'All' },
        { value: "queued", label: 'Queued' },
        { value: "completed", label: 'Completed' },
        { value: "busy", label: 'Busy' },
        { value: "failed", label: 'Failed' },
        { value: "no-answer", label: 'No Answer' },
        { value: "canceled", label: 'Cancelled' },
      ],
      dropdownFilter: { value: "all", label: 'All' },
      isTableLoading: false,
      selectedSID: null,
    }
  },
  computed: {
    companyInfo() {
      return store.getters['company/getCompany']
    },
    pageMeta() {
      const from = (this.queryBody.page - 1) * this.queryBody.per_page + 1;
      const to =
        this.queryBody.page * this.queryBody.per_page > this.totalRows
          ? this.totalRows
          : this.queryBody.page * this.queryBody.per_page;

      return { from, to };
    }
  },
  methods: {
    filterCallLogs(selectedAgentId) {
      // Handle the selected agent change
      console.log('Selected Agent ID:', selectedAgentId);
      // Perform filtering or other actions based on the selected agent
    },
    onRowClicked(item, index, event) {
      console.log(item, index, event);
      this.selectedSID = item.sid;
      this.$bvModal.show("call-details");
    },
    getRange() {
      let start_date = null;
      let end_date = null;
      let d = new Date();
      d.setDate(1);
      start_date = d.toISOString().slice(0, 10);
      end_date = new Date().toISOString().slice(0, 10);
      console.log(start_date, end_date);
      return start_date + " 00:00:00 to " + end_date + " 23:59:59";
    },
    refetchData() {
      this.getData(this.queryBody);
    },
    onSelectChange() {
      this.queryBody.status = this.dropdownFilter.value;
      console.log("status" + this.queryBody);
      this.getData(this.queryBody);
    },
    onSelectUserChange() {
      this.agentOptions.map((item) => {
        if (item.sub == this.dropdownFilterUser.sub) {
          if (item.phone_number !== null) {
            this.queryBody.from_number = item.phone_number;
            this.getData(this.queryBody);
          }
          else {
            Swal.fire({
              title: 'Action aborted!',
              text: "Selected user's phone number is not available.",
              icon: 'error',
              timer: 2000
            });
          }
        }
      });
    }
    ,
    onSelectCompanyChange() {
      let self = this;
      this.queryBody.company_id = this.dropdownCompanyFilter.value;
      if (this.queryBody.company_id != '') {
        axios.get(`/api/companies/${this.dropdownCompanyFilter.value}/contacts`)
          .then(function (response) {
            let result = response.data;
            self.contactList = [
              {
                value: null, label: "Please select one contact", phone_number: null
              },
              ...result.map((item) => ({
                value: item.id, label: item.full_name, phone_number: item.mobile
              }))
            ];
            self.phoneNumbers = [
              { value: null, label: 'Select Phone', phone_number: null },
              ...result.map(item => ({
                value: item.id, label: item.mobile, phone_number: item.mobile
              }))
            ];
          });
        this.getData(this.queryBody);
      }
    },
    onSelectCompanyContactChange() {
      this.contactList.map(item => {
        if (item.value == this.dropdownCompanyContactFilter.value) {
          this.queryBody.to_number = item.phone_number;
        }
      });
      console.log("Query Body" + this.queryBody);
      this.getData(this.queryBody);
    }
    ,
    onSelectPhoneNumberChange() {
      this.phoneNumbers.map(item => {
        if (item.value == this.selectedPhoneNumber.value) {
          this.queryBody.to_number = item.phone_number;
        }
      });
      console.log("Query Body" + this.queryBody);
      this.getData(this.queryBody);
    }
    ,
    onDateChange(range) {
      this.dateRange = range;
      this.getData(this.queryBody);
    },
    findData() {
      this.queryBody.query = this.filter;
      // this.getData(this.queryBody);
    },

    clearSearch(event) {
      if (event.target.value === "") {
        if (!this.filter) {
          this.queryBody.page = 1;
          this.queryBody.per_page = 10;
          delete this.queryBody['query'];
          this.getData(this.queryBody);
        }

      }
    },
    changePageCount(per_page) {
      this.queryBody.page = 1;
      this.queryBody.per_page = per_page;
      this.getData(this.queryBody);

    },
    getData(params) {
      this.isTableLoading = true;
      let start_date = null;
      let end_date = null;
      if (this.dateRange == null) {
        let d = new Date();
        d.setDate(1);
        start_date = d.toISOString().slice(0, 10) + ' 00:00:00';
        end_date = new Date().toISOString().slice(0, 10) + ' 23:59:59';
      }
      else {
        let dates = this.dateRange.split(' to ');
        start_date = dates[0];
        end_date = dates[1].replace('00:00:00', '23:59:59');
      }
      params.start_date = start_date;
      params.end_date = end_date;
      this.itemsData = [];
      axios
        .post(this.apiUrl, this.deleteEmptyKeys(params))
        .then((response) => {
          if (response.data.status == 204) {
            Swal.fire({
              title: 'Action aborted!',
              text: response.data.message,
              icon: 'error',
              timer: 5000
            });
          }
          else {
            let result = response.data;

            this.itemsData = result.map((item) => ({
              ...item,
              sid: item.sid, // Use 'time' instead of 'call_date'

              date: this.getDate("date", item.time), // Use 'time' instead of 'call_date'
              time: this.getDate("time", item.time), // Use 'time' instead of 'call_date'
              duration: item.duration || "0", // Default to "0" if duration is missing
              from_number: item.from_number || "Unknown", // Handle missing 'from_number'
              to_number: item.to_number || "Unknown",     // Handle missing 'to_number'
              status: item.status || "unknown",          // Handle missing 'status'
            }));

            // let pagination = result[0].pagination;
            // this.queryBody.page = pagination.current_page;
            // this.queryBody.per_page = pagination.per_page;
            // this.lastPage = pagination.last_page;
            // this.totalRows = pagination.total;
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error('Error fetching user data:', error);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    getDate(field, dateTimeString) {
      // console.log(dateTimeString);
      if (dateTimeString !== null) {
        const timeZone = 'Europe/London';
        const dateTimeInLondon = moment.tz(dateTimeString, 'DD-MM-YYYY hh:mm:ss A', timeZone);
        if (field == 'date') {
          const today = new Date(dateTimeString);

          // Extracting year, month, and day
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const day = String(today.getDate()).padStart(2, '0');

          // Formatting the date as YYYY-MM-DD
          const formattedDate = `${day}-${month}-${year}`;
          // console.log(formattedDate);
          return formattedDate;
          // console.log(dateTimeInLondon);
          // return  dateTimeInLondon.format('DD-MM-YYYY')
        } else {
          return dateTimeInLondon.format('hh:mm:ss A')
        }
      }
      else {
        return ' - ';
      }
    },
    customSorting: function (ctx) {
      if (ctx.sortBy == "" || ctx.sortBy == null) {
        return;
      }
      this.queryBody.order = ctx.sortDesc ? "asc" : "desc";
      this.queryBody.sort_by = ctx.sortBy;
      this.queryBody.page = 1;
      this.getData(this.queryBody);
    },
    pagination: function (pageNumber) {
      this.queryBody.page = pageNumber;
      this.getData(this.queryBody);
    },

  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.actions .circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}


// .b-table .width-adjustment {
//   word-wrap: break-word !important;
//   width: 350px !important;
// }
// .b-table th {
//   white-space: nowrap;
// }
// .table-log-component .b-table {
//   display: block;
//   margin: 0 auto;
//   overflow-x: auto;
//   white-space: nowrap;
// }
.table-log-component .b-table {
  margin: 0 auto;
  overflow-x: auto;
}
</style>
