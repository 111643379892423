<template>

    <!--    <b-row>-->

    <div class="ContactDetails">
        <b-row>
            <b-col cols="8">

                <h1>
                    <!--                                Show Phone Number & Outcome Box If Call Button Hit-->

                    <template>
                        <font-awesome-icon :icon="['fas', 'phone']"/>
                        <b v-text="primaryContactNumber"/>
                        <!--                                    Show number for primary contact by default-->
                    </template>
                </h1>
            </b-col>
            <b-col cols="4">
                <b-button class="callBackButton" variant="primary" @click="makeCall">Make a Call
                </b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="8">

            </b-col>
            <b-col cols="4">
                <b-button v-if="!showSmsDiv" class="callBackButton" variant="primary" @click="showSms">Send SMS
                </b-button>
            </b-col>
        </b-row>

        <b-row v-if="showSmsDiv">
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <b-form-input v-model="to_number" placeholder="Enter number"></b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-form-textarea id="textarea" v-model="smsContent" max-rows="6" placeholder="Enter something..."
                                         rows="3"></b-form-textarea>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button :disabled="to_number == null || smsContent == null" class="callBackButton"
                                  variant="primary" @click="sendSMS">Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row class="vx-row detail-options">
            <b-col cols="5">
                <b>Main Contact:</b>
            </b-col>
            <b-col cols="7">
                <b>{{ primaryContactDetails.title }} {{ primaryContactDetails.forename }} {{
                        primaryContactDetails.last_name
                    }} {{ primaryContactNumber }}</b>
            </b-col>
            <!--                        Show details for primary contact in format: Mr FirstName LastName-->
        </b-row>

        <b-row class="vx-row detail-options">
            <b-col cols="5">
                <b>Reference:</b>
            </b-col>
            <b-col cols="7">
                {{ recordContactDetails.public_id }}
            </b-col>

        </b-row>

        <b-row class="vx-row detail-options">
            <b-col cols="5">
                <b>Next Callback:</b>
            </b-col>
            <b-col cols="7">
                <flat-pickr v-model="latestCallback" :config="configdateTimePicker" class="form-control col-12"
                            placeholder="Set a Callback"/>
                <!--                            Show "Set Call Back" if no call back is set, else show call back date/time"-->
            </b-col>
            <b-col cols="12"/>
        </b-row>
        <b-row class="vx-row detail-options">
            <b-col cols="12">
                <b>Call Outcome:</b>
            </b-col>
            <b-col class="w-full" cols="12">
                <v-select v-model="select1" :options="getOutcomeList()" :reduce="outcome => outcome.id"
                          class="selectOptions w-full" label="outcome" placeholder="Select Relevant Outcomes"
                          vs-autocomplete
                          @input="checkSelection"/>
            </b-col>
            <b-col v-if="checkSelection().length !== 0" cols="12">
                <v-select v-model="select2" :options="checkSelection()" :reduce="outcome => outcome.id"
                          class="selectOptions w-full" label="outcome" multiple placeholder="Select Relevant Outcomes"
                          vs-autocomplete/>

            </b-col>
            <b-col cols="12">
                <b-button class="callBackButton" variant="primary" @click="saveCallback">Save CallBack & Call Outcome
                </b-button>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
    BAvatar,
    BAvatarGroup,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BTooltip
} from 'bootstrap-vue'

import Button from '@/views/components/button/Button'

import {getUserData} from '@/auth/utils'
import axios from 'axios'
import store from '@/store'
import {bus} from '@/main'
import SoftPhone from '@core/layouts/components/soft-phone/SoftPhone'
import Swal from 'sweetalert2'

export default {
    name: 'SoftPhone',
    components: {
        flatPickr,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        vSelect,
        BAvatar,
        BAvatarGroup,
        BTooltip,
        BFormInput,
        BFormTextarea,
        ToastificationContent,
        Button,
        SoftPhone,
    },
    directives: {},
    props: ['contact'],
    data() {
        return {
            callbackType: null,
            company_id: null,
            tel: null,
            refreshTimestamp: 0,
            title: 'Contact',
            CallButton: false,
            showSmsDiv: false,
            to_number: this.primaryContactNumber,
            userData: null,
            from_number: null,
            smsContent: null,
            twilio_number: null,
            isValidMobileNumber: false,
            transitions: {
                showNumber: true,
                showOutcome: true,
            },
            ts: 0,
            datetime: null,
            company_type: null,
            configdateTimePicker: {
                enableTime: true,
                dateFormat: 'd-m-Y H:i',
                minDate: new Date(),
                weekNumbers: false,
                static: true,
            },
            // Map
            center: {lat: 10.0, lng: 10.0},
            markers: [
                {position: {lat: 10.0, lng: 10.0}},
                {position: {lat: 11.0, lng: 11.0}},
            ],
            select1: [],
            outcomes: null,
            select2: [],
            notInterested: [
                {label: 'Too Many Calls', value: 1},
                {label: 'Use Other Broker', value: 2},
                {label: 'Just Agreed A Contract', value: 3},
                {label: 'A new contract recently live', value: 4},
                {label: 'Call Closer to the time', value: 5},
                {label: 'Customer Requests Removal', value: 6}, // Show dialog for data removal request
            ],
            avatars: [
                {avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins'},
                {avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson'},
                {avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles'},
                {avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber'},
                {avatar: "['fas', 'phone']", fullName: 'Add Collaborator'},
            ],
        }
    },
    computed: {
        activeUserInfo() {
            return getUserData
        },

        latestCallback: {

            get() {
                const callback = store.getters['company/getLatestCallback']
                return callback.callback
            },
            set(val) {
                this.datetime = val
            },
        },

        primaryContactDetails() {
            return store.getters['company/getPrimaryContact']
        },
        primaryContactNumber() {
            return store.getters['company/getPrimaryContactNumber']
        },

        getOutcomes() {
            const Outcomes = store.getters['company/getOutcomeList']
            return Outcomes
        },
        recordContactDetails() {
            return store.getters['company/getCompany']
        },

    },
    mounted() {
        const self = this
        this.userData = JSON.parse(localStorage.getItem('userDetails'));
        this.from_number = this.userData.phone_number;
        this.twilio_number = this.userData.twilio_phone_number;
        this.company_id = (self.$route.params.clientid) ? Number(self.$route.params.clientid) : null;
        bus.$on('phone:Call', tel => {
            self.tel = tel
            self.CallButton = !self.CallButton
        })
        bus.$on('contact:Update', () => {
            self.refreshTimestamp = Math.round(+new Date() / 1000)
        })
    },
    methods: {
        showSms() {
            this.to_number = this.primaryContactNumber;
            this.showSmsDiv = !this.showSmsDiv;
        },
        getOutcomeList(id = null) {
            if (id != null) {
                return this.getOutcomes.filter(outcome => outcome.parent_id == id)
            }
            return this.getOutcomes.filter(outcome => outcome.parent_id == null)
        },
        makeCall() {
            // console.log('call clicked');
            bus.$emit("phone:OpenDialer", {phone_number: this.primaryContactNumber, type: "contacts"});

            this.$emit('closeComponent'); // Emit an event to close the component

        },
        sendSMS() {
            console.log('sms clicked');
            if (this.validateMobileNumber()) {
                axios.post("/api/send-sms", {
                    "from_number": this.from_number,
                    "to_number": this.to_number,
                    "message": this.smsContent,
                    "company_id": this.company_id,
                    "type": 'unknown',
                    "twilio_number": this.twilio_number
                }).then(response => {
                    console.log(response);
                    if (response.data.data.status == 'sent') {
                        this.showSmsDiv = false;
                        Swal.fire({
                            title: 'Success!',
                            text: 'Message sent successfully!',
                            icon: 'success',
                            timer: 2000
                        });
                    } else {
                        Swal.fire({
                            title: 'Error!',
                            text: response.data.data.message,
                            icon: 'Error'
                        });
                    }

                }).catch(error => {
                    if (error.data.status === 422) {
                        this.errors = error.data.errors || {};
                        Swal.fire(
                            'Error!',
                            response.data.message,
                            'Error'
                        );
                    }
                })
            } else {
                console.log('Number not valid');

                Swal.fire(
                    'Error!',
                    'Number not valid',
                    'Error'
                );
            }
        },
        validateMobileNumber() {
            const validationRegex = /^\+[1-9]{1}[0-9]{7,11}$/;
            if (this.to_number.match(validationRegex)) {
                this.isValidMobileNumber = true;
            } else {
                this.isValidMobileNumber = false;
            }
            return this.isValidMobileNumber;
        },
        saveCallback() {
            const self = this
            if (self.datetime === null) {
                self.showToast('danger', 'You need to update your call back!', 'Error', 'AlertOctagonIcon')
            } else {
                const currentPath = this.$route.path.toLowerCase(); // Get the current path and convert to lowercase for case insensitivity

                if (currentPath.includes('prospects')) {
                    this.callbackType = 1; // Set prospectType to 1 if 'prospects' is in the URL
                } else if (currentPath.includes('client')) {
                    this.callbackType = 2; // Set prospectType to 2 if 'clients' is in the URL
                }
                axios.defaults.headers.common.Authorization = `Bearer ${document.querySelector('meta[name="csrf-token"]').getAttribute('content')}`
                axios.post('/api/callbacks', {
                    company_id: this.recordContactDetails.id,
                    id: this.activeUserInfo().sub,
                    callback: this.datetime,
                    type: this.callbackType,
                }).then(response => {
                    // TODO: Add validation for Blank
                    if (self.select1 > 0) {
                        self.saveOutcomes()
                    } else {
                        self.showToast('primary', 'Call Back Updated', 'Notification', 'BellIcon')
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },

        saveOutcomes() {
            const self = this
            if (this.datetime === null) {
                self.showToast('danger', 'You need to update your call back!', 'Error', 'AlertOctagonIcon')
            } else {
                axios.defaults.headers.common.Authorization = `Bearer ${document.querySelector('meta[name="csrf-token"]').getAttribute('content')}`
                axios.post('/api/outcomes', {
                    company_id: this.recordContactDetails.id,
                    id: this.activeUserInfo().sub,
                    // outcome: this.saveOutcomes(),
                    outcome: this.select1,
                    suboutcome: this.select2,
                    type: 1,
                }).then(response => {
                    self.showToast('primary', 'Call Outcome Logged & Call Back Updated')
                    bus.$emit('prospect:saveCallback')
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        showToast(variant, text = '', title = 'Notification', icon = 'BellIcon', timeout = 2000) {
            this.$toast({
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        variant,
                    },
                },
                {
                    timeout,
                })
        },
        checkSelection() {
            return this.getOutcomeList(this.select1)
        },
    },

}

</script>

<style scoped></style>
