<template>
  <div class="AddMeter">
    <b-card>
      <MeterDetails
        ref="meterinfo"
        @MeterValid="meterValid"
      />
      <template #footer>
        <b-button
          block
          :disabled="!meterValid"
          :variant="!meterValid? 'outline-primary':'primary'"
          @click="addMeter()"
        >
          Add Meter
        </b-button>

      </template>
    </b-card>
  </div>
</template>
<style lang = "scss">
@import '_AddMeter.scss';

</style>

<script>
// import draggable from 'vuedraggable'
// import Prism from 'vue-prism-component'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCard, BCardBody, BCardHeader, BCol, BCollapse, BFormGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { bus } from '@/main'
import MeterDetails from '@/views/components/SitesMeters/MeterDetails/MeterDetails.vue'
// import Meters from "@/views/components/SitesMeters/Meters/Meters";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import MeterRecord from "@/views/components/SitesMeters/Meters/MeterRecordPage/MeterRecordPage.vue";

export default {
  name: 'AddMeter',
  directives: {
    Ripple,
  },
  components: {
    MeterDetails,
    // draggable,
    // Prism,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    VBTooltip,
    BCollapse,
    BCardHeader,
    BCardBody,
    ToastificationContent,
    MeterRecord,
  },
  props: [],
  data() {
    return {
      meterValid: false,
    }
  },
  // computed: {
  //     sites() {
  //         return this.$store.getters["company/getSites"];
  //     }
  // },
  mounted() {
    const self = this
    bus.$on('MeterDetails:MeterValid', valid => {
      self.meterValid = valid
    })
  },
  methods: {
    addMeter() {
      let meter_details = this.$refs.meterinfo.MeterDetails;
      let getMeter = this.$store.getters["company/getMeters"];
      switch (meter_details.Type) {
        case 1:
          let water = {
            "spid":getMeter.spid,
            "spid_type" : getMeter.spid_type ?? 'W',
          }
          meter_details.meter = water;
          break
        case 2:
          let electric = {
            "profile":getMeter.profile,
            "mtc":getMeter.mtc,
            "llf":getMeter.llf,
            "mpan":getMeter.mpan,
            "area":getMeter.area
          }
          meter_details.meter = electric;
          break
        case 3:
          let gas = {
            "mprn":getMeter.mprn,
            "postcode":getMeter.postcode,
          }
          meter_details.meter = gas;
          break
      }
    
      store.dispatch('company/addMeter', meter_details).then(response => {
        this.meterValid = false;
        setTimeout(()=>{this.getErrors()}, 2000);
      });
    },
    getErrors() {
        let messageText , variantText, titleText = '';
        let errors = store.getters["company/getErrors"];
        if (errors) {
          this.meterValid = true;
          variantText = 'danger';
          titleText = 'Form Submission Error!'
          if (errors.status == 400) {
            messageText = errors.data.message;
          }
          else if (errors.status == 422) {
            messageText = "Please fill in the required fields with valid data!!"
          } else {
            messageText = "Something went wrong !! Please contact Support Team."
          }
        } else {
          variantText = 'success';
          titleText = 'Form Submitted Successfully!';
          messageText = '';
          let meter_id = store.getters["company/getLatestMeter"].id;
          this.$router.push("/apps/sales-engine/meter/" + meter_id);
        }

        this.$toast({
          component: ToastificationContent,
          props: {
              title: titleText,
              icon: 'XCircleIcon',
              variant: variantText,
              text: messageText
          },
        })
    }
  },
}
</script>
