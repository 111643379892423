var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "MeterRecordPage" },
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c("b-col", { attrs: { md: "10", xl: "10" } }),
          _vm._v(" "),
          _c("b-col", { attrs: { md: "2", xl: "2" } }, [
            _c(
              "div",
              [
                !_vm.currentData
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.addContract",
                            modifiers: { addContract: true },
                          },
                        ],
                        staticClass: "float-right",
                        attrs: { variant: "primary" },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "AlignJustifyIcon" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "base-modal",
                  {
                    attrs: {
                      id: "addContract",
                      "hide-footer": "",
                      size: "lg",
                      title: "Add Contract",
                    },
                  },
                  [
                    !_vm.currentData
                      ? _c("AddContract", {
                          attrs: {
                            meter_id: Number(_vm.$route.params.meterid),
                          },
                          on: { "save-success": _vm.hideModal },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [_c("b-col", { attrs: { md: "12", xl: "12" } }, [_vm._v(" ")])],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { md: "4", xl: "4" } },
            [
              _c(
                "b-card",
                [
                  _c("Identifiers", { attrs: { editing: false } }),
                  _vm._v(" "),
                  _c("br"),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4", xl: "4" } },
            [
              _c(
                "b-card",
                { staticClass: "card" },
                [
                  _c("b-card-title", [_vm._v("Current Contract Info")]),
                  _vm._v(" "),
                  _vm.currentData
                    ? _c(
                        "div",
                        [
                          _c("contractDetails", {
                            attrs: { contract: _vm.currentData },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4", xl: "4" } },
            [
              _c(
                "b-card",
                { staticClass: "card" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _vm._v(
                          "\n                        Consumption Log\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _vm._v(
            "\n        " +
              _vm._s(/**
        TODO: SPLIT THIS into two, add on the left (pull from Add Site Modal), site list on right (site & meter list
        already done)
        */) +
              "\n\n\n        "
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-card",
                { staticClass: "card" },
                [
                  _c("h4", [_vm._v("Future Contracts")]),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "4" },
                        },
                        [
                          _vm._v(
                            "\n                        Supplier\n\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "3" },
                        },
                        [
                          _vm._v(
                            "\n                        CSD\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "3", icon: "filter" },
                        },
                        [
                          _vm._v(
                            "\n                        CED\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "2", icon: "filter" },
                        },
                        [
                          _vm._v(
                            "\n                        Deal\n\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.futureData, function (future, index) {
                    return _c(
                      "b-card",
                      {
                        key: index,
                        staticClass:
                          "prospect-record-list b-card vx-row w-full",
                      },
                      [
                        _c("ContractRecord", {
                          staticClass: "contract-record",
                          attrs: { contractData: future },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-card",
                { staticClass: "card" },
                [
                  _c("h4", [_vm._v("Historic Contracts")]),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "4" },
                        },
                        [
                          _vm._v(
                            "\n                        Supplier\n\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "3" },
                        },
                        [
                          _vm._v(
                            "\n                        CSD\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "3", icon: "filter" },
                        },
                        [
                          _vm._v(
                            "\n                        CED\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "2", icon: "filter" },
                        },
                        [
                          _vm._v(
                            "\n                        Deal\n\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.pastData, function (past, index) {
                    return _c(
                      "b-card",
                      {
                        key: index,
                        staticClass:
                          "prospect-record-list b-card vx-row w-full",
                      },
                      [
                        _c("ContractRecord", {
                          staticClass: "contract-record",
                          attrs: { contractData: past },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "base-modal",
                    { attrs: { id: "viewContract", size: "xl" } },
                    [_c("viewContract")],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }