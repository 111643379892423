<template>
  <b-container class="SPID card border-primary font-large-1">
    <b-row>
      <b-col class="col-12">
      <ValidationProvider
          :disabled="!editing"
          v-slot="{ valid, errors }"
          rules="required"
          name="SPID"
          immediate
        >
        <b-input-group>
          <b-input
            v-model="meter.spid"
            :state = "errors[0] ? false : (valid ? true : null)"
            :disabled="!editing"
            class="spid-number"
            placeholder="Enter SPID number."
          />
          <template #append>
            <v-select
              v-model="SPIDType"
              variant="outline"
              :clearable="false"
              :searchable="false"
              :reduce="(option) => option.value"
              :options="types"
              transition=""
              class="spid-type"
              :disabled="!editing"
              type="number"
            >
              <template
                #selected-option-container="{ option }"
              >
                {{ option.value }}
              </template>
              <template #selected-option="option">
                {{ option.value }}
              </template>
            </v-select>
          </template>
        </b-input-group>
        <b-form-invalid-feedback id="inputLiveFeedback">
          {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCol, BContainer, BDropdown, BDropdownDivider, BDropdownItem, BInputGroup, BRow,
} from 'bootstrap-vue'
import store from '@/store'
import { bus } from '@/main'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {

  name: 'SPID',
  components: {
    BRow,
    BCol,
    BContainer,
    BInputGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      types: [
        { value: 'W', label: 'Water' },
        { value: 'S', label: 'Sewage' },
        { value: 'D', label: 'Drainage' },
      ],
      SPIDType:{ value: 'W', label: 'Water' },
      editing:false,
    }
  },
  props:{
    editVal:{
      type: Boolean
    }
  },
  watch: {
    SPIDType(newVal) {
      this.meter.spid_type = newVal
    },
  },
  computed: {
    meter() {
      return store.getters['company/getMeters']
    },
    // SPIDType() {
    //   return this.meter.spid_type
    // },
  },
  mounted() {
    const self = this;
    self.editing = self.editVal;
    bus.$on('Meter:Edit', () => {
      self.editing = true
    })
    bus.$on('Meter:Save', () => {
      self.editing = false
    })
  },
}
</script>

<style lang = "scss">

@import "SPID";
</style>
