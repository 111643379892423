var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notes
    ? _c(
        "vue-perfect-scrollbar",
        {
          ref: "notescroller",
          staticClass:
            "scrollable-container media-list scroll-area note-scroller",
          attrs: { settings: _vm.perfectScrollbarSettings, tagname: "div" },
        },
        [
          _c(
            "div",
            { ref: "notes", staticClass: "ViewNotes" },
            _vm._l(_vm.notes, function (note, index) {
              return _c("single-note", { key: index, attrs: { value: note } })
            }),
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }