<template>
    <div class="ProspectContacts">
        <b-card slot="no-body" class="text-right">

            <b-button class="" color="primary" variant="primary" v-if="canPermittedByRoleOrUser('add', 'contact')"
                @click="addContact">
                <font-awesome-icon :icon="['fa', 'plus']" />
                Add Contact
            </b-button>
            <b-button v-if="canPermittedByRoleOrUser('edit', 'contact')" :disabled="$v.contacts.$invalid" class=""
                color="primary" variant="primary" @click="updateContact">
                <font-awesome-icon :icon="['fa', 'save']" />
                Update Contacts
            </b-button>

        </b-card>
        <div :key="count" class="b-card w-full overflow-auto contact-list">
            <b-card slot="no-body" class="text-center">
                <b-row>
                    <b-col cols="1">
                        Title
                    </b-col>
                    <b-col cols="1">
                        First Name
                    </b-col>
                    <b-col cols="1">
                        Last Name
                    </b-col>
                    <b-col cols="1">
                        Position
                    </b-col>
                    <b-col cols="3">
                        <b-row>
                            <b-col cols="6">
                                LandLine
                            </b-col>
                            <b-col cols="6">
                                Mobile (with country code)
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="2">
                        Email
                    </b-col>
                    <b-col cols="3">
                        <b-row>
                            <b-col cols="2">

                            </b-col>
                            <b-col cols="2">

                            </b-col>
                            <b-col cols="2">

                            </b-col>
                            <b-col cols="2">

                            </b-col>
                            <b-col cols="2">

                            </b-col>
                            <b-col cols="2">

                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
                <b-row v-for="(contact, index) in contacts" :id="contact.id" :key="contact.id" class="mb-1 contact"
                    @click="toggleRow(index)">
                    <b-col cols="1">
                        <b-row>
                            <!--                        <b-checkbox v-model = "!!contacts[index].primary" class = "primary-contact fa fa-star" size = "lg" switch @change = "setPrimary($event,contact.id)" />-->
                            <b-col cols="3">
                                <b-checkbox v-model="contact.primary" class="primary-contact fa fa-star" size="lg"
                                    switch @change="setPrimary(contact.id)" />
                            </b-col>
                            <b-col cols="9">
                                <b-form-select v-model="contact.title" required
                                    :state="!$v.contacts.$each[index].title.$invalid ? null : false"
                                    :options="titleOptions" />
                                <!--                        <b-input v-model = "contact.title"  />-->
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="1">
                        <b-input v-model.trim="contact.forename"
                            :state="!$v.contacts.$each[index].forename.$invalid ? null : false" />
                    </b-col>
                    <b-col cols="1">
                        <b-input v-model.trim="contact.surname"
                            :state="!$v.contacts.$each[index].surname.$invalid ? null : false" />
                    </b-col>
                    <b-col cols="1">
                        <b-input v-model.trim="contact.position"
                            :state="!$v.contacts.$each[index].position.$invalid ? null : false" />
                    </b-col>
                    <b-col cols="3">
                        <b-row>
                            <b-col cols="6">
                                <b-input-group>
                                    <template #append>
                                        <!--                <b-button-->
                                        <!--                  :disabled="contact.landline===''"-->
                                        <!--                  :variant="contact.landline!=='' ? 'outline-primary' : 'outline-secondary'"-->
                                        <!--                  @click="getTelLink(contact.id)"-->
                                        <!--                >-->
                                        <!--                  <font-awesome-icon :icon="['fa', 'phone']" />-->
                                        <!--                </b-button>-->
                                    </template>

                                    <b-input v-if="contact.show_contacts || !hide_landline"
                                        v-model.trim="contact.landline"
                                        :state="!$v.contacts.$each[index].landline.$invalid ? null : false" />
                                    <b-input v-else :value="maskedData(contact.landline)"
                                        @focus="enableData('landline')" />
                                </b-input-group>
                            </b-col>
                            <b-col cols="6">
                                <b-input-group>
                                    <template #append>
                                        <!--                <b-button-->
                                        <!--                  :disabled="contact.mobile===''"-->
                                        <!--                  :variant="contact.mobile!=='' ? 'outline-primary' : 'outline-secondary'"-->
                                        <!--                  @click="getTelLink(contact.id)"-->
                                        <!--                >-->
                                        <!--                  <font-awesome-icon :icon="['fa', 'phone']" />-->
                                        <!--                </b-button>-->
                                    </template>
                                    <b-input v-if="contact.show_contacts || !hide_mobile" v-model.trim="contact.mobile"
                                        :state="!$v.contacts.$each[index].mobile.$invalid ? null : false" />
                                    <b-input v-else :value="maskedData(contact.mobile)" @focus="enableData('mobile')" />


                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="2">
                        <b-input-group>
                            <template #append>
                                <b-button :disabled="!contact.email" :href="getEmailLink(contact.email)"
                                    :variant="contact.email ? 'outline-primary' : 'outline-secondary'">
                                    <font-awesome-icon :icon="['fa', 'envelope']" />
                                </b-button>
                            </template>
                            <b-input v-if="contact.show_contacts || !hide_email" v-model="contact.email"
                                :state="!$v.contacts.$each[index].email.$invalid ? null : false" />
                            <b-input v-else :value="maskedData(contact.email)" @focus="enableData('email')" />

                        </b-input-group>
                    </b-col>
                    <b-col cols="3">
                        <b-row>
                            <b-col cols="2">

                                <b-checkbox v-if="contact.id !== null" v-model="contact.show_contacts"
                                    :button-variant="contact.show_contacts ? 'primary' : 'secondary'"
                                    name="check-button" @change="showContacts(contact.id, contact.show_contacts)"
                                    button>
                                    <feather-icon v-if="contact.show_contacts" icon="EyeIcon" size="16" />
                                    <feather-icon v-else icon="EyeOffIcon" size="16" />
                                </b-checkbox>
                            </b-col>

                            <b-col cols="2">
                                <b-button v-if="contact.id !== null && canPermittedByRoleOrUser('delete', 'contact')"
                                    aria-label="Remove" variant="outline-danger" class="delete-contact"
                                    :disabled="!!contact.primary" @click="deleteContact(contact)">
                                    <font-awesome-icon :icon="['fas', 'trash']" />
                                </b-button>
                            </b-col>

                            <b-col cols="2">
                                <b-button v-if="contact.mobile !== null && checkCallPermission()" aria-label="Call"
                                    variant="outline-primary" @click="callContact(contact)">
                                    <font-awesome-icon :icon="['fas', 'phone']" />
                                </b-button>
                            </b-col>

                            <b-col cols="2">
                                <b-button v-if="contact.mobile !== null && checkSMSPermission()" aria-label="SMS"
                                    variant="outline-primary" @click="smsContact(contact)">
                                    <font-awesome-icon :icon="['fas', 'mobile']" />
                                </b-button>
                            </b-col>

                            <b-col cols="2"></b-col>

                            <b-col cols="2"></b-col>
                        </b-row>
                    </b-col>
                </b-row>

            </b-card>

            <base-modal id="modal-sms" centered hide-footer title="Send SMS">
                <b-row>
                    <b-col cols="12">
                        <!-- <b-row>
                            <b-col cols = "12">
                                <b-form-input v-model="to_number" placeholder="Enter number"></b-form-input>
                            </b-col>
                        </b-row> -->

                        <b-row>
                            <b-col cols="12">
                                <b-form-textarea id="textarea" v-model="smsContent" placeholder="Enter something..."
                                    rows="3" max-rows="6"></b-form-textarea>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-button class="callBackButton" variant="primary"
                                    :disabled="to_number == null || smsContent == null" @click="sendSMS()">Submit
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </base-modal>
        </div>
    </div>
</template>

<style lang="scss">
@import "_ProspectContacts";
</style>

<script>
import Vuelidate from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { BButton, BCard, BCol, BInputGroup, BModal, BRow, } from 'bootstrap-vue'
import axios from 'axios'
import store from '@/store'
import { bus } from '@/main'
import AddContact from './AddContact/AddContact'
import Swal from 'sweetalert2'
import { useUtils as useAclUtils } from '@core/libs/acl'
import BaseModal from "@/views/components/modal/BaseModal.vue";
// TODO: We need some VALIDATION!!!!!!!!
// TODO:Set max heigt and add scroll bar
// TODO: Add Save button "Update Contacts"
export default {
    name: 'ProspectContacts',
    transitions: {
        showAddContact: true,

    },
    components: {
        BaseModal,
        AddContact,
        BRow,
        BCol,
        BCard,
        BModal,
        BButton,
        BInputGroup,
        Vuelidate,
    },

    props: {

        company_id_lead: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            count: 0,
            currentRow: 0,
            titleOptions: [
                { text: 'Mr', value: 'Mr' },
                { text: 'Mrs', value: 'Mrs' },
                { text: 'Ms', value: 'Ms' },
                { text: 'Miss', value: 'Miss' },
                { text: 'Dr', value: 'Dr' },
                { text: 'Sir', value: 'Sir' },
                { text: 'Lord', value: 'Lord' },
                { text: 'Lady', value: 'Lady' },
                { text: 'Honorable', value: 'Honorable' }],
            editing: true,
            hide_landline: true,
            hide_mobile: true,
            hide_email: true,
            smsContent: null,
            isValidMobileNumber: false,
            from_number: null,
            to_number: null,
            company_id: null,
            smstype: 'contacts',
            twilio_number: null,
        }
    },

    validations: {
        contacts: {
            $each: {

                title: {
                    required,
                },
                forename: {
                    required,
                },
                surname: {
                    required,
                },
                position: {
                    required,
                },

                // TODO:Landline or Mobile
                landline: {
                    required(val, v) {
                        // debugger
                        return v.mobile || required(val)
                    },
                },
                mobile: {
                    required(val, v) {
                        return v.landline || required(val)
                    },
                },
                email: {
                    email,
                    required,
                },

            },
        },
    },
    computed: {
        companyInfo() {
            return store.getters['company/getCompany']
        },
        contacts: {
            get() {
                return store.getters['company/getContacts']
            },
            set(newVal) {
                this.$v.$touch()
                if (this.$v.$anyError) {
                    return false
                }
                store.dispatch('company/saveContacts', newVal)
            },
        },
    },
    watch: {
        contacts: {
            deep: true,
            handler(newVal) {
                this.editing = false
            },
        },
    },
    mounted() {
        // alert("mounted")
        this.initialContacts = JSON.parse(JSON.stringify(this.contacts))
        this.userData = JSON.parse(localStorage.getItem('userDetails'));
        this.from_number = this.userData.phone_number;
        this.twilio_number = this.userData.twilio_phone_number;

        // Assign the company_id based on route params or props
        if (this.$route.params.clientid) {
            // Use clientid from the route if available
            this.company_id = Number(this.$route.params.clientid);
        } else if (this.companyInfo?.id) {
            // Use companyInfo.id if available
            this.company_id = this.companyInfo.id;
        } else if (this.company_id_lead) {
            // Fallback to company_id_lead prop
            this.company_id = this.company_id_lead;
        } else {
            // Handle the case where no ID is found
            alert('No company ID found!');
            this.company_id = null;
        }

        console.log("company id" + this.company_id);
    },
    created() {
        console.log('create')
    },
    methods: {
        showContacts(contact_id, value) {
            if (value == true) {
                axios
                    .post('/api/log-contacts/' + this.companyInfo.id + '/' + contact_id)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(err => {

                    });
            }

        },
        checkCallPermission() {
            let userData = JSON.parse(localStorage.getItem('userDetails'));
            let showPhone = false;
            if (this.canPermittedByRoleOrUser('make-call', 'calls')) {
                showPhone = true;

                if (userData.phone_number == null || userData.phone_number == undefined) {
                    showPhone = false;
                }
            }
            else {
                showPhone = false;
            }
            return showPhone;
        },

        checkSMSPermission() {
            let userData = JSON.parse(localStorage.getItem('userDetails'));
            let showSms = false;
            if (this.canPermittedByRoleOrUser('send-sms', 'sms')) {
                showSms = true;

                if (userData.phone_number == null || userData.phone_number == undefined) {
                    showSms = false;
                }
            }
            else {
                showSms = false;
            }
            return showSms;
        },
        callContact(contact) {
            console.log("contact");
            bus.$emit("phone:OpenDialer", { phone_number: contact.mobile, type: "contacts" });

            this.$emit('closeComponent'); // Emit an event to close the component


        },

        smsContact(contact) {
            console.log(contact);
            this.to_number = contact.mobile;
            this.$bvModal.show("modal-sms");
        },
        sendSMS() {
            console.log('sms clicked');
            if (this.validateMobileNumber()) {
                axios.post("/api/send-sms", {
                    "from_number": this.from_number,
                    "to_number": this.to_number,
                    "message": this.smsContent,
                    "company_id": this.company_id,
                    "type": this.smstype,
                    "twilio_number": this.twilio_number,

                }).then(response => {
                    console.log(response);
                    if (response.data.data.status == 'sent') {
                        this.$bvModal.hide("modal-sms");
                        Swal.fire({
                            title: 'Success!',
                            text: 'Message sent successfully!',
                            icon: 'success',
                            timer: 2000
                        });
                    }
                    else {
                        Swal.fire({
                            title: 'Error!',
                            text: response.data.data.status,
                            icon: 'Error'
                        });
                    }

                }).catch(error => {
                    if (error.data.status === 422) {
                        this.errors = error.data.errors || {};
                        Swal.fire(
                            'Error!',
                            response.data.message,
                            'Error'
                        );
                    }
                })
            }
            else {
                console.log('Number not valid');

                Swal.fire(
                    'Error!',
                    'Number not valid',
                    'Error'
                );
            }
        },
        validateMobileNumber() {
            const validationRegex = /^\+[1-9]{1}[0-9]{7,11}$/;
            if (this.to_number.match(validationRegex)) {
                this.isValidMobileNumber = true;
            } else {
                this.isValidMobileNumber = false;
            }
            return this.isValidMobileNumber;
        },
        deleteContact(contact) {
            this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the contact "${contact.forename} ${contact.surname}"?`, {

                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
            })
                .then(value => {
                    if (value) {
                        store.commit('company/DELETE_CONTACT', contact.id)
                        this.count += 1
                    }
                })
            //
        },
        maskedData(value, percentageToShow = 25) {
            // Check if the value is not empty and the percentage is valid
            if (!value || typeof value !== 'string' || percentageToShow <= 0 || percentageToShow >= 100) {
                return '';
            }

            const visibleCharacters = Math.ceil((percentageToShow / 100) * value.length);
            const hiddenCharacters = value.length - visibleCharacters;

            // Replace characters with asterisks for the hidden part
            const hiddenPart = '*'.repeat(hiddenCharacters);

            // Concatenate the visible and hidden parts
            const maskedValue = value.substring(0, visibleCharacters) + hiddenPart;

            return maskedValue;
        },
        enableData(type) {
            console.log("enable");
            this['hide_' + type] = false;
        },
        validateState(name) {
            if (this.$v[name]) {
                const { $dirty, $error } = this.$v[name]
                return $dirty ? !$error : null
            }
        },
        validateEmail(email) {
            if (email === '') {
                return null
            }
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                return true
            }
            return false
        },
        setPrimary(id) {
            if (e === true) {
                this.contacts.forEach(c => {
                    if (c.id !== id) {
                        c.primary = false
                    } else {
                        c.primary = true
                    }
                })
            }
        },
        getTelLink(tel) {
            if (tel) {
                this.updateContact()
                bus.$emit('phone:Call', tel)
            }
        },
        getEmailLink(email) {
            if (email) {
                return `mailto:${email.replace(' ', '')}`
            }
        },
        arrayDiff(a, b) {
            return [
                ...a.filter(x => !b.includes(x)),
                ...b.filter(x => !a.includes(x)),
            ]
        },
        goBack() {
            this.ts += 1
            this.showTab('showProspectContacts')
        },
        toggleRow(row) {
            this.currentRow = row
        },
        addContact() {
            this.contacts.push({
                id: null,
                company_id: this.companyInfo.id,
                primary: 0,
                show_contacts: true,
            })
        },
        updateContact() {
            this.$v.$touch()
            if (this.$v.$anyError) {
                return false
            }
            const self = this
            store.dispatch('company/saveContacts', this.contacts).then(() => {
                setTimeout(() => {
                    self.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: 'Contact Updated',
                        },
                    })

                    store.dispatch('company/setContacts', self.contacts)
                    bus.$emit('contact:Update', self.contacts)
                    self.editing = true
                    this.$v.$reset()
                }, 2000);
            })
        },
        showTab(tab) {
            const self = this
            self.ts += 1
            switch (tab) {
                case 'AddContact':
                    this.transitions.showProspectContacts = false
                    this.transitions.showAddContact = true
                    // console.log(self.getContacts);
                    // alert("Check Console");
                    break
            }
        },
    },
    setup() {
        const { canPermittedByRoleOrUser } = useAclUtils()

        return {
            // ACL
            canPermittedByRoleOrUser,
        }
    },
}

</script>
