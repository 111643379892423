<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
           <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
           
          >
            <label>Show</label>
            <v-select
              v-model="queryBody.per_page"
              :options="pageOptions"
              :clearable="false"
               @option:selected="changePageCount($event)"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <!-- Per Page -->
          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            v-if="showFilter"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-selectize 
              :options="agentOptions" 
              v-model="dropdownFilterUser" 
              :reduce="val => val.value"
              key-by="value" 
              label="label" 
              :keys="['label', 'value']"
              @input="onSelectUserChange"
              class="w-50 m-1"
              />
              <!-- <v-select
                lebel="Agent"
                v-model="dropdownFilterUser"
                :reduce="val => val.value"
                :clearable="false"
                :options="agentOptions"
                class="w-100"
                @input="onSelectUserChange"
              /> -->
              <v-select
                v-model="dropdownFilter"
                :reduce="val => val.value"
                :clearable="false"
                :options="performedByOptions"
                class="w-50 m-1"
                @input="onSelectChange"
              />
              <b-input-group size="md" class="w-50 m-1">
                <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type here to Search ..."
                    v-on:keyup="findData"
                    @search="clearSearch"
                ></b-form-input>
                <b-input-group-append>
                    <b-button
                        :disabled="!filter"
                        @click="findData"
                        variant="primary"
                    >Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>

      </div>
      <div class="table-container table-log-component">
        <b-table
          :busy="isTableLoading"
          :items="itemsData"
          responsive="true"
          :fields="fields"
          primary-key="id"
          no-local-sorting
          @sort-changed="customSorting"
          show-empty
          empty-text="No matching records found"
          
        >
          <template v-slot:table-busy>
              <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
              </div>
          </template>
          <template v-slot:cell(properties.to)="row">
            <div v-html="row.item.properties.to"></div>
          </template>
          <template v-slot:cell(properties.from)="row">
            <div v-html="row.item.properties.from"></div>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ pageMeta.from }} to {{ pageMeta.to }} of {{ totalRows}} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="queryBody.page"
              :total-rows="totalRows"
              :per-page="queryBody.per_page"
              @input="pagination"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
 

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import axios from 'axios'; 
import moment from 'moment-timezone'
import VSelectize from '@isneezy/vue-selectize'
import 'selectize/dist/css/selectize.default.css'
export default {
  name: 'Log',
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VSelectize,
  },
   props: {
    filterParams: {
      type: [Object, String, Number], // Allow Object, String, or Number
      required:false,
    },
    showFilter:{
      type: Boolean,
      required:false,
    },
    userType:{
      type: String,
      required:false,
    },
    company_id:{
      type: Number,
      required:false,
    },
  },
  mounted() {
    axios
    .get('/api/list-users/status?status=active')
    .then((response) => {
      console.log(response);
      let result = response.data;
      console.log(result);
      this.agentOptions=[];
      this.agentOptions = [
        {
          label: 'Please select one of below agents',
          value: ''
        },
        ...result.data.map((item)=>({
          value:item.id,label:item.name
        }))
      ];
      
      console.log(this.agentOptions);
    })
    .catch((error) => {
      // Handle errors here
      console.error('Error fetching user data:', error);
    })
    .finally(() => {
        this.isTableLoading = false;
    });
    if(this.filterParams)
    {
      this.queryBody.query=this.filterParams;
    }
    if(this.userType)
    {
      this.queryBody.user_type=this.userType;
    }
    if(this.company_id)
    {
       this.queryBody.company_id=this.company_id;
    }
    this.getData(this.queryBody);
  },
  
  data() {
    return {
      itemsData: [], 
      fields: [
          // { key: 'properties.date', label:'Date',sortable:true},
          // { key: 'properties.time', label:'Time',sortable:true},
          // { key: 'properties.performed_by', label:'Performed By'},
          { key: 'properties.section_name', label:'Where'},
          // { key: 'properties.performed_by', label:'Performed By'},
          { key: 'properties.where', label:'URL'},
          { key: 'description', label:'Description'},
          { key: 'properties.from', label:'From',tdClass: 'width-adjustment'},
          { key: 'properties.to', label:'To',tdClass: 'width-adjustment'},

          // { key: 'description', label:'Title'},
          // { key: 'properties.where', label:'URL'},
          // { key: 'properties.from', label:'From',tdClass: 'width-adjustment'},
          // { key: 'properties.to', label:'To',tdClass: 'width-adjustment'},
      ],
      queryBody: {
          sort_by: "",
          order: "",
          page: 1,
          per_page: 10,
          user_id:'',
      },
      pageOptions: [10, 25, 50, 100],
      lastPage: 1,
      totalRows: 1,
      filter: '',
      performedByOptions : [
        { value: "",label:'Please Select one of below collborator'},
      ],
      agentOptions : [{ value: '',label:'Please Select one of below agents'},],
      dropdownFilterUser: '',
      dropdownFilter:"",
      isTableLoading: false,
    }
  },
  computed: {
    pageMeta() {
      const from = (this.queryBody.page - 1) * this.queryBody.per_page + 1;
      const to =
        this.queryBody.page * this.queryBody.per_page > this.totalRows
          ? this.totalRows
          : this.queryBody.page * this.queryBody.per_page;

      return { from, to };
    }
  },
  methods:{
    refetchData(){
      this.getData(this.queryBody);
    },
    onSelectUserChange(){
      this.queryBody.user_id=this.dropdownFilterUser.value;
      this.getData(this.queryBody);
    },
    onSelectChange(){
      this.queryBody.query=this.dropdownFilter;
      this.getData(this.queryBody);
    },
    findData() {
      this.queryBody.query=this.filter;
      this.getData(this.queryBody);
    },
    
    clearSearch(event)
    {
      if(event.target.value ==="")
      {
        if(!this.filter)
        {
          this.queryBody.page=1;
          this.queryBody.per_page=10;
          delete this.queryBody['query'];
          this.getData(this.queryBody);
        }

      }
    },
    changePageCount(per_page) {
      this.queryBody.page=1;
      this.queryBody.per_page=per_page;
      this.getData(this.queryBody);
               
    },
    getData(params) {
      this.isTableLoading = true;
      var query={};
      query["params"] = this.deleteEmptyKeys(params);
      axios
        .get('/api/activity-log',query)
        .then((response) => {
          let result = response.data;
          this.itemsData = result.data.map((item) => ({
            ...item,
            'properties.date': this.getDate("date",item.properties.when),
            'properties.time': this.getDate("time",item.properties.when),
          }));
          if(result.performed_by)
          {
            const performedByArray = result.performed_by;
            this.performedByOptions=[];
            this.performedByOptions = [
              {
                label: 'Please select one of below collaborator',
                value: ''
              },
              ...performedByArray.map((item) => ({
                label: item,
                value: item,
              }))
            ];
          }
          
          let pagination=result.pagination;
          this.queryBody.page = pagination.current_page;
          this.queryBody.per_page = pagination.per_page;
          this.lastPage = pagination.last_page;
          this.totalRows = pagination.total;         
        })
        .catch((error) => {
          // Handle errors here
          console.error('Error fetching user data:', error);
        })
        .finally(() => {
            this.isTableLoading = false;
        });
    },
    getDate(field,dateTimeString) {
      const timeZone = 'Europe/London';
      const dateTimeInLondon = moment.tz(dateTimeString, 'DD-MM-YYYY hh:mm:ss A', timeZone);
      if(field =='date')
      {
        return  dateTimeInLondon.format('DD-MM-YYYY')
      } else{
        return  dateTimeInLondon.format('hh:mm:ss A')
      }
    },
    customSorting: function(ctx) {
        if (ctx.sortBy == "" || ctx.sortBy == null) {
            return;
        }
        this.queryBody.order = ctx.sortDesc ? "asc" : "desc";
        this.queryBody.sort_by = ctx.sortBy;
        this.queryBody.page = 1;
        this.getData(this.queryBody);
    },
    pagination: function(pageNumber) {
      this.queryBody.page=pageNumber;
      this.getData(this.queryBody);
    },
    
  },
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.actions .circle {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
}


// .b-table .width-adjustment {
//   word-wrap: break-word !important;
//   width: 350px !important;
// }
// .b-table th {
//   white-space: nowrap;
// }
// .table-log-component .b-table {
//   display: block;
//   margin: 0 auto;
//   overflow-x: auto;
//   white-space: nowrap;
// }
.table-log-component .b-table {
  margin: 0 auto;
  overflow-x: auto;
}
</style>
