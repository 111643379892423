<template>

  <div>
    <b-card no-body class="mb-0">
      <div class="ContactDetails" v-if="sms_details!==null">
        <b-row>
          <b-col class="detail-options" cols="3">
            <b>To:</b>
          </b-col>

          <b-col class="detail-options" cols="9">
            {{ sms_details.data.to }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="detail-options" cols="3">
            <b>Content:</b>
          </b-col>

          <b-col class="detail-options" cols="9">
              {{ sms_details.data.message }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="detail-options" cols="3">
            <b>Status:</b>
          </b-col>

          <b-col class="detail-options" cols="9">
              {{ sms_details.data.status }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="detail-options" cols="3">
            <b>Message Sent on:</b>
          </b-col>

          <b-col class="detail-options" cols="9">
              {{ sms_details.data.message_date_created }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button @click="deleteSms" v-if="canPermittedByRoleOrUser('delete-sms','sms')">
              Delete
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import axios from 'axios'; 
import moment from 'moment-timezone'
import {bus} from "@/main";
import Swal from 'sweetalert2'
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {
  name: 'SmsDetails',
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
   props: {
    sid:{
      type: String,
      required:false,
    },
  },
  setup() {
        const { canPermittedByRoleOrUser } = useAclUtils()

        return {
            // ACL
            canPermittedByRoleOrUser,
        }
    },
  mounted() {
      axios
        .get('/api/sms-details/'+this.sid)
        .then((response) => {
          // console.log(response);
          this.sms_details = response.data;
        })
        .catch((error) => {
          // Handle errors here
          console.error('Error fetching user data:', error);
        });
  },
  
  data() {
    return {
      sms_details: null,
    }
  },
  methods:{
    deleteSms()
    {
      axios
        .delete('/api/delete-sms/'+this.sid)
        .then((response) => {
          // console.log(response);
          // this.sms_details = response.data;
          Swal.fire({
            title:'Action Successful!',
            text:response.data.message,
            icon:'success',
            timer: 3000
          });
          bus.$emit("close:smsDetailsModel", response);
        })
        .catch((error) => {
          // Handle errors here
          console.error('Error fetching user data:', error);
        });
    }
  },
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.actions .circle {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
}


// .b-table .width-adjustment {
//   word-wrap: break-word !important;
//   width: 350px !important;
// }
// .b-table th {
//   white-space: nowrap;
// }
// .table-log-component .b-table {
//   display: block;
//   margin: 0 auto;
//   overflow-x: auto;
//   white-space: nowrap;
// }
.table-log-component .b-table {
  margin: 0 auto;
  overflow-x: auto;
}
</style>
