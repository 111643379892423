var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card-code",
        {
          attrs: { title: "Relief" },
          scopedSlots: _vm._u([
            {
              key: "code",
              fn: function () {
                return [
                  _vm._v("\n      " + _vm._s(_vm.codeButtonRelief) + "\n    "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-card-text", { staticClass: "mb-0" }, [
            _c("span", [_vm._v("Use ")]),
            _vm._v(" "),
            _c("code", [_vm._v(".btn-relief-{color}")]),
            _vm._v(" "),
            _c("span", [_vm._v(" to create a relief button.")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "demo-inline-spacing" },
            [
              _c("b-button", { attrs: { variant: "relief-primary" } }, [
                _vm._v("\n        Primary\n      "),
              ]),
              _vm._v(" "),
              _c("b-button", { attrs: { variant: "relief-secondary" } }, [
                _vm._v("\n        Secondary\n      "),
              ]),
              _vm._v(" "),
              _c("b-button", { attrs: { variant: "relief-success" } }, [
                _vm._v("\n        Success\n      "),
              ]),
              _vm._v(" "),
              _c("b-button", { attrs: { variant: "relief-danger" } }, [
                _vm._v("\n        Danger\n      "),
              ]),
              _vm._v(" "),
              _c("b-button", { attrs: { variant: "relief-warning" } }, [
                _vm._v("\n        Warning\n      "),
              ]),
              _vm._v(" "),
              _c("b-button", { attrs: { variant: "relief-info" } }, [
                _vm._v("\n        Info\n      "),
              ]),
              _vm._v(" "),
              _c("b-button", { attrs: { variant: "relief-dark" } }, [
                _vm._v("\n        Dark\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }