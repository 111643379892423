export default {
    sectors: [],
    providers: [],
    file: [],
    headers: [],
    records: [],
    batchinfo:[],
    totals: [],
    charts: {
        sector: [],
        provider: []
    }
}
