var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "MPAN border-primary" },
    [
      _c(
        "b-col",
        [
          _c("mpanTop", { attrs: { editVal: _vm.editVal } }),
          _vm._v(" "),
          _c("mpanBottom", { attrs: { editVal: _vm.editVal } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }