<template>
    <b-card class = "FileList">
        <template v-if = "files">
            <b-row>
                <b-col cols = "5">
                    <!--                        <strong>File Name</strong>-->

                </b-col>

                <b-col
                    cols = "3"
                    text-aligh = "right"
                >
                    Uploaded By
                </b-col>
                <b-col cols = "3">
                    Uploaded On
                </b-col>
                <b-col cols = "1" />
            </b-row>
            <b-row
                v-for = "file in files"
                :key = "file.id"
                class = "border grid-box-item file-row min-height: 50px; @clic"
            >

                <b-col
                    cols = "5"
                    @click = "FileItem(file)"
                >
                    <b-row>
                        <strong
                            style = "padding-right: 10px;"
                        >{{ file.name || "" }}
                        </strong>
                        <span class = "text-muted">({{ file.size }})</span>
                    </b-row>
                </b-col>
                <b-col
                    cols = "3"
                    @click = "FileItem(file)"
                >
                    <b-row>
                        <b-avatar
                            slot = "avatar"
                            :v-b-tooltip.hover.bottom = "file.user.name"
                            :text = "file.user.initials"
                        />
                    </b-row>
                </b-col>
                <b-col
                    cols = "3"
                    @click = "FileItem(file)"
                >

                    <b-row>
                        <b-col
                            class = "timestamp text-muted"
                            v-text = "file.created_at"
                        />
                    </b-row>
                </b-col>
                <b-col cols = "1">
                    <b-row>
                        <button
                            aria-label = "Remove"
                            class = "close remove"
                            type = "button"
                            v-if="canPermittedByRoleOrUser('delete', 'files')"
                            @click = "deleteFile(file.id)"
                        >
                            <span aria-hidden = "true">&times;</span>
                        </button>
                    </b-row>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            Loading....
        </template>
    </b-card>
</template>

<script>
import {BAvatar, BCard, BCol, BRow,} from 'bootstrap-vue'
import store from '@/store'
import axios from 'axios'
import {bus} from '@/main'
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {

    name: 'FileList',

    components: {
        BCard,
        BRow,
        BCol,
        BAvatar,
    },
    data() {
        return {
            loading: false,
            fileRecords: [],
            uploadUrl: '/api/file-upload',
        }
    },
    computed: {
        company() {
            return store.getters['company/getCompany']
        },
        files() {
            this.loading
            console.log('Loading Files')
            return store.getters['company/getFiles']
        },
    },
    mounted() {
        const self = this
        self.$store.dispatch('company/setFiles').then(response => {
            self.loading = !self.loading
        })
    },
    methods: {
        deleteFile(file) {
            const self = this
            axios.post(`/api/file-upload/${file}`, {_method: 'delete'}).then(response => {
                console.log(response)
                bus.$emit('fileManager:fileUploaded')
                // TODO: Work on generalizing toast (Use Bus emit?)
            })
        },
        FileItem(FileItem) {
            axios({
                url: `/api/download/${FileItem.id}`,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a')

                fileLink.href = fileURL
                // debugger;
                fileLink.setAttribute('download', FileItem.name)
                // console.log(fileLink)
                document.body.appendChild(fileLink)

                fileLink.click()
            })
        },
        validateFile() {
            this.formData.file = this.file
            return true
        },
    },
    setup() {
        const { canPermittedByRoleOrUser } = useAclUtils()

        return {
            // ACL
            canPermittedByRoleOrUser,
        }
    },
}
</script>

<style lang = "scss">
@import './_FileList.scss';
</style>
