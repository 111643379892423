<template>
  <div class="SitesMeters">
    <b-row>
      <b-col>
        <AddSiteMeter />
      </b-col>
      <b-col>
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="scroll-area"
        >
          <Sites />
        </vue-perfect-scrollbar>
      </b-col>
    </b-row>

  </div>
</template>
<style lang = "scss">
@import '_SitesMeters.scss';

</style>

<script>
// import draggable from 'vuedraggable'
// import Prism from 'vue-prism-component'
import {
  BButton, BCard, BCardBody, BCardHeader, BCol, BCollapse, BFormGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import AddSiteMeter from '@/views/components/SitesMeters/AddSiteMeter/AddSiteMeter.vue'
import Sites from '@/views/components/SitesMeters/Sites/Sites'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'SitesMeters',

  components: {
    // draggable,
    // Prism,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    VBTooltip,
    BCollapse,
    BCardHeader,
    BCardBody,
    Sites,
    AddSiteMeter,
    VuePerfectScrollbar,
  },
  props: ['value'],
  // computed: {
  //     sites() {
  //         return this.$store.getters["company/getSites"];
  //     }
  // },
  data() {
    return {

      addSite:
                { icon: 'plus', avatar: null, fullName: 'Add Site' },

      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        suppressScrollX: true,
      },
      fileRefresh: 0,
    }
  },
  methods: {
    // addSite() {
    //     this.addSite = !this.addSite;
    //
    // },
  },
}
</script>
