<template>
  <b-form-row class="mpanTop">
    <b-input-group>

      <template #append>
        <ValidationProvider
          v-slot="{ valid, errors }"
          rules="required"
          name="profile"
          immediate
        >
        <b-input
          v-model="meter.profile"
          :state = "errors[0] ? false : (valid ? true : null)"
          :disabled="!editing"
          placeholder="Profile"
          maxlength="2"
          @change="checkProfile"
          class="progress-bar-animated"
        />
        <b-form-invalid-feedback id="inputLiveFeedback">
        {{ errors[0] }}
        </b-form-invalid-feedback>
        </ValidationProvider>
        <!--                        PROFILE CLASS-->

        <!--                        MTC - Meter Tineswitch Code-->
        <ValidationProvider
          v-slot="{ valid, errors }"
          rules="required"
          name="mtc"
          immediate
        >
        <b-input
          v-model="meter.mtc"
          :state = "errors[0] ? false : (valid ? true : null)"
          :disabled="!editing"
          class="mtc"
          maxlength="3"
          placeholder="MTC"
          @change="checkMtc"
        />
        <b-form-invalid-feedback id="inputLiveFeedback">
        {{ errors[0] }}
        </b-form-invalid-feedback>
        </ValidationProvider>
        <!--                        LLF - Line Loss Factor-->
        <ValidationProvider
          v-slot="{ valid, errors }"
          rules="required"
          name="llf"
          immediate
        >
        <b-input
          v-model="meter.llf"
          :state = "errors[0] ? false : (valid ? true : null)"
          :disabled="!editing"
          class="llf"
          maxlength="3"
          placeholder="LLF"
          @change="checkLLF"
        />
        <b-form-invalid-feedback id="inputLiveFeedback">
        {{ errors[0] }}
        </b-form-invalid-feedback>
        </ValidationProvider>
      </template>
    </b-input-group>
  </b-form-row>
</template>

<script>
import { BCol, BContainer, BRow } from 'bootstrap-vue'
import store from '@/store'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: 'MpanTop',
  components: {
    BRow,
    BCol,
    BContainer,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent
  },

  data() {
    return {
      editing: false,
    }
  },
  props:{
    editVal:{
      type: Boolean
    }
  },
  mounted() {
    this.editing = this.editVal;
  },
  computed: {
    meter() {
      return store.getters['company/getMeters']
    },
  },
  methods: {
    checkProfile() {
        if (this.meter.profile.length < 2) {
          let msg = "Profile must be of length 2 !!"
          this.meter.profile = '';
          this.getErrors(msg);
          return false;
        }
    },
    checkMtc() {
      if (this.meter.mtc.length < 3) {
          let msg = "MTC must be of length 3 !!"
          this.meter.mtc = '';
          this.getErrors(msg);
          return false;
          
      }
    },
    checkLLF() {
      if (this.meter.llf.length < 3) {
          let msg = "LLF must be of length 3 !!"
          this.meter.llf = '';
          this.getErrors(msg);
          return false;
      }
    },
    getErrors(msg) {
        this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Error!',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: msg
        },
      })
    }
  }
}
</script>

<style lang = "scss">

@import "mpanTop";
</style>
