<template>
  <div class="AddSite">
    <b-card>
      <SiteDetails
        ref="siteinfo"
        @SiteValid="siteValid"
      />
      <template #footer>
        <b-button
          block
          :disabled="!siteValid"
          :variant="!siteValid? 'outline-primary':'primary'"
          @click="addSite()"
        >
          Add Site
        </b-button>

      </template>
    </b-card>

  </div>
</template>

    <b-button @click="addSite()">
      Add Site
    </b-button>
  </div>
</template>
<style lang = "scss">
@import '_AddSite.scss';

</style>

<script>
// import draggable from 'vuedraggable'
// import Prism from 'vue-prism-component'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCard, BCardBody, BCardHeader, BCol, BCollapse, BFormGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import SiteDetails from '@/views/components/SitesMeters/SiteDetails/SiteDetails.vue'
import store from '@/store'
import { bus } from '@/main'
// import Meters from "@/views/components/SitesMeters/Meters/Meters";

export default {
  name: 'AddSite',
  directives: {
    Ripple,
  },
  components: {
    SiteDetails,
    // draggable,
    // Prism,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    VBTooltip,
    BCollapse,
    BCardHeader,
    BCardBody,

  },
  props: [],
  data() {
    return {
      siteValid: false,
    }
  },
  // computed: {
  //     sites() {
  //         return this.$store.getters["company/getSites"];
  //     }
  // },
  mounted() {
    const self = this
    bus.$on('SiteDetails:SiteValid', valid => {
      self.siteValid = valid
    })
  },
  methods: {
    addSite() {
      store.dispatch('company/addSite', this.$refs.siteinfo.SiteDetails)
    },
  },
}
</script>
