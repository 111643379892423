<template>
    <div class="ContractRecord">
        <b-row slot="no-body" @click="openContractRecord">
            <b-col class="d-flex align-items-center" cols="4">
                {{contractData.supplier.name}}
            </b-col>
            <b-col class="d-flex align-items-center" cols="3">
                {{ contractData.start_date ?? 'Unknown'}}
            </b-col>
            <b-col class="d-flex align-items-center" cols="3" icon="filter">
                {{ contractData.end_date }}
            </b-col>
          

            <b-col class="d-flex align-items-center" cols="2" icon="filter">
                <b-button variant="transparent">
                    <font-awesome-icon :icon="['fas', 'handshake']" size="2x"/>
                    <b-badge variant="secondary">External</b-badge>
                </b-button>
            </b-col>
        </b-row>


    </div>
</template>
<style lang="scss">

@import "ContractRecord";
</style>

<script>
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BCollapse,
    BFormGroup,
    BRow,
    VBToggle,
    VBTooltip,
} from 'bootstrap-vue';
import FontAwesome from 'vue-fontawesome';
import MeterRecord from "@/views/components/SitesMeters/Meters/MeterRecordPage/MeterRecordPage.vue";
import viewContract from "@/views/services/broker/contracts/viewContract/viewContract";
import axios from "axios";
import moment from 'moment';
import {bus} from '@/main'

export default {
    name: "ContractRecord",
    // mounted() {
    //
    //     store.dispatch("company/setMeters", this.meterid);
    // },
    methods: {
        openContractRecord() {
            bus.$emit('ViewContractModal:prepareData', this.contractData)
            this.$bvModal.show('viewContract');
        },
        getVariant(date) {
            var now = moment();

            var datecheck = moment(date, "DD/MM/YYYY");
            var datediff = datecheck.diff(now, "days");
            console.log(datediff)
            if (datediff < 0) {
                return "danger";
            } else if (datediff < 30 && datediff > 0) {
                return "warning";
            } else {
                return "success";
            }
        }
    },
    components: {
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        VBTooltip,
        BCollapse,
        BCard,
        BCardHeader,
        BCardBody,
        VBToggle,
        BBadge,
        FontAwesome,
        MeterRecord,
        BAvatar
    },
    props: {
        contractData: {
            type: Object,
        },
        meterid: {
            type: String,
        }
    },
    mounted() {
        // console.log("Id",this.meterid)
        // var self = this;
        // //TODO: Needs possibly moving to VueX
        // let url = "/api/meters/" + this.meterid;
        // axios.get(url)
        //     .then(function (response) {
        //         self.meter = response.data;
        //     }).catch(err => console.log("error:", err.message));
    },
    data() {
        return {
            meter: [],
            title: "MeterRecord",
            home: true,
            date: "25/11/2021"
        }
    }
}
</script>
