var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card-code",
        {
          attrs: { title: "Icon Only" },
          scopedSlots: _vm._u([
            {
              key: "code",
              fn: function () {
                return [
                  _vm._v(
                    "\n      " + _vm._s(_vm.codeButtonIconOnly) + "\n    "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-card-text", { staticClass: "mb-0" }, [
            _vm._v(
              "\n      You can only use .btn-icon when you only want icon in your button\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "demo-inline-spacing" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(255, 255, 255, 0.15)",
                      expression: "'rgba(255, 255, 255, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon",
                  attrs: { variant: "warning" },
                },
                [_c("feather-icon", { attrs: { icon: "ArchiveIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "btn-icon",
                  attrs: { variant: "gradient-danger" },
                },
                [_c("feather-icon", { attrs: { icon: "UserPlusIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(255, 255, 255, 0.15)",
                      expression: "'rgba(255, 255, 255, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon rounded-circle",
                  attrs: { variant: "warning" },
                },
                [_c("feather-icon", { attrs: { icon: "ArchiveIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "btn-icon rounded-circle",
                  attrs: { variant: "gradient-danger" },
                },
                [_c("feather-icon", { attrs: { icon: "UserPlusIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon",
                  attrs: { variant: "outline-primary" },
                },
                [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon",
                  attrs: { variant: "outline-primary", disabled: "" },
                },
                [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon rounded-circle",
                  attrs: { variant: "outline-primary" },
                },
                [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon rounded-circle",
                  attrs: { variant: "outline-primary", disabled: "" },
                },
                [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(40, 199, 111, 0.15)",
                      expression: "'rgba(40, 199, 111, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon rounded-circle",
                  attrs: { variant: "flat-success" },
                },
                [_c("feather-icon", { attrs: { icon: "CameraIcon" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(40, 199, 111, 0.15)",
                      expression: "'rgba(40, 199, 111, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  staticClass: "btn-icon",
                  attrs: { variant: "flat-success" },
                },
                [_c("feather-icon", { attrs: { icon: "CameraIcon" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }