<template>
    <div class = "CompanyDetails">

        <b-row class = "details-options">
            <b-col
                align-self = "center"
                cols = "3"
            >
                <b>Address</b>
            </b-col>
            <b-col
                align-self = "center"
                cols = "9"
            >
                <b-form-group>
                    <b-form-input
                        id = "address1"
                        v-model = "primarySiteDetails.address1"
                        :disabled = "readonly"
                        :readonly = "readonly"
                        :state = "validateState('address1')"
                        :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                        placeholder = "Address 1"
                    />
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id = "readOnlyInput"
                        v-model = "primarySiteDetails.address2"
                        :disabled = "readonly"
                        :readonly = "readonly"
                        :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                        placeholder = "Address 2"
                    />
                </b-form-group>
                <div class = "row">
                    <b-form-group class = "col-6">
                        <b-form-input
                            id = "city"
                            v-model = "primarySiteDetails.city"
                            :disabled = "readonly"
                            :readonly = "readonly"
                            :state = "validateState('city')"
                            :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                            placeholder = "City"
                        />
                    </b-form-group>
                    <b-form-group
                        class = "col-6"
                    >

                        <b-form-input
                            id = "postcode"
                            v-model = "primarySiteDetails.postcode"
                            :disabled = "readonly"
                            :readonly = "readonly"
                            :state = "validateState('postcode')"
                            :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                            placeholder = "Post Code"
                        />
                    </b-form-group>
                </div>
            </b-col>

        </b-row>

        <b-row class = "detail-options">
            <b-col
                align-self = "center"
                cols = "6"
            >
                <b-row class = "detail-options">
                    <b-col
                        align-self = "center"
                        cols = "6"
                    >
                        <b>Company Type:</b>
                    </b-col>
                    <b-col
                        align-self = "center"
                        cols = "6"
                    >
                        <b-form-group>
                            <b-form-select
                                id = "type"
                                v-model = "companyDetails.company_type"

                                :disabled = "readonly"
                                :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                                :options = "getTypes"
                                :state = "validateState('type')"

                                placeholder = "Select Type"
                                text-field = "Name"
                                value-field = "id"
                            >
                                <!--                show number of records avalible in sector -->

                                <template #first>
                                    <b-form-select-option
                                        :value = "null"
                                        disabled
                                    >-- Please select a Sector --
                                    </b-form-select-option>
                                </template>

                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col
                align-self = "center"
                cols = "6"
            >
                <b-row class = "detail-options">
                    <b-col
                        align-self = "center"
                        cols = "4"
                    >
                        <b>Reg No:</b>
                    </b-col>
                    <b-col
                        align-self = "center"
                        cols = "8"
                    >
                        <b-form-group>
                            <b-form-input
                                id = "readOnlyInput"
                                v-model = "companyDetails.reg"
                                :disabled = "readonly"
                                :readonly = "readonly"
                                :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row class = "detail-options form-group">
            <b-col
                align-self = "center"
                cols = "3"
            >
                <b>Sector:</b>
            </b-col>
            <b-col
                align-self = "center"
                cols = "9"
            >
                <b-form-select
                    id = "sector"
                    v-model = "companyDetails.sector.id"

                    :disabled = "readonly"
                    :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                    :options = "getSectors"
                    :state = "validateState('sector')"

                    placeholder = "Select Provider"
                    text-field = "sector"
                    value-field = "id"
                >
                    <!--                show number of records avalible in sector -->

                    <template #first>
                        <b-form-select-option
                            :value = "null"
                            disabled
                        >-- Please select a Sector --
                        </b-form-select-option>
                    </template>

                </b-form-select>
            </b-col>
        </b-row>

        <b-row class = "detail-options">
            <b-col
                align-self = "center"
                cols = "6"
            >
                <b-row class = "detail-options">
                    <b-col
                        align-self = "center"
                        cols = "6"
                    >
                        <b>SIC Code:</b>
                    </b-col>

                    <b-col
                        align-self = "center"
                        cols = "6"
                    >
                        <b-form-group>
                            <b-form-input
                                id = "readOnlyInput"
                                v-model = "companyDetails.sic"
                                :disabled = "readonly"
                                :readonly = "readonly"
                                :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                            />
                        </b-form-group>
                    </b-col>
                    <!--            TODO: Contemplate JSON Label for v-for -->
                    <!--                      Format for sic = sic code (number) - sic description (text)-->
                </b-row>
            </b-col>
            <b-col
                align-self = "center"
                cols = "6"
            >
                <b-row class = "detail-options">
                    <b-col
                        align-self = "center"
                        cols = "4"
                    >
                        <b>Headcount:</b>
                    </b-col>
                    <b-col
                        align-self = "center"
                        cols = "8"
                    >
                        <b-form-group>
                            <b-form-input
                                id = "readOnlyInput"
                                v-model = "companyDetails.headcount"
                                :disabled = "readonly"
                                :readonly = "readonly"
                                :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                                type = "number"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class = "detail-options">
            <b-col
                align-self = "center"
                cols = "3"
            >
                <b>Website:</b>
            </b-col>
            <b-col
                align-self = "center"
                cols = "9"
            >
                <b-form-group>
                    <b-form-input
                        id = "readOnlyInput"
                        v-model = "companyDetails.website"
                        :disabled = "readonly"
                        :readonly = "readonly"
                        :style = "[readonly ? {'opacity':4.5} : {'border-color': '#7367f0'}]"
                        type = "url"
                    />
                </b-form-group>

            </b-col>

        </b-row>
    </div>
</template>
<style lang = "scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "_CompanyDetails";
</style>

<script>

import Button from '@/views/components/button/Button'

import store from '@/store'
import vSelect from 'vue-select'

import {BButton, BCol, BFormGroup, BFormSelect, BFormSelectOption, BRow, VBTooltip,} from 'bootstrap-vue'
import {getUserData} from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {bus} from '@/main'

export default {
    name: 'CompanyDetails',
    components: {
        Button,

        // 'v-select': vSelect,

        BRow,
        BCol,
        BFormGroup,
        BButton,
        vSelect,
        BFormSelect,
        BFormSelectOption,
        ToastificationContent,
        VBTooltip,

    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,

    },
    data() {
        return {
            title: 'Company',
            readonly: true,
            CallButton: false,
            transitions: {
                showNumber: true,
                showOutcome: true,
            },
            ts: 0,
            company_type: null,

        }
    },
    computed: {
        getSectors() {
            return store.getters['dataManager/getSectors']
        },
        getTypes() {
            return store.getters['company/getCompanyTypes']
        },

        activeUserInfo() {
            return getUserData
        },
        companyLoading() {
            return store.getters['company/getStatus']
        },

        companyDetails: {
            get() {
                return store.getters['company/getCompany']
            },
            set(newDetails) {
                //store.state.company.companyDetails = newDetails;
                store.commit("company/SET_COMPANY_INFO", newDetails)
            },
        },
        primarySiteDetails: {
            get() {
                return store.getters['company/getPrimarySite']
            },
            set(newDetails) {
                //store.state.company.companyDetails = newDetails;
                store.commit("company/SET_SITE_INFO", newDetails)
            },
        },
        primaryContactDetails: {
            get() {
                return store.getters['company/getPrimaryContact']
            },
            set(newDetails) {
                store.commit("company/SET_CONTACT_INFO", newDetails)
            },
        },
        getWebsiteUrl() {
            return `//${this.companyDetails.website.replace(/(^\w+:|^)\/\//, '')}`
        },

    },

    mounted() {
        const self = this
        store.dispatch('dataManager/setSectors')
        store.dispatch('company/setCompanyTypes')
        bus.$emit('pageTitle:set', this.companyDetails.business_name)
        bus.$on('prospect:edit', edit => {
            self.readonly = !edit
            if (edit === false) {
                console.log("Saving The Details");
                console.log("======================");
               // console.log(self.companyDetails);
                // region Data Save
                // self.$v.$touch()
                // if (self.$v.$anyError) {
                //     self.edit = true;
                //     return false;
                // }
                store.dispatch("company/syncCompany")
                // self.store.dispatch("company/setCompany", self.companyDetails.id);
                // self.$http.put("/sites/" + self.primarySiteDetails.id, {
                //     site: {
                //         address1: self.primarySiteDetails.address1,
                //         address2: self.primarySiteDetails.address2,
                //         town: self.primarySiteDetails.town,
                //         city: self.primarySiteDetails.city,
                //         postcode: self.primarySiteDetails.postcode,
                //
                //
                //     }
                // })
                // //endregion
            }
        })
        // debugger;
    },
    // validations: {
    //     address1: {
    //         required
    //     },
    //     city: {
    //         required
    //     },
    //     postcode: {
    //         required
    //     },
    //     type: {
    //         required
    //     },
    //     sector: {
    //         required
    //     }
    // },
    methods: {
        validateState(name) {
            // if (this.$v[name]) {
            //     const {$dirty, $error} = this.$v[name];
            //     return $dirty ? !$error : null;
            // }
        },
        toggleEdit(event) {
            // debugger;
            this.readonly = !this.readonly
        },
    },

}

</script>
