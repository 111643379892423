var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-card", { staticClass: "text-center" }, [
    _c("div", { staticClass: "b-row" }, [
      _c("div", { staticClass: "b-col w-1/6" }, [
        _c("p", [
          _c("strong", [_vm._v("Current Supplier:")]),
          _vm._v(" " + _vm._s(_vm.contract.supplier.name)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-col w-1/6" }, [
        _c("p", [
          _c("strong", [_vm._v("Contract Start Date:")]),
          _vm._v(" " + _vm._s(_vm.contract.start_date ?? "Unknown")),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-col w-1/6" }, [
        _c("p", [
          _c("strong", [_vm._v("Contract End Date:")]),
          _vm._v(" " + _vm._s(_vm.contract.end_date)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-col w-1/6" }, [
        _c("p", [
          _c("strong", [_vm._v("Consumption:")]),
          _vm._v(
            " " + _vm._s(_vm.contract.consumption) + " \n                "
          ),
          _vm.contract.is_annual ? _c("span", [_vm._v("Annual")]) : _vm._e(),
          _vm._v(" kWh\n            "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-col w-1/6" }, [
        _c("p", [
          _c("strong", [_vm._v("Deal:")]),
          _vm._v(" "),
          _c(
            "span",
            [
              _c("b-badge", { attrs: { variant: "secondary" } }, [
                _vm._v("External"),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-col w-1/6" },
        [
          _c("b-button", { attrs: { variant: "primary" } }, [
            _c("span", { staticClass: "text-nowrap" }, [_vm._v("Rate Card")]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }