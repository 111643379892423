var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "FileManager" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { border: "", cols: "6" } },
            [
              _vm.canPermittedByRoleOrUser("import", "files")
                ? _c("FileUpload", { key: _vm.fileRefresh })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { border: "", cols: "6" } },
            [
              _c(
                "vue-perfect-scrollbar",
                {
                  ref: "refChatLogPS",
                  staticClass: "user-chats scroll-area",
                  attrs: { settings: _vm.perfectScrollbarSettings },
                },
                [_c("FileList", { key: _vm.fileRefresh })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }