import axios from 'axios'
import {bus} from "@/main";

export default {
    async setLOATemplates({commit, dispatch}) {
        commit('SET_LOADING', true);
        let url = "/api/docusign/templates/loa";
        return axios.get(url)

            .then(function (response) {
                let templates = response.data;
                commit('SET_TEMPLATES', templates);
                commit('SET_LOADING', false)
            }).catch(err => console.log("error:", err.message));
    },
    async setLOA({commit, dispatch}, id) {

        commit('SET_LOADING', true);
        let url = "/api/companies/" + id;
        return axios.get(url)
            .then(function (response) {
                let company = response.data;
                commit('SET_COMPANY_INFO', company);
                dispatch('setSites');
                dispatch('setNotes');
                dispatch('setLOAType');
                commit('SET_LOADING', false)
            }).catch(err => console.log("error:", err.message));
    },
    async setSites({commit, dispatch, state}) {

        let url = "/api/companies/" + state.companyDetails.id  + "/sites/";
        return axios.get(url)
            .then(function (response) {
                commit('SET_SITE_INFO', response.data);
            }).catch(err => console.log("error:", err.message));
    },
    async setNotes({commit, dispatch, state}) {

        let url = "/api/companies/" + state.companyDetails.id + "/notes/";
        return axios.get(url)
            .then(function (response) {
                commit('SET_NOTES', response.data);
            }).catch(err => console.log("error:", err.message));
    },
    async resetLOA({commit, state}) {
        commit('RESET_COMPANY');
    },
    async setLOAType({commit, state}) {
        let url = '/api/company_type/'+ state.companyDetails.company_type;
        return axios
            .get(url)
            .then(function (response) {
                commit('SET_COMPANY_TYPE',response.data.Name);
            }).catch(err => console.log("error:", err.message));

    },
}
