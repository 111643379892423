

import Vue from 'vue'

export default {

    SET_LOADING (state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },
    SET_USERS_DIR (state, users) {
        state.users = users
    },
}

