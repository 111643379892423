<template #default="{ hide }">
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <div v-if="apiError != null">
      <b-alert class="m-1 p-2" :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged">
        {{ apiError }}
      </b-alert>
    </div>

    <!-- Form -->
    <b-form class="p-2" @submit.prevent="handleSubmit(saveChanges)" @reset.prevent="resetForm">

      <!-- Full Name -->
      <validation-provider ref="nameValidator" #default="validationContext" name="supplier_id" rules="required">
        <b-form-group label="Supplier" label-for="supplier_id" :state="getValidationState(validationContext)">
          <template v-slot:label>
            Supplier <span class="text-danger"> *</span>
          </template>

          <v-select v-model="createData.supplier_id" input-id="supplier_id" :options="supplierOptions"
            :reduce="val => val.id" :clearable="false" searchable />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group label="Start date" label-for="start_date">
        <flat-pickr v-model="createData.start_date" class="form-control" :config="dateTimeConfig"
          placeholder="DD-MM-YYYY" />
      </b-form-group>

      <validation-provider ref="dateTimeValidator" #default="validationContext" name="End date" rules="required">
        <b-form-group label="End Date" label-for="end_date" :state="getValidationState(validationContext)">
          <template v-slot:label>
            End Date <span class="text-danger"> *</span>
          </template>
          <flat-pickr v-model="createData.end_date" class="form-control" :config="dateTimeConfig"
            placeholder="DD-MM-YYYY" />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <!-- <b-row class="pt-1">
        <b-col cols="9">
          <b-form-checkbox switch v-model="createData.is_annual" size="sm">
            Annual kWh
          </b-form-checkbox>
        </b-col>
      </b-row> -->
      <validation-provider #default="validationContext" name="consumption" rules="required">
        <b-form-group label-for="consumption" :state="getValidationState(validationContext)">
          <template v-slot:label>
            Consumption
          </template>
          <b-form-input v-model="createData.consumption" type="text" id="consumption"
            placeholder="Consumption in kWh" />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
          Add Contract
        </b-button>
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
          @click="closeModal">
          Cancel
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>


<style lang="scss">
@import './_addContract';
</style>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BFormInput, BFormInvalidFeedback, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import axios from 'axios';
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import popUpComponent from '@/views/components/modal/PopUp.vue'
import moment from 'moment-timezone';
import flatPickr from 'vue-flatpickr-component';

export default {
  name: "AddContract",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    vSelect,
    BDropdownItem,
    BFormInput,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    popUpComponent,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      responseData: {},
      supplierOptions: [{ id: '', value: 'Please select a supplier', label: 'Please select a supplier' }],
      createData: {
        supplier_id: '',
        consumption: '',
        is_annual: false,
        start_date: '',
        end_date: moment.tz(moment(), 'Europe/London').add(1, 'days').format("YYYY-MM-DD"),
      },
      apiError: "",
      apiSuccess: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      loader: false,
      showModal: false,
      rowItem: '',
      dateTimeConfig: {
        minDate: '',
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: false,
        static: true,
        position: 'above',
      },
    }
  },
  mounted() {
    this.createData.meter_id = this.meter_id;
    this.getSuppliers();

  },
  props: {
    meter_id: {
      type: Number,
      required: false,
    },
  },
  methods: {
    getSuppliers() {
      axios.get('/api/suppliers')
        .then((response) => {
          this.supplierOptions = [];
          this.supplierOptions = [{ id: '', value: 'Please select a supplier', label: 'Please select a supplier' }];
          response.data.forEach(item => {
            this.supplierOptions.push({
              id: item.id,
              value: item.name,
              label: item.name,
            });
          });

        })
        .catch((error) => {
          // Handle errors here
          console.error('Error fetching user data:', error);
        })
    },
    closeModal() {
      this.$emit('save-success');
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    resetForm() {
      this.createData = {
        supplier_id: 'Please select a supplier',
        consumption: '',
        is_annual: false,
        start_date: '',
        end_date: moment.tz(moment(), 'Europe/London').add(1, 'days').format("YYYY-MM-DD"),
      };
      this.$refs.refFormObserver.reset();
      this.$emit('save-success');
    },
    saveChanges() {
      axios
        .post('/api/contracts', this.createData)
        .then(response => {
          this.resetForm();
          this.responseData = response.data;
        })
        .catch(err => {
          this.apiError = "Something went wrong! Please contact support team";
          this.showAlert();
        });
    },
    formateResponses(result) {
      this.responseData = result;
    },
  },
  setup(props, { emit }) {
    const {

      getValidationState,

    } = formValidation()

    return {
      getValidationState,
    }
  },
}
</script>
