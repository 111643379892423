module.exports = {
    deleteEmptyKeys: function(data) {
      var query = {};
      for (var key in data) {
        (data[key] !== '') ? query[key] = data[key]: '';
      }
      return query;
    },
    getLoggedUserToken(){
      return localStorage.getItem('token');
    },
    getLoggedUserName(){
      const data = JSON.parse(localStorage.getItem('userData'));
      return data.name;
    },
    getLoggedUser(){
      return JSON.parse(localStorage.getItem('userData'));
    },
    modifyPermissions(permissions) {
      const modules = {};
      const actions = permissions.map(permission => permission.split(":")[1]);
      const uniqueActions = Array.from(new Set(actions));
      const actionsWithFalse = uniqueActions.reduce((acc, right) => {
          acc[right] = false;
          return acc;
      }, {});
      permissions.forEach(permission => {
          const [module, action] = permission.split(":");

          modules[module] = modules[module] || {
              module,
              ...actionsWithFalse,
          };

          modules[module][action] = true;
      });

      return Object.values(modules);
    },
    errorMessage(response) {
      const errorData = response.data;
      if (errorData && errorData.errors) {
        for (const field in errorData.errors) {
          if (errorData.errors.hasOwnProperty(field)) {
            const fieldErrors = errorData.errors[field];
            if (fieldErrors && fieldErrors.length > 0) {
              return fieldErrors[0];
            }
          }
        }
      } else if (errorData && errorData.message) {
        return errorData.message;
      }
      return "An error occurred.";
    },
    snakeCaseToHumanReadable(input) {
      return input.split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    },
    hasAnyPermission(...permissions) {
      return permissions.some(permission => this.$can('view', permission));
    },
    permissionsMap(direct_permissions,response){
      const permissionMap = {};
      let permissionsToSetTrue =direct_permissions.map(item => item.name);
      response.forEach(permission => {
        const nameParts = permission.name.split(':');
        const key = nameParts[0];
        if (!permissionMap[key]) {
          permissionMap[key] = [];
        }
        // Check if the permission name is in the list to set to true
        permission.isChecked = permissionsToSetTrue.includes(permission.name);
        permissionMap[key].push(permission);
      });
      return permissionMap;
    },
    errorResponseAlert(err){
      if (err.response.status === 400) {
        return err.response.data.message;
      } else if (err.response.status === 422) {
        const object = err.response.data.errors;
        return Object.values(object)[0][0];
      }else {
        return 'Something went wrong!. Please contact support team';
      }
    }
    
  };
  