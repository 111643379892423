export default [
    {
        path: '/',
        name: 'company-settings',
        // component: () => import('@/'),
        meta: {
            pageTitle: 'Company Settings',
            breadcrumb: [
                {
                    text: 'Extensions',
                },
                {
                    text: 'Company Settings',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/',
        name: 'settings',
        // component: () => import(''),
        meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
                {
                    text: 'Extensions',
                },
                {
                    text: 'System Settings',
                    active: true,
                },
            ],
        },
    },

]
