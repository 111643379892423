<template>
    <b-card class="text-center">
        <div class="b-row">
            <div class="b-col w-1/6">
                <p><strong>Current Supplier:</strong> {{contract.supplier.name}}</p>
            </div>
            <div class="b-col w-1/6">
                <p><strong>Contract Start Date:</strong> {{ contract.start_date ?? 'Unknown'}}</p>
            </div>
            <div class="b-col w-1/6">
                <p><strong>Contract End Date:</strong> {{ contract.end_date }}</p>
            </div>
            <div class="b-col w-1/6">
                <p><strong>Consumption:</strong> {{ contract.consumption }} 
                    <span v-if = "contract.is_annual">Annual</span> kWh
                </p>
            </div>
            <div class="b-col w-1/6">
                <p><strong>Deal:</strong>  
                    <span>
                        <b-badge variant="secondary">External</b-badge>
                    </span>
                    
                </p>
            </div>
            <div class="b-col w-1/6">
                <b-button
                        variant="primary"
                    >
                    <span class="text-nowrap">Rate Card</span>
                </b-button>
            </div>
            
        </div>
    </b-card>
</template>


<style lang="scss">
@import './_contractDetails';
</style>

<script>
import {BButton, BCard, BCol, BModal, BRow} from 'bootstrap-vue';

export default {
    name: "ContractDetails",
    components: {
        BRow,
        BCol,
        BCard,
        BModal,
        BButton
    },
    props: {
        contract:{
            start_date:'',
            end_date:'',
            is_annual:'',
            consumption:'',
            supplier:{
                name:'',
            }
        },
    },
    methods: {},
    watch: { 
        contract: function(newVal, oldVal) { // watch it
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }
    },
    data() {
        return {
        }
    },
    mounted(){
    }

}
</script>

