var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-code",
    {
      attrs: { title: "Router" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function () {
            return [_vm._v("\n    " + _vm._s(_vm.codeButtonRouter) + "\n  ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Refer to the ")]),
        _vm._v(" "),
        _c("code", [_vm._v("Router support")]),
        _vm._v(" "),
        _c("span", [_vm._v(" reference docs for the various supported ")]),
        _vm._v(" "),
        _c("code", [_vm._v("<router-link>")]),
        _vm._v(" "),
        _c("span", [_vm._v(" related props.")]),
      ]),
      _vm._v(" "),
      _c(
        "b-alert",
        { staticClass: "mb-0", attrs: { variant: "warning", show: "" } },
        [
          _c("div", { staticClass: "alert-body" }, [
            _c("span", [
              _c("strong", [_vm._v("Note:")]),
              _vm._v(
                " Onclick of the Link button will redirect you to another page."
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "primary", to: "/components/breadcrumb" },
            },
            [_vm._v("\n      String Literal\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: {
                variant: "warning",
                to: { path: "/components/breadcrumb" },
              },
            },
            [_vm._v("\n      Object Path\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: {
                variant: "success",
                to: { name: "components-breadcrumb" },
              },
            },
            [_vm._v("\n      Named Router\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: {
                variant: "dark",
                to: {
                  path: "/components/breadcrumb",
                  query: { plan: "private" },
                },
              },
            },
            [_vm._v("\n      With Query\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }