var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ProspectDetails" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c("ContactDetails"),
              _vm._v(" "),
              _c("div", {
                staticClass: "row border-bottom",
                staticStyle: {
                  padding: "10px",
                  "margin-right": "10px",
                  "margin-bottom": "30px",
                },
              }),
              _vm._v(" "),
              _c("b-row", [_c("CompanyDetails")], 1),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "b-row",
                { staticClass: "detail-options" },
                [_c("Notes", { key: _vm.ts })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "DetailButtons", attrs: { cols: "2" } },
            [
              _c(
                "b-row",
                { staticClass: "vx-row Call pb-1" },
                [_c("CallButton")],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row pb-1" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "col",
                      attrs: { target: "", variant: "primary" },
                      on: { click: _vm.triggerEdit },
                    },
                    [
                      !_vm.editing
                        ? [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fa", "edit"] },
                            }),
                            _vm._v(
                              "\n                        Edit Details\n                    "
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editing
                        ? [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fa", "save"] },
                            }),
                            _vm._v(
                              "\n                        Save Details\n                    "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row Assignees" },
                [_c("Collaborator")],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", "ProspectContacts")
                        },
                      },
                    },
                    [_vm._v("Contacts")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", "CallLog")
                        },
                      },
                    },
                    [_vm._v("Call\n                    Logs")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", "SMSLog")
                        },
                      },
                    },
                    [_vm._v("SMS\n                    Logs")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "DocGen",
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", "DocGen")
                        },
                      },
                    },
                    [_vm._v("LOA\n                    Manager")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", "SitesMeters")
                        },
                      },
                    },
                    [_vm._v("Sites &\n                    Meters")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", "FileManager")
                        },
                      },
                    },
                    [_vm._v("File\n                    Manager")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", "Log")
                        },
                      },
                    },
                    [_vm._v("Log")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "vx-row" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          _vm.showConvertModal = true
                        },
                      },
                    },
                    [_vm._v("Convert To\n                    Client")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "base-modal",
                {
                  attrs: {
                    id: "convert-confirmation-modal",
                    title: "Convert to Client",
                    "ok-title": "Confirm",
                    "cancel-title": "Cancel",
                  },
                  on: { ok: _vm.convertToClient },
                  model: {
                    value: _vm.showConvertModal,
                    callback: function ($$v) {
                      _vm.showConvertModal = $$v
                    },
                    expression: "showConvertModal",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "Are you sure you want to convert this prospect to a client?"
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }