export default [
    // =============================================================================
    // Sales-Engine /apps/sales-engine/
    // =============================================================================
    {
        name: 'client-list',
        path: '/apps/sales-engine/client',
        component: () => import('@/views/apps/sales-engine/client/client-list/client-list'),
        meta: {
            authRequired: true,
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Clients', active: true },
            ],
            pageTitle: 'Clients',
            rule: 'editor',
            no_scroll: true,
            action: "list",
            //resource: 'default'
            resource: 'clients'
        },
    },

    {
        name: 'client-record-page',
        path: '/apps/sales-engine/client/:clientid',
        component: () => import('@/views/apps/sales-engine/client/client-record-page/client-record-page.vue'),
        meta: {
            Navigation: 'ClientMenu',
            authRequired: true,
            breadcrumb: [
                { text: 'Clients' },
                { text: 'Loading...', active: true },
            ],
            pageTitle: ' ',
            rule: 'editor',
            no_scroll: true,
            action: 'view',
            resource: 'clients'
        },
    },
    {
        name: 'meter-record-page',
        path: '/apps/sales-engine/meter/:meterid',
        /*
                                                    TODO:
                                                        Put meter page in the right place
                                                        Maybe breadcrumb = Site Name > Meter Name/id?
                                                     */
        component: () => import('@/views/components/SitesMeters/Meters/MeterRecordPage/MeterRecordPage'),
        meta: {
            Navigation: 'Empty',
            authRequired: true,
            breadcrumb: [
                { text: 'Meters' },
                // {text: 'Loading...', active: true},
                { text: '' },
            ],
            pageTitle: ' ',
            rule: 'editor',
            no_scroll: true,
            resource: 'default'
        },
    },

    {
        name: 'prospect-list',
        path: '/apps/sales-engine/prospects/',
        component: () => import('@/views/apps/sales-engine/prospect/prospect.vue'),
        meta: {
            authRequired: true,
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Prospects', active: true },
            ],
            pageTitle: 'Prospects',
            rule: 'editor',
            no_scroll: true,
            Navigation: 'Default',
            action: "list",
            resource: "prospects"
        },
    },
 {
        name: 'leads-list',
        path: '/apps/sales-engine/leads/',
        component: () => import('@/views/apps/sales-engine/lead/leads.vue'),
        meta: {
            authRequired: true,
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Leads', active: true },
            ],
            pageTitle: 'Leads',
            rule: 'editor',
            no_scroll: true,
            action: "view_lead_menu",
            resource: "leads",
        },
    },
    {
        name: 'objection-list',
        path: '/apps/sales-engine/objections/objections.vue',
        component: () => import('@/views/apps/sales-engine/objections/objections.vue'),
        meta: {
            authRequired: true,
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'objections', active: true },
            ],
            pageTitle: 'Objections',
            rule: 'editor',
            no_scroll: true,
            action: "list",
            resource: "objections",
        },
    },
    {
        name: 'deals-list',
        path: '/apps/sales-engine/deal/deal.vue',
        component: () => import('@/views/apps/sales-engine/deal/deals.vue'),
        meta: {
            authRequired: true,
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'deals', active: true },
            ],
            pageTitle: 'Deals',
            rule: 'editor',
            no_scroll: true,
            action: "list",
            resource: "deals"
        },
    },


]
