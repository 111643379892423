<template>
    <div class="ProspectDetails">
        <b-row>
            <b-col cols="5">
                <ContactDetails />

                <!-- Separator line -->
                <div class="row border-bottom" style="padding: 10px; margin-right: 10px; margin-bottom: 30px;"></div>

                <b-row>
                    <CompanyDetails />
                </b-row>
            </b-col>

            <b-col cols="5">
                <b-row class="detail-options">
                    <Notes :key="ts" />
                </b-row>
            </b-col>

            <!-- Button Section -->
            <b-col class="DetailButtons" cols="2">
                <b-row class="vx-row Call pb-1">
                    <CallButton />
                </b-row>

                <b-row class="vx-row pb-1">
                    <b-button class="col" target variant="primary" @click="triggerEdit">
                        <template v-if="!editing">
                            <font-awesome-icon :icon="['fa', 'edit']" />
                            Edit Details
                        </template>
                        <template v-if="editing">
                            <font-awesome-icon :icon="['fa', 'save']" />
                            Save Details
                        </template>
                    </b-button>
                </b-row>

                <b-row class="vx-row Assignees">
                    <!-- avatar group -->
                    <Collaborator />
                </b-row>

                <b-row class="vx-row">
                    <b-button block variant="outline-secondary"
                        @click="$emit('change-tab', 'ProspectContacts')">Contacts</b-button>
                </b-row>

                <b-row class="vx-row">
                    <b-button block variant="outline-secondary" @click="$emit('change-tab', 'CallLog')">Call
                        Logs</b-button>
                </b-row>

                <b-row class="vx-row">
                    <b-button block variant="outline-secondary" @click="$emit('change-tab', 'SMSLog')">SMS
                        Logs</b-button>
                </b-row>

                <b-row class="vx-row">
                    <b-button block class="DocGen" variant="outline-secondary"
                        @click="$emit('change-tab', 'DocGen')">LOA
                        Manager</b-button>
                </b-row>

                <b-row class="vx-row">
                    <b-button block variant="outline-secondary" @click="$emit('change-tab', 'SitesMeters')">Sites &
                        Meters</b-button>
                </b-row>

                <b-row class="vx-row">
                    <b-button block variant="outline-secondary" @click="$emit('change-tab', 'FileManager')">File
                        Manager</b-button>
                </b-row>

                <b-row class="vx-row">
                    <b-button block variant="outline-secondary" @click="$emit('change-tab', 'Log')">Log</b-button>
                </b-row>

                <b-row class="vx-row">
                    <b-button block variant="outline-secondary" @click="showConvertModal = true">Convert To
                        Client</b-button>
                </b-row>

                <!-- Modal for confirmation -->
                <base-modal id="convert-confirmation-modal" v-model="showConvertModal" title="Convert to Client"
                    @ok="convertToClient" ok-title="Confirm" cancel-title="Cancel">
                    <p>Are you sure you want to convert this prospect to a client?</p>
                </base-modal>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "_ProspectDetails";
</style>

<script>
import axios from 'axios'; // Import axios here
import Button from '@/views/components/button/Button'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { BAvatar, BAvatarGroup, BButton, BCol, BFormGroup, BIcon, BRow, BTooltip, VBTooltip } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import Collaborator from '@/views/components/Collaborator/Collaborator'
import CompanyDetails from '@/views/components/CompanyDetails/CompanyDetails'
import ContactDetails from '@/views/components/ContactDetails/ContactDetails'
import CallButton from '@/views/components/SoftPhone/CallButton/CallButton.vue'
import { bus } from '@/main'
import DocGen from '@/views/components/DocGen/DocGen.vue'
import Notes from '../../Notes/Notes'
import BaseModal from "@/views/components/modal/BaseModal.vue";

export default {
    name: 'ProspectDetails',
    props: {

    },
    components: {
        BaseModal,
        CallButton,
        ContactDetails,
        CompanyDetails,
        Button,
        Notes,
        BIcon,
        DocGen,
        flatPickr,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        vSelect,
        BAvatar,
        BAvatarGroup,
        BTooltip,
        ToastificationContent,
        Collaborator,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },

    data() {
        return {
            showConvertModal: false,
            editing: false,
            title: 'Prospect',
            CallButton: false,
            transitions: {
                showNumber: true,
                showOutcome: true,
            },
            ts: 0,
            datetime: null,
            company_type: null,
            configdateTimePicker: {
                enableTime: true,
                dateFormat: 'd-m-Y H:i',
                minDate: new Date(),
                weekNumbers: false,
                static: true,
            },
            center: { lat: 10.0, lng: 10.0 },
            markers: [
                { position: { lat: 10.0, lng: 10.0 } },
                { position: { lat: 11.0, lng: 11.0 } },
            ],
            select1: [],
            outcomes: null,
            select2: [],
            notInterested: [
                { label: 'Too Many Calls', value: 1 },
                { label: 'Use Other Broker', value: 2 },
                { label: 'Just Agreed A Contract', value: 3 },
                { label: 'A new contract recently live', value: 4 },
                { label: 'Call Closer to the time', value: 5 },
                { label: 'Customer Requests Removal', value: 6 },
            ],
            avatars: [
                { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
                { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
                { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
                { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
                { avatar: "['fas', 'phone']", fullName: 'Add Collaborator' },
            ],
        }
    },
    computed: {
        activeUserInfo() {
            return getUserData()
        },
        prospectLoading() {
            return store.getters['company/getStatus']
        },
        primarySiteDetails() {
            return store.getters['company/getPrimarySite']
        },
        getWebsiteUrl() {
            return `//${this.prospectDetails.website.replace(/(^\w+:|^)\/\//, '')}`
        },
        companyDetails: {
            get() {
                return store.getters['company/getCompany']
            }
        },
    },
    methods: {
        triggerEdit() {
            this.editing = !this.editing
            bus.$emit('prospect:edit', this.editing)
        },
        async convertToClient() {
            try {
                const response = await axios.post(`/api/clients/convert/${this.companyDetails.id}`);
                this.$bvToast.toast('Prospect has been successfully converted to a client', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                this.$emit('clientConverted');
            } catch (error) {
                this.$bvToast.toast(error.message || 'Error converting prospect to client', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
}
</script>
