var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Notes col-12" }, [
    _c(
      "div",
      { staticClass: "NoteView" },
      [
        _vm.canPermittedByRoleOrUser("view", "notes")
          ? _c("ViewNotes", {
              key: _vm.timestamp,
              ref: "notes",
              attrs: { id: _vm.company_id, type: "companies" },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "NoteAdd" },
      [
        _vm.canPermittedByRoleOrUser("add", "notes")
          ? _c("AddNotes", {
              attrs: { company_id: _vm.company_id },
              on: { "added-note": _vm.NoteAdded },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }