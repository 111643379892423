<template>
    <div class = "FileManager">
        <b-row>
            <b-col
                border
                cols = "6"
            >

                <FileUpload  v-if="canPermittedByRoleOrUser('import', 'files')" :key = "fileRefresh" />
            </b-col>

            <!--            VIEW SAVED FILES-->
            <b-col
                border
                cols = "6"
            >
                <vue-perfect-scrollbar
                    ref = "refChatLogPS"
                    :settings = "perfectScrollbarSettings"
                    class = "user-chats scroll-area"
                >
                    <FileList :key = "fileRefresh" />
                </vue-perfect-scrollbar>
            </b-col>
        </b-row>

    </div>
</template>

<script>

// import Vue from "vue";
import {BAvatar, BCol, BModal, BRow,} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import featherIcon from '@core/components/feather-icon/FeatherIcon'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FileList from './FileList/FileList'
import FileUpload from './FileUpload/FileUpload'
import {bus} from "@/main";
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {
    name: 'FileManager',

    components: {
        VuePerfectScrollbar,
        FileUpload,
        FileList,
        BRow,
        BCol,
        BModal,
        BAvatar,
        vSelect,
        flatPickr,
        featherIcon,
    },
    data() {
        return {
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
                suppressScrollX: true
            },
            fileRefresh: 0
        }
    },
    computed: {},
    created() {
        var self = this;
        bus.$on("fileManager:fileUploaded", function () {
            console.log("Refreshing Files!");
            self.fileRefresh += 1;
        })
    },
    methods: {},
    setup() {
        const { canPermittedByRoleOrUser } = useAclUtils()

        return {
            // ACL
            canPermittedByRoleOrUser,
        }
    },

}
</script>

<style lang = "scss">

@import "./_FileManager";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
