var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Meters" },
    [
      _vm.meters.length
        ? _c(
            "b-card",
            { attrs: { slot: "no-body" }, slot: "no-body" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _vm._v("\n                Type\n            "),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "3" } }, [
                    _vm._v("\n                Identifier\n            "),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _vm._v("\n                CED\n            "),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _vm._v("\n                EAC (kWh)\n            "),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "3" } }, [
                    _vm._v("\n                Supplier\n            "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.meters, function (meter) {
                return _c("MeterRecord", {
                  key: meter.id,
                  staticClass: "meter-record",
                  attrs: { meterid: meter.id },
                })
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }