export default [
    {
        path: '/dashboard/analytics',
        name: 'dashboard-analytics',
        meta: {
            action: "view",
            resource: "dashboard"
        },
        component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            action: "view",
            resource: "dashboard"
        },
        component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    },
]
