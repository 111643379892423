<template>
    <div class = "viewContract">
        <b-row class = "match-height">
            <b-col md = "4" xl = "4">

                <b-card>
                    <h1>Contract Information</h1>
                    <contractDetails v-if="record" :contract="record"></contractDetails>
                </b-card>

            </b-col>

            <b-col md = "4" xl = "4">

                <b-card class = "card">
                    <h1>PDF VIEW</h1>
                    <p>NA</p>
                </b-card>

            </b-col>

            <b-col md = "4" xl = "4">
                <b-card class = "card">
                    <h1> Deal Information</h1>
                    <p><span><b-badge variant="secondary">External</b-badge></span></p>
                </b-card>
            </b-col>

        </b-row>
    </div>


</template>


<style lang = "scss">
@import './_viewContract';
</style>

<script>
import {BButton, BCard, BCol, BModal, BRow} from 'bootstrap-vue';
import contractDetails from "@/views/services/broker/contracts/contractDetails/contractDetails";
import {bus} from '@/main'

export default {
    name: "viewContract",
    components: {
        BRow,
        BCol,
        BCard,
        BModal,
        BButton,
        contractDetails
    },
    methods: {},
    watch: {
        "ViewContractModal.prepareData": function(newVal, oldVal) { // watch it
          console.log('value changed: ', newVal, ' | was: ', oldVal)
        }
    },
    data() {
        return {
            record:{
                start_date:'',
                end_date:'',
                consumption:'',
                supplier:{
                    name:'',
                }
            }
        }
    },
    created() {
        var self = this;
        bus.$on("ViewContractModal:prepareData", function (contractData) {
            self.record = contractData;
        })
    },
    // mounted() {
    //     var self = this;
    //     bus.$on("ViewContractModal:prepareData", function (contractData) {
    //         self.record = contractData;
    //     })
    // },

}
</script>

