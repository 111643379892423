var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddSiteMeter" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-button-group",
            { staticClass: "btn-block btn-group" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  attrs: {
                    pressed: _vm.transitions.showAddSite,
                    variant: "outline-primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange("Site")
                    },
                  },
                },
                [_vm._v("Add Site\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  attrs: {
                    pressed: _vm.transitions.showAddMeter,
                    variant: "outline-primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange("Meter")
                    },
                  },
                },
                [_vm._v("Add Meter\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "transition",
                {
                  staticClass: "AddSitesMeters",
                  attrs: { mode: "out-in", name: "slide-fade" },
                },
                [
                  _vm.transitions.showAddSite
                    ? _c("AddSite", { key: _vm.ts })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showAddMeter
                    ? _c("AddMeter", {
                        key: _vm.ts,
                        on: {
                          refresh: function ($event) {
                            return _vm.check()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }