export default {
    getStatus: state => state.loadingStatus,
    getCompany: state => state.companyDetails,
    getCollaborators: state => state.collaborators,
    getUsers: state => state.users,

    getLatestCallback(state) {
        const {callbacks} = state.companyDetails
        if (callbacks !== undefined && callbacks.length > 0) {
            callbacks.sort((a, b) => b.id - a.id)
            return callbacks[0].callback ?? ''
        }
        return "No Callback Set"
    },
    getOutcomeList(state) {
        return state.outcomes
    },
    getContacts: state => state.contactDetails,
    getFiles: state => state.fileDetails,
    getSite: state => state.siteDetails,

    getPrimarySite: state => {
        let primarySite = state.siteDetails.filter(site => site.primary > 0)[0]
        if (primarySite === undefined) {
            primarySite = state.siteDetails[0]
        }
        return primarySite
    },
    getPrimaryContact: state => {
        const primaryContact = state.contactDetails.filter(contact => contact.primary > 0)[0]
        // if (contactId) {
        //   return state.contactDetails.filter(contact => contact.id === contactId)[0]
        // }
        if (primaryContact) {
            return primaryContact
        }
        return state.contactDetails[0] ?? []
    },
    getPrimaryContactNumber: state => {
        let primaryContact = state.contactDetails.filter(contact => contact.primary > 0)[0]

        // if (contactId) {
        //   return state.contactDetails.filter(contact => contact.id === contactId)[0].contactDetails[0]
        // }
        if (primaryContact === undefined) {
            if (state.contactDetails[0]) {
                primaryContact = state.contactDetails[0]
            }
        }
        if (primaryContact) {
            const number = primaryContact.numbers.filter(number => number.primary > 0)
            if (number.length === 0) {
                return 'NO CONTACT NUMBER FOUND'
            }
            return number[0].number
        }
        return []
    },
    getSites: state => state.siteDetails,
    getMeters: state => state.meters,
    getNotes: state => state.notes,
    getCompanyTypes: state => state.company_types,
    getErrors: state => state.errors,
    getLatestMeter: state => state.latest_meter,
    getDashboardNotices: state => state.notices,
    getCompanyNotices: state => state.company_notices,
}
