var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showModal,
          expression: "showModal",
        },
      ],
    },
    [
      _c(
        "base-modal",
        {
          attrs: {
            id: "confirmation_modal",
            title: _vm.infoModal.title,
            centered: "",
            size: "sm",
          },
          on: { hide: _vm.resetInfoModal },
        },
        [
          _c("p", {
            staticClass: "float-left",
            domProps: { innerHTML: _vm._s(_vm.infoModal.message) },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-3 float-right",
                  on: {
                    click: function ($event) {
                      return _vm.cancel("confirmation_modal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mt-3 float-right",
                  staticStyle: { "margin-right": "10px" },
                  attrs: { variant: "primary" },
                  on: { click: _vm.deleteItem },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }