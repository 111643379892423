<template>
  <div class="AddSiteMeter">
    <b-row>
      <b-button-group
        class="btn-block btn-group"
      >

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :pressed="transitions.showAddSite"
          variant="outline-primary"

          @click="tabChange('Site')"
        >Add Site
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :pressed="transitions.showAddMeter"
          variant="outline-primary"

          @click="tabChange('Meter')"
        >Add Meter
        </b-button>
      </b-button-group>
    </b-row>
    <b-row>
      <b-col>

        <transition
          class="AddSitesMeters"
          mode="out-in"
          name="slide-fade"
        >
          <AddSite
            v-if="transitions.showAddSite"
            :key="ts"
          />
          <AddMeter
            v-if="transitions.showAddMeter"
            :key="ts"
            @refresh="check()"
          />
        </transition>
      </b-col>
    </b-row>
  </div>
</template>
<style lang = "scss">
@import 'AddSiteMeter';

</style>

<script>

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BCollapse,
  BFormGroup,
  BRow,
  ButtonGroupPlugin,
  VBTooltip,
} from 'bootstrap-vue'
import AddSite from '@/views/components/SitesMeters/AddSiteMeter/AddSite/AddSite.vue'
import AddMeter from '@/views/components/SitesMeters/AddSiteMeter/AddMeter/AddMeter.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'AddSiteMeter',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    VBTooltip,
    BCollapse,
    BCardHeader,
    BCardBody,
    AddSite,
    AddMeter,
    ButtonGroupPlugin,

  },
  props: [],

  data() {
    return {
      ts: 0,
      transitions: {
        showAddSite: true,
        showAddMeter: false,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        suppressScrollX: true,
      },

      addSiteMeter:
                {},

    }
  },
  methods: {
        check()
        {
          this.$emit('refresh');
        },
    tabChange(tab) {
      self.ts += 1
      if (tab == 'Site') {
        this.transitions.showAddSite = true
        this.transitions.showAddMeter = false
      } else {
        this.transitions.showAddSite = false
        this.transitions.showAddMeter = true
      }
    },
  },
}
</script>
