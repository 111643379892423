var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CompanyDetails" },
    [
      _c(
        "b-row",
        { staticClass: "details-options" },
        [
          _c("b-col", { attrs: { "align-self": "center", cols: "3" } }, [
            _c("b", [_vm._v("Address")]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "9" } },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    style: [
                      _vm.readonly
                        ? { opacity: 4.5 }
                        : { "border-color": "#7367f0" },
                    ],
                    attrs: {
                      id: "address1",
                      disabled: _vm.readonly,
                      readonly: _vm.readonly,
                      state: _vm.validateState("address1"),
                      placeholder: "Address 1",
                    },
                    model: {
                      value: _vm.primarySiteDetails.address1,
                      callback: function ($$v) {
                        _vm.$set(_vm.primarySiteDetails, "address1", $$v)
                      },
                      expression: "primarySiteDetails.address1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    style: [
                      _vm.readonly
                        ? { opacity: 4.5 }
                        : { "border-color": "#7367f0" },
                    ],
                    attrs: {
                      id: "readOnlyInput",
                      disabled: _vm.readonly,
                      readonly: _vm.readonly,
                      placeholder: "Address 2",
                    },
                    model: {
                      value: _vm.primarySiteDetails.address2,
                      callback: function ($$v) {
                        _vm.$set(_vm.primarySiteDetails, "address2", $$v)
                      },
                      expression: "primarySiteDetails.address2",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-6" },
                    [
                      _c("b-form-input", {
                        style: [
                          _vm.readonly
                            ? { opacity: 4.5 }
                            : { "border-color": "#7367f0" },
                        ],
                        attrs: {
                          id: "city",
                          disabled: _vm.readonly,
                          readonly: _vm.readonly,
                          state: _vm.validateState("city"),
                          placeholder: "City",
                        },
                        model: {
                          value: _vm.primarySiteDetails.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.primarySiteDetails, "city", $$v)
                          },
                          expression: "primarySiteDetails.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { staticClass: "col-6" },
                    [
                      _c("b-form-input", {
                        style: [
                          _vm.readonly
                            ? { opacity: 4.5 }
                            : { "border-color": "#7367f0" },
                        ],
                        attrs: {
                          id: "postcode",
                          disabled: _vm.readonly,
                          readonly: _vm.readonly,
                          state: _vm.validateState("postcode"),
                          placeholder: "Post Code",
                        },
                        model: {
                          value: _vm.primarySiteDetails.postcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.primarySiteDetails, "postcode", $$v)
                          },
                          expression: "primarySiteDetails.postcode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "detail-options" },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "6" } },
            [
              _c(
                "b-row",
                { staticClass: "detail-options" },
                [
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "6" } },
                    [_c("b", [_vm._v("Company Type:")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("b-form-select", {
                            style: [
                              _vm.readonly
                                ? { opacity: 4.5 }
                                : { "border-color": "#7367f0" },
                            ],
                            attrs: {
                              id: "type",
                              disabled: _vm.readonly,
                              options: _vm.getTypes,
                              state: _vm.validateState("type"),
                              placeholder: "Select Type",
                              "text-field": "Name",
                              "value-field": "id",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [
                                        _vm._v(
                                          "-- Please select a Sector --\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.companyDetails.company_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyDetails,
                                  "company_type",
                                  $$v
                                )
                              },
                              expression: "companyDetails.company_type",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "6" } },
            [
              _c(
                "b-row",
                { staticClass: "detail-options" },
                [
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "4" } },
                    [_c("b", [_vm._v("Reg No:")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "8" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("b-form-input", {
                            style: [
                              _vm.readonly
                                ? { opacity: 4.5 }
                                : { "border-color": "#7367f0" },
                            ],
                            attrs: {
                              id: "readOnlyInput",
                              disabled: _vm.readonly,
                              readonly: _vm.readonly,
                            },
                            model: {
                              value: _vm.companyDetails.reg,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyDetails, "reg", $$v)
                              },
                              expression: "companyDetails.reg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "detail-options form-group" },
        [
          _c("b-col", { attrs: { "align-self": "center", cols: "3" } }, [
            _c("b", [_vm._v("Sector:")]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "9" } },
            [
              _c("b-form-select", {
                style: [
                  _vm.readonly
                    ? { opacity: 4.5 }
                    : { "border-color": "#7367f0" },
                ],
                attrs: {
                  id: "sector",
                  disabled: _vm.readonly,
                  options: _vm.getSectors,
                  state: _vm.validateState("sector"),
                  placeholder: "Select Provider",
                  "text-field": "sector",
                  "value-field": "id",
                },
                scopedSlots: _vm._u([
                  {
                    key: "first",
                    fn: function () {
                      return [
                        _c(
                          "b-form-select-option",
                          { attrs: { value: null, disabled: "" } },
                          [
                            _vm._v(
                              "-- Please select a Sector --\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.companyDetails.sector.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.companyDetails.sector, "id", $$v)
                  },
                  expression: "companyDetails.sector.id",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "detail-options" },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "6" } },
            [
              _c(
                "b-row",
                { staticClass: "detail-options" },
                [
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "6" } },
                    [_c("b", [_vm._v("SIC Code:")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("b-form-input", {
                            style: [
                              _vm.readonly
                                ? { opacity: 4.5 }
                                : { "border-color": "#7367f0" },
                            ],
                            attrs: {
                              id: "readOnlyInput",
                              disabled: _vm.readonly,
                              readonly: _vm.readonly,
                            },
                            model: {
                              value: _vm.companyDetails.sic,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyDetails, "sic", $$v)
                              },
                              expression: "companyDetails.sic",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "6" } },
            [
              _c(
                "b-row",
                { staticClass: "detail-options" },
                [
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "4" } },
                    [_c("b", [_vm._v("Headcount:")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "8" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("b-form-input", {
                            style: [
                              _vm.readonly
                                ? { opacity: 4.5 }
                                : { "border-color": "#7367f0" },
                            ],
                            attrs: {
                              id: "readOnlyInput",
                              disabled: _vm.readonly,
                              readonly: _vm.readonly,
                              type: "number",
                            },
                            model: {
                              value: _vm.companyDetails.headcount,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyDetails, "headcount", $$v)
                              },
                              expression: "companyDetails.headcount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "detail-options" },
        [
          _c("b-col", { attrs: { "align-self": "center", cols: "3" } }, [
            _c("b", [_vm._v("Website:")]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "9" } },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    style: [
                      _vm.readonly
                        ? { opacity: 4.5 }
                        : { "border-color": "#7367f0" },
                    ],
                    attrs: {
                      id: "readOnlyInput",
                      disabled: _vm.readonly,
                      readonly: _vm.readonly,
                      type: "url",
                    },
                    model: {
                      value: _vm.companyDetails.website,
                      callback: function ($$v) {
                        _vm.$set(_vm.companyDetails, "website", $$v)
                      },
                      expression: "companyDetails.website",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }