<!--TODOL Need to add this to run through pusher
Reasons:
1. send notification to other collaborators (and manager ((not shown as a collaborator)) ) that user X was added as a collaborator on Business 123
2. the collaborators on the vue file only update on page refresh, this could be set to listen to canges
-->

<template>
    <div class = "Collaborators">
        <template v-if = "!removeCollabSwitch">
            <b-row cols = "12">
                <h6 class = "align-self-center cursor-pointer ml-1 mb-0">
                    Collaborators:
                </h6>
            </b-row>
            <b-row>
                <b-col xs = "12">
                    <b-avatar-group
                        class = "mt-2"
                        size = "lg"
                    >
                       <b-avatar
                             v-for="avatar in sortedCollaborators"
                            :key="avatar.id"
                            v-b-tooltip.hover.bottom="avatar.name"
                            :text="avatar.initials"
                            badge
                            badge-offset="-3px"
                            badge-top
                            :class="['pull-up', { 'custom-badge-class': avatar.pivot.is_primary }]"
                        >
                            <template #badge v-if="!avatar.pivot.is_primary">
                                <b-icon
                                    v-if="canPermittedByRoleOrUser('delete','collaborator')"
                                    variant="danger"
                                    icon="x-circle-fill"
                                    font-scale="1.2"
                                    @click="showRemoveCollaborator(avatar)">
                                </b-icon>
                                <b-icon
                                    v-if="canPermittedByRoleOrUser('reassign','datamanager')"
                                    font-scale="1.2"
                                    icon="check-circle-fill"
                                    variant="warning"
                                    @click="showInfoModal(avatar)"
                                ></b-icon>
                             </template>
                        </b-avatar>
                        <b-avatar v-b-tooltip.hover.bottom = "addCollab.fullName"
                            :icon = "addCollab.icon"
                            button
                            class = "pull-up addColButton"
                            v-b-modal.add_collaborator
                        />
                    </b-avatar-group>
                </b-col>
            </b-row>
            <base-modal
                id="add_collaborator"
                hide-footer
                centered
                size="sm"
                title="Add Collaborator"
            >
                <addCollaborator></addCollaborator>
            </base-modal>
        </template>
        <template v-if = "removeCollabSwitch">
            <b-row v-if = "removeCollabSwitch">
                <b-col sm = "12">
                    Are you sure you want to remove <b
                    class = "text-primary bold font-medium-2">{{ removeCollabPerson.name }}</b> as a collaborator?
                </b-col>
                <b-col sm = "12">
                    <b-row>
                        <b-col sm = "6">
                            <b-button class = "removeNo" @click = "removeCollabSwitch=false">No</b-button>
                        </b-col>
                        <b-col class = "text-right" sm = "6">
                            <b-button variant = "danger" @click = "permRemove">Yes</b-button>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>

        </template>
        <base-modal
            id="make_primary_collaborator"
            :title="infoModal.title"
            centered
            size="sm"
            @hide="resetInfoModal"
            >
            <p v-html="infoModal.message" class="float-left"></p>
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="cancel('make_primary_collaborator')">Cancel</b-button>
                <b-button
                    v-if ="!infoModal.is_primary"
                    class="mt-3 float-right"
                    variant="primary"
                    v-on:click="makePrimaryCollabarator(infoModal.content)"
                    style="margin-right: 10px;"
                >Yes</b-button>
            </div>
        </base-modal>
        <base-modal
            id="remove_collaborator"
            :title="infoModal.title"
            centered
            size="sm"
            @hide="resetInfoModal"
            >
            <p v-html="infoModal.message" class="float-left"></p>
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="cancel('remove_collaborator')">Cancel</b-button>

                <b-button
                v-if ="!infoModal.is_primary"
                class="mt-3 float-right"
                variant="danger"
                v-on:click="removetheCollaborator(infoModal.content)"
                style="margin-right: 10px;"
                >Yes</b-button>
            </div>
        </base-modal>
    </div>
</template>


<script>
import Button from "@/views/components/button/Button";
import {bus} from "@/main";
import store from "@/store"
import {BAvatar, BAvatarGroup, BButton, BCol, BIcon, BIconPlus, BIconX, BRow, BTooltip, VBTooltip} from 'bootstrap-vue'
import addCollaborator from "@/views/components/Collaborator/addCollaborator/addCollaborator";
import {getUserData} from "@/auth/utils";
import PaginationColor from "@/views/components/pagination/PaginationColor";
import axios from 'axios';
import { useUtils as useAclUtils } from '@core/libs/acl'
import BaseModal from "@/views/components/modal/BaseModal.vue";

export default {
    components: {
        BaseModal,
        PaginationColor,
        Button,
        BRow,
        BCol,
        BButton,
        BAvatar,
        BAvatarGroup,
        BTooltip,
        BIcon,
        BIconPlus,
        addCollaborator,
        BIconX,

    },
    directives: {
        'b-tooltip': VBTooltip
    },
    computed: {
        activeUserInfo() {
            return getUserData()
        },
        sortedCollaborators() {
            return this.getCollaborators().filter((collaborator) => collaborator.hasOwnProperty('pivot')).sort((a, b) => {
                if (a.pivot.is_primary > b.pivot.is_primary) {
                    return -1; // a comes first
                } else if (a.pivot.is_primary < b.pivot.is_primary) {
                    return 1; // b comes first
                } else {
                    return 0; // no change in order
                }
            });
        }
    },
    name: "Collaborator",
    created() {
        var self = this;
        bus.$on("collaborator:add", function (collab) {
            self.addCollabSwitch = false
        })
    },
    methods: {
        getCollaborators() {
            return store.getters["company/getCollaborators"];
        },
        makePrimaryCollabarator(avatar) {
            let createData = {
                uid: avatar.pivot.user_id,
                make_primary_collabarator:true,
                user:this.getLoggedUser().sub,
                logged_user:this.getLoggedUserName()
            };
            const url = `/api/companies/${avatar.pivot.company_id}/collaborators/`
            axios
                .post(url, createData)
                .then((response) => {
                    // console.log(response.data);
                    store.commit('company/SET_COLLABORATOR_INFO', response.data);
                    this.cancel('make_primary_collaborator');
                    this.$bvModal.hide('make_primary_collaborator');
                })
        },
        removetheCollaborator(avatar){
            var query = {
                params: {
                    uid: avatar.pivot.user_id,
                    user:this.getLoggedUser().sub,
                    logged_user:this.getLoggedUserName()
                },
            };
            const url = `/api/companies/${avatar.pivot.company_id}/collaborators/destroy`;
            axios
            .delete(url, query)
            .then((response) => {
                store.commit('company/SET_COLLABORATOR_INFO', response.data);
                this.cancel('remove_collaborator');
                this.$bvModal.hide('remove_colloborator');
            })
        },
        companyDetails() {
            /*
              TODO: Connect collaborator up to API
              Eg Link: https://dev21.enduapp/api/companies/1/collaborators
             */

            return store.getters["company/getCompany"];
        },
        showInfoModal(avatar) {
            this.infoModal.content = avatar;
            this.infoModal.title='Modify Primary Collaborator';
            this.infoModal.message = "Are you sure you want to change the primary collaborator to " + avatar.name;
            this.$bvModal.show('make_primary_collaborator');
        },
        showRemoveCollaborator(avatar) {
            this.infoModal.content = avatar;
            this.infoModal.title="Confirmation to Remove Collaborator?"
            this.infoModal.message = "Are you sure you want to remove the collaborator  " + avatar.name +'"?';
            this.$bvModal.show('remove_collaborator');
        },
        resetInfoModal() {
            this.infoModal.id="Remove Collaborator";
            this.infoModal.content = null;
            this.infoModal.is_primary=false;
        },
        cancel(model_name) {
            this.$bvModal.hide(model_name);
            this.resetInfoModal();
        },
        addCollaborator() {
            this.addCollabSwitch = !this.addCollabSwitch;
        },
        removeCollaborator(collab) {
            this.removeCollabSwitch = !this.removeCollabSwitch;
            this.removeCollabPerson = collab;

        },

        permRemove() {
            console.clear();
            console.log(this.removeCollabPerson);
            store.dispatch("company/removeCollaborator", {
                collab: this.removeCollabPerson,
                user: this.activeUserInfo.sub
            });
            this.removeCollabSwitch = false;
            // TODO: Toast?
        }
    },
    filters: {
        pretty: function (value) {
            return JSON.stringify(JSON.parse(value), null, 2);
        }
    },
    data() {
        return {
            removeCollabPerson: [],
            addCollab:
                {icon: "plus", avatar: null, fullName: 'Add Collaborator'},
            addCollabSwitch: false,
            removeCollabSwitch: false,
            infoModal: {
                id: '', // Provide a unique ID for the modal
                title: '',
                content:{},
                message:"",
                is_primary:false,
            },
        }
    },
    setup() {
        const { canPermittedByRoleOrUser } = useAclUtils()

        return {
            // ACL
            canPermittedByRoleOrUser,
        }
    }
}
</script>

<style lang = "scss">
@import "./_Collaborator";
.custom-badge-class{
    border: 2px solid purple !important;
}
.badge-primary{
     background-color: transparent !important;
}
</style>
