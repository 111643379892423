<template>
  <b-container class="MPAN border-primary">
    <b-col>

      <mpanTop :editVal="editVal"/>
      <mpanBottom :editVal="editVal"/>
    </b-col>

  </b-container>
</template>

<script>
import mpanTop from '@/views/services/broker/electric/identifier/mpanTop/mpanTop'
import mpanBottom from '@/views/services/broker/electric/identifier/mpanBottom/mpanBottom'
import { BCol, BContainer, BRow } from 'bootstrap-vue'

export default {
  name: 'MPAN',
  components: {
    mpanTop,
    mpanBottom,
    BRow,
    BCol,
    BContainer,
  },

  data() {
    return {
      editing: false,
    }
  },
  props:{
    editVal:{
      type: Boolean
    }
  },
  mounted() {
    this.editing = this.editVal;
  }
}

</script>

<style lang = "scss">

@import "_MPAN.scss";
</style>
