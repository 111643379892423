var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-code",
    {
      attrs: { title: "Round buttons" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function () {
            return [_vm._v("\n    " + _vm._s(_vm.codeButtonRounded) + "\n  ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use a prop")]),
        _vm._v(" "),
        _c("code", [_vm._v("pill")]),
        _vm._v(" "),
        _c("span", [_vm._v("to create round button.")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "outline-primary", pill: "" },
            },
            [_vm._v("\n      Primary\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(186, 191, 199, 0.15)",
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "outline-secondary", pill: "" },
            },
            [_vm._v("\n      Secondary\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(40, 199, 111, 0.15)",
                  expression: "'rgba(40, 199, 111, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "outline-success", pill: "" },
            },
            [_vm._v("\n      Success\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(234, 84, 85, 0.15)",
                  expression: "'rgba(234, 84, 85, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "outline-danger", pill: "" },
            },
            [_vm._v("\n      Danger\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 159, 67, 0.15)",
                  expression: "'rgba(255, 159, 67, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "outline-warning", pill: "" },
            },
            [_vm._v("\n      Warning\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(0, 207, 232, 0.15)",
                  expression: "'rgba(0, 207, 232, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "outline-info", pill: "" },
            },
            [_vm._v("\n      Info\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(30, 30, 30, 0.15)",
                  expression: "'rgba(30, 30, 30, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: { variant: "outline-dark", pill: "" },
            },
            [_vm._v("\n      Dark\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }