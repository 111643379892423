import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'
import settings from '@/router/routes/settings'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import salesEngine from './routes/sales-engine'
import dataManager from './routes/data-manager'
import financeSuite from './routes/finance-suite'
import adminCentre from './routes/admin-centre'
import complaince from './routes/complaince'
import { canPermit } from '@core/libs/acl/utils'
import axios from 'axios' // Make sure to import axios or your preferred HTTP client


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/', redirect: { name: 'dashboard' } },
        ...apps,
        ...dashboard,
        ...pages,
        ...chartsMaps,
        ...formsTable,

        ...uiElements,
        ...salesEngine,
        ...dataManager,
        ...financeSuite,
        ...adminCentre,
        ...complaince,
        ...settings,
        ...others,
        {
            path: '/call-board-no-auth', // URL path for the component
            name: 'CallBoardNoAuth', // A unique name for the route
            component: () => import('@/views/components/CallBoard/CallBoardNoAuth.vue'), // Lazy-loaded component
            meta: {
                Navigation: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach(async (to, _, next) => {
    console.log("beforeEach guard is called for route:", to.path);
    const getNav = to.matched.some(route => route.meta.Navigation)
    const isLoggedIn = isUserLoggedIn()
    if (!getNav) {
        to.meta.Navigation = 'Default'
    }

    //bus.$emit('Menu:Change', to.meta.Navigation)
    // if (!canNavigate(to)) {
    //     console.log("navigate");
    //     // Redirect to login if not logged in
    //     if (!isLoggedIn) return next({ name: 'auth-login' })

    //     // If logged in => not authorized
    //     return next({ name: 'misc-not-authorized' })
    // }

    try {


        const userData = JSON.parse(localStorage.getItem('userDetails'));
        if (userData) {
            const userId = userData.id;

            // Pass the userId in the request body
            const response = await axios.post('/api/tagvalue',
                { user_id: userId }, // Request body with user ID

            );
            const apiData = response.data; // Extract API data


            // Store the API data into localStorage
            localStorage.setItem('tagValue', JSON.stringify(apiData));
            console.log('API Data:', apiData);
        }

    }
    catch (error) {
        if (error.response) {
            console.error('Error fetching API data:', {
                message: error.message,
                status: error.response.status,
                data: error.response.data
            });
        } else {
            console.error('Error fetching API data:', error.message);
        }

        // Handle error, e.g., redirect to an error page or next to a default route
        return next({ name: 'error-page' }); // Replace with your error handling route

    }


    if (!canPermit("admin", "superuser")) {
        // If not a superuser
        if (!canPermit(to.meta.action, to.meta.resource)) {
            // If no permission => not authorized
            return next({ name: 'misc-not-authorized' })
        }
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    if (to.path === '/telescope' || to.path.startsWith('/telescope/')) {
    // Let Laravel handle this route
window.location.href = to.fullPath;
    return
  } 
    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
