var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Identifier" },
    [
      Object.keys(_vm.meter).includes("mpan")
        ? _c("MPAN", { attrs: { editVal: _vm.editing } })
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.meter).includes("mprn")
        ? _c("MPRN", { attrs: { editVal: _vm.editing } })
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.meter).includes("spid")
        ? _c("SPID", { attrs: { editVal: _vm.editing } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }