import Vue from 'vue'
import {BootstrapVue, BootstrapVueIcons, ModalPlugin, ToastPlugin,} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import {Auth0Plugin} from './auth/auth0/auth0-plugin';

import i18n from '@/libs/i18n'
// Global Components
import './global-components'
// Font Awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
// 3rd party plugins
import moment from 'moment'
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
// Axios Mock Adapter
import '@/@fake-db/db'
import Vuelidate from 'vuelidate'
import Echo from 'laravel-echo'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from "axios"
import methods from './mixins.js';
import AudioPlayer from '@/views/components/CallDetails/AudioPlayer.vue'

Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)
Vue.use(Auth0Plugin, {
    domain: process.env.MIX_AUTH0_DOMAIN,
    clientId: process.env.MIX_AUTH0_CLIENT_ID,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname,
        )
    },
})
Vue.use(VueFileAgent)
Vue.use(VueFileAgentStyles)
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// BSV Plugin Registration
Vue.use(axios);
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

Vue.prototype.moment = moment
// Composition API
Vue.use(VueCompositionAPI)
Vue.mixin({ methods });

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@/views/components/CallDetails/freqtimeupdate.js')

Vue.component('audio-player', AudioPlayer);
Vue.config.productionTip = false
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true,
})

export const bus = new Vue()
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
