<template>

    <div id="app" :class="[skinClasses]" class="h-100">
        <component :is="layout">
            <router-view />
        </component>

        <AutoLogout v-if="isUserLoggedIn" />

        <div class="modal-container">
            <!--            <component v-for = "modal in getModals" :is = "modal"></component>-->
            <ProspectModal ref="details" />

        </div>
        <scroll-to-top v-if="enableScrollToTop" />
    </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import useJwt from '@/auth/jwt/useJwt'

import { getUserData, isUserLoggedIn } from '@/auth/utils'
import { useCssVar, useWindowSize } from '@vueuse/core'

import store from '@/store'
import AutoLogout from '@/views/pages/authentication/AutoLogout'
import ProspectModal from "@/views/components/ProspectModal/ProspectModal.vue";
import axios from "axios";
// import AutoLogout from "@/views/pages/authentication/AutoLogout";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
    components: {
        ProspectModal,
        AutoLogout,
        // AutoLogout,
        isUserLoggedIn,
        // Layouts
        LayoutHorizontal,
        LayoutVertical,
        LayoutFull,

        ScrollToTop,
    },

    // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
    // Currently, router.currentRoute is not reactive and doesn't trigger any change
    computed: {
        layout() {
            if (this.$route.meta.layout === 'full') return 'layout-full'
            return `layout-${this.contentLayoutType}`
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
        getModals() {
            return this.$route.meta.modals || [];
        },
        isUserLoggedIn() {
            return isUserLoggedIn();
        }
    },
    beforeCreate() {
        // Set colors in theme
        const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
        }

        // Set Theme Breakpoints
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
        }

        // Set RTL
        const { isRTL } = $themeConfig.layout
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    },
    created() {
        if (isUserLoggedIn()) {
            console.log("Setting Auth Headers");

            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            // TODO: FIND A MORE SECURE WAY FOR THIS
            this.$http.defaults.headers.common['Auth'] = btoa(getUserData().sub);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            // TODO: FIND A MORE SECURE WAY FOR THIS
            axios.defaults.headers.common['Auth'] = btoa(getUserData().sub);
        }
        // console.log("Loading Modals.....");
        //  console.log(this.getModals);
    },
    setup() {
        const { skin, skinClasses } = useAppConfig()
        const { enableScrollToTop } = $themeConfig.layout

        // If skin is dark when initialized => Add class to body
        if (skin.value === 'dark') document.body.classList.add('dark-layout')
        // Provide toast for Composition API usage
        // This for those apps/components which uses composition API
        // Demos will still use Options API for ease
        provideToast({
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            icon: false,
            timeout: 3000,
            transition: 'Vue-Toastification__fade',
        })

        // Set Window Width in store
        store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
        const { width: windowWidth } = useWindowSize()
        watch(windowWidth, val => {
            store.commit('app/UPDATE_WINDOW_WIDTH', val)
        })

        return {
            skinClasses,
            enableScrollToTop,
        }
    },


    mounted() {

        // Handle inactivity logout
        this.setupInactivityTimer();
    },
    methods: {
        handleUnload(event) {
            // Only trigger logout if `userNavigating` is false
            if (!this.userNavigating) {
                var getAuthHeaders = {
                    headers: {
                        Auth: btoa(this.getUserData().sub)  // Ensure the Auth header is correctly set
                    }
                };

                // Directly call update-user-status API
                axios
                    .post('/api/update-user-status', {}, getAuthHeaders)  // Adjust payload as needed
                    .then(() => {
                        // Clear storage and redirect after the status update
                        this.clearUserSession();
                        console.log('User logged out due to browser close');
                    })
                    .catch(err => {
                        console.log('Logout error:', err);
                    });

                // Optional: Prevent default behavior on closing the browser
                event.returnValue = '';
            }
        },
        setupInactivityTimer() {
            const getAuthHeaders = {
                headers: {
                    Auth: btoa(this.getUserData().sub), // Use the same header format
                },
            };

            const logOutUser = () => {
                // Same logout logic as in logOutUser for handling inactivity
                axios
                    .post('/api/update-user-status', {}, getAuthHeaders)
                    .then(() => {
                        this.clearUserSession();
                        console.log('Logged out due to inactivity');

                        // Redirect to login page after session clear
                        this.$router.push({ name: 'auth-login' });
                    })
                    .catch(error => {
                        console.error('Logout error:', error);
                    });
            };

            const resetTimer = () => {
                clearTimeout(this.timeout); // Clear any existing timeout
                this.timeout = setTimeout(logOutUser, 300000); // Set inactivity timeout for 5 minutes
            };

            // Reset inactivity timer on user interaction
            window.addEventListener('mousemove', resetTimer);
            window.addEventListener('keypress', resetTimer);

            // Start the inactivity timer initially
            resetTimer();
        },

        // Method to clear user session and redirect
        clearUserSession() {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
            localStorage.removeItem('userData');

            // Clear all local and session storage
            localStorage.clear();
            sessionStorage.clear();
            console.clear();
        },

        // Method to get user data (assuming it's stored in localStorage or session)
        getUserData() {
            return JSON.parse(localStorage.getItem('userData')); // Fetch the stored user data
        }
    },
    beforeDestroy() {
        // Remove event listeners when component is destroyed
        window.removeEventListener('beforeunload', this.handleUnload);
        window.removeEventListener('mousemove', this.resetTimer);  // Correct method for removing resetTimer
        window.removeEventListener('keypress', this.resetTimer);   // Correct method for removing resetTimer

        // Clear the inactivity timeout to avoid memory leaks
        clearTimeout(this.timeout);
    }


}
</script>
