import {BButton} from 'bootstrap-vue'
import {bus} from "@/main";
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {
    name: "CallButton",
    components: {
        BButton
    },
    methods: {
        triggerCall() {
            bus.$emit("phone:Call")
        },
        checkPermission()
        {
            let userData = JSON.parse(localStorage.getItem('userDetails'));
            let showSoftPhone = false;
            if(this.canPermittedByRoleOrUser('make-call','calls'))
            {
                showSoftPhone = true;

                if(userData.phone_number==null || userData.phone_number==undefined)
                {
                    showSoftPhone = false;
                }
            }
            else 
            {
                showSoftPhone = false;
            }
            return showSoftPhone;
        }
    },
    data() {
        return {
            CallButton: false
        }
    },
    setup() {
        const { canPermittedByRoleOrUser } = useAclUtils()

        return {
            // ACL
            canPermittedByRoleOrUser,
        }
    },
}
