<template>
    <div v-show="showModal">
        <base-modal
            id="confirmation_modal"
            :title="infoModal.title"
            centered
            size="sm"
            @hide="resetInfoModal"
        >
        <p v-html="infoModal.message" class="float-left"></p>
        <div slot="modal-footer" class="w-100">
            <b-button class="mt-3 float-right" @click="cancel('confirmation_modal')">Cancel</b-button>
            <b-button
                class="mt-3 float-right"
                variant="primary"
                v-on:click="deleteItem"
                style="margin-right: 10px;"
            >Yes</b-button>
        </div>
        </base-modal>
    </div>

</template>

<script>
import BaseModal from "@/views/components/modal/BaseModal.vue";

export default {
    components: {BaseModal},
  props: {
    receivedData: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      infoModal: {
        id: '',
        title: '',
        content:{},
        message:"",
      },
    }
  },

  mounted() {
    if (this.showModal)
    {
      this.showInfoModal(this.receivedData);
    }
  },
  methods:
  {
    deleteItem() {
        this.$emit('delete-action', this.receivedData.id);
        this.closeModal();
    },
    closeModal() {
        this.$emit('close-modal');
    },
    showInfoModal(data) {
      this.infoModal.content =data;
      this.infoModal.title=this.title;
      this.infoModal.message =this.message;
      this.$bvModal.show('confirmation_modal');
    },
    resetInfoModal() {
        this.infoModal.id="";
        this.infoModal.content = null;
        this.infoModal.title='';
        this.infoModal.message='';
        this.closeModal();
    },
    cancel(model_name) {
        this.$bvModal.hide(model_name);
        this.resetInfoModal();
    },
  }
};
</script>
