import axios from 'axios'
import {bus} from "@/main";

export default {
    async setUsers({commit, dispatch}) {

        commit('SET_LOADING', true);
        let url = "/api/users?all=true";
        return axios.get(url)
            .then(async function (response) {
                let users = response.data.data;
                commit('SET_USERS_DIR', users);
                commit('SET_LOADING', false)
            }).catch(err => console.log("error:", err.message));
    }
}
