var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "FileUpload",
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "b-button",
                { attrs: { block: "" }, on: { click: _vm.uploadFiles } },
                [_vm._v("Upload")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", [_vm._v("Upload Files:")]),
      _vm._v(" "),
      _c(
        "vue-perfect-scrollbar",
        {
          ref: "refChatLogPS",
          staticClass: "file-upload-scroll scroll-area",
          attrs: { settings: _vm.perfectScrollbarSettings },
        },
        [
          _c(
            "div",
            { staticClass: "upload-block", on: { click: _vm.selectFile } },
            [
              _c("input", {
                ref: "fileInput",
                attrs: { type: "file", multiple: "", hidden: "" },
                on: { change: _vm.onFileSelected },
              }),
              _vm._v(" "),
              _c("feather-icon", {
                staticClass: "d-block m-auto",
                attrs: { icon: "UploadCloudIcon", size: "8x" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "upload-help" }, [
                _vm._v("Click or Drag & Drop to upload files"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.fileRecords.length > 0
            ? _c(
                "div",
                { staticClass: "file-preview-container" },
                _vm._l(_vm.fileRecords, function (file, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "file-preview" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "file-info",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [_vm._v(_vm._s(file.name))]
                          ),
                          _vm._v(" "),
                          _c("b-progress", {
                            staticStyle: { "flex-grow": "1", margin: "0 10px" },
                            attrs: { value: file.progress, max: "100" },
                          }),
                          _vm._v(" "),
                          !file.sorted
                            ? _c(
                                "b-button",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { variant: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openSortAndSaveModal(file)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Sort&Save\n                    "
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "sorted-text",
                                  staticStyle: { "margin-right": "10px" },
                                },
                                [
                                  _vm._v(
                                    "\n                        Sorted\n                    "
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                color: "inherit",
                                "font-size": "1.2em",
                                padding: "0",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteFile(file)
                                },
                              },
                            },
                            [_vm._v("\n                        × ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "base-modal",
        {
          attrs: {
            id: "notification-modal",
            "hide-footer": "",
            title: "Notification",
          },
          on: { hide: _vm.clearNotification },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("b-button", { on: { click: _vm.closeNotification } }, [
                    _vm._v("Okay"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.closeNotification },
                    },
                    [_vm._v("Close")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("h5", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.notificationTitle)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "msg" }, [
              _vm._v(_vm._s(_vm.notificationMessage)),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("SortSave"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }