import axios from 'axios'
import {bus} from '@/main'
import {getUserData} from "@/auth/utils";

export default {
    async setCompany({commit, dispatch}, id) {
        try {
            bus.$emit('site:setLoading', true)
            commit('SET_LOADING', true);
            commit("RESET_COMPANY");
            const url = `/api/companies/${id}`
            return axios.get(url)
                .then(async response => {
                    const company = response.data
                    commit('SET_COMPANY_INFO', company)
                    await Promise.all([
                        dispatch('setCompanyType'),
                        dispatch('setContacts'),
                        dispatch('setSites'),
                        dispatch('setNotes'),
                        dispatch('setFiles'),
                        dispatch('setCollaborators'),
                        dispatch('setOutcomes'),
                    ]);

                    commit('SET_LOADING', false)
                }).catch(err => console.log('error:', err.message))
        } catch (error) {
            console.error('Error setting company:', error);
            // You might want to handle the error in a way that suits your application
        }
    },
    async setCallbacks({commit, dispatch, state}) {
        const url = `/api/companies/${state.companyDetails.id}`
        return axios.get(url)
            .then(async response => {
                const company = response.data
                commit('SET_CALLBACK_INFO', company.callbacks)
            }).catch(err => console.log('error:', err.message))
    },
    async saveCompany({commit, dispatch, state}, company) {
        dispatch("syncCompany", company);
    },
    async syncCompany({commit, state, dispatch, rootGetters}) {
        console.log(state);
        let primarySiteDetails = rootGetters["company/getPrimarySite"];
        //TODO: state.companyDetails is undefined???????
        axios.put("/api/companies/" + state.companyDetails.id, {
            company: state.companyDetails,
            site: {
                id: primarySiteDetails.id,
                address1: primarySiteDetails.address1,
                address2: primarySiteDetails.address2,
                town: primarySiteDetails.town,
                city: primarySiteDetails.city,
                postcode: primarySiteDetails.postcode,
                primary: 1
            },
        });
    },
    async saveContacts({commit, dispatch}, contacts) {
        commit('UPDATE_CONTACTS', contacts)
    },
    async setSites({commit, dispatch, state}) {
        const url = `/api/companies/${state.companyDetails.id}/sites/`
        return axios.get(url)
            .then(response => {
                commit('SET_SITE_INFO', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async setFiles({commit, dispatch, state}) {
        const url = `/api/companies/${state.companyDetails.id}/files/`
        return axios.get(url)
            .then(response => {
                commit('SET_FILE_INFO', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async setMeters({commit, dispatch, state}, id) {
        const url = `/api/meters/${id}`
        return axios.get(url)
            .then(response => {
                commit('SET_METER_INFO', response.data.meter)
            }).catch(err => console.log('error:', err.message))
    },
    async setNotices({commit, dispatch, state}, id) {
        const url = `/api/notice`
        return axios.get(url)
            .then(response => {
                commit('SET_NOTICES', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async setCompanyNotices({commit, dispatch, state}, id) {
        const url = `/api/company-notice/${state.companyDetails.id}`
        return axios.get(url)
            .then(response => {
                commit('SET_COMPANY_NOTICES', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async fetchProspects({commit, dispatch, state}) {
        axios.defaults.headers.common.Authorization = `Bearer ${document.querySelector('meta[name="csrf-token"]').getAttribute('content')}`
        axios.defaults.headers.common.Auth = btoa(getUserData().sub)
        return await axios.get('/api/prospects')
            .then(response => {
                return response.data.data.filter(prosp => prosp.callbacks.length === 0)
            })
    },
    async setLoading({commit, dispatch, state}, load) {
        commit('SET_LOADING', load)
    },

    async setCollaborators({commit, dispatch, state}) {
        const url = `/api/companies/${state.companyDetails.id}/collaborators/`
        return axios.get(url)
            .then(response => {
                commit('SET_COLLABORATOR_INFO', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async setOutcomes({commit, dispatch, state}) {
        const url = '/api/outcomelist'
        return axios.get(url)
            .then(response => {
                commit('SET_OUTCOMES', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async setNotes({commit, dispatch, state}) {
        const url = `/api/companies/${state.companyDetails.id}/notes/`
        return axios.get(url)
            .then(response => {
                commit('SET_NOTES', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async setContacts({commit, dispatch, state}) {
        const url = `/api/companies/${state.companyDetails.id}/contacts/`
        return axios.get(url)
            .then(response => {
                commit('SET_CONTACTS', response.data)
            }).catch(err => console.log('error:', err.message))
    },
    async addCollaborator({commit, dispatch, state}, data) {
        // console.log('vamsik');
        const url = `/api/users/${data.collab}`
        return axios.get(url)
            .then(response => {
                if (!state.collaborators) {
                    state.collaborators = []
                }
                state.collaborators.push(response.data)
                commit('UPDATE_COLLABORATOR_INFO', {collab: response.data, owner: data.user})
            }).catch(err => console.log('error:', err.message))
    },

    async removeCollaborator({commit, dispatch, state}, data) {
        commit('DELETE_COLLABORATOR_INFO', {collab: data.collab, owner: data.user})
    },
    async removeSite({commit, dispatch, state}, data) {
        commit('DELETE_SITE_INFO', data)
    },
    async addSite({commit, dispatch, state}, data) {
        commit('ADD_SITE_INFO', data)
    },
    async addMeter({commit, dispatch, state}, data) {
        state.errors = null;
        commit('ADD_METER_INFO', data)
    },
    async resetCompany({commit, state}) {
        commit('RESET_COMPANY')
    },
    async setCompanyType({commit, state}) {
        const url = `/api/company_type/${state.companyDetails.company_type}`
        return axios
            .get(url)
            .then(response => {
                commit('SET_COMPANY_TYPE', response.data.Name)
            }).catch(err => console.log('error:', err.message))
    },
    async setCompanyTypes({commit, state}) {
        commit('SET_COMPANY_TYPES')
    },
    async addNotice({commit, dispatch, state}, data) {
        commit('ADD_NOTICE', data)
    },
}
