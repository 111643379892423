var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    {
      ref: "modal",
      staticClass: "ProspectModal",
      attrs: {
        id: "ProspectModal",
        active: _vm.ProspectModal,
        title: `${_vm.record.business_name} (${_vm.record.public_id})`,
        centered: "",
        size: "xl",
      },
      on: {
        "update:active": function ($event) {
          _vm.ProspectModal = $event
        },
        close: _vm.closeModal,
      },
    },
    [
      _c(
        "div",
        { staticClass: "ProspectModal" },
        [
          _c(
            "div",
            { staticClass: "text-center", staticStyle: { margin: "0auto" } },
            [
              _vm.prospectLoading
                ? _c("b-spinner", {
                    staticStyle: {
                      width: "3rem",
                      height: "3rem",
                      "vertical-align": "middle",
                    },
                    attrs: { label: "Loading...", variant: "primary" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.prospectLoading
            ? _c(
                "transition",
                {
                  staticClass: "SitesMeters",
                  attrs: { mode: "out-in", name: "slide-fade" },
                },
                [
                  _vm.transitions.showHome
                    ? _c("ProspectDetails", {
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showProspectContacts
                    ? _c("ProspectContacts", {
                        key: _vm.ts,
                        attrs: { company_id: _vm.record.id },
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showDocGen
                    ? _c("DocGen", {
                        key: _vm.ts,
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showSitesMeters
                    ? _c("SitesMeters", {
                        key: _vm.ts,
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showFileManager
                    ? _c("FileManager", {
                        key: _vm.ts,
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showLog
                    ? _c("Log", {
                        key: _vm.ts,
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showCallLog
                    ? _c("CallLogs", {
                        key: _vm.ts,
                        attrs: { company_id: _vm.prospectDetails.id },
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transitions.showSMSLog
                    ? _c("SMSLogs", {
                        key: _vm.ts,
                        attrs: { company_id: _vm.prospectDetails.id },
                        on: { "change-tab": _vm.showTab },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "base-modal",
            {
              attrs: {
                id: "modal-phone",
                centered: "",
                "hide-footer": "",
                title: "Call & SMS",
              },
              on: { hide: _vm.onHide },
            },
            [_c("SoftPhone", { attrs: { contact: _vm.contact } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.transitions.showHome == false
        ? _c(
            "b-button",
            {
              staticClass: "back-button btn-icon rounded-circle",
              attrs: { size: "md", variant: "primary" },
              on: { click: _vm.goHome },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "chevron-left"] },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }