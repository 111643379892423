var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "SPID card border-primary font-large-1" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "col-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  disabled: !_vm.editing,
                  rules: "required",
                  name: "SPID",
                  immediate: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ valid, errors }) {
                      return [
                        _c(
                          "b-input-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c("v-select", {
                                        staticClass: "spid-type",
                                        attrs: {
                                          variant: "outline",
                                          clearable: false,
                                          searchable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.types,
                                          transition: "",
                                          disabled: !_vm.editing,
                                          type: "number",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selected-option-container",
                                              fn: function ({ option }) {
                                                return [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(option.value) +
                                                      "\n            "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "selected-option",
                                              fn: function (option) {
                                                return [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(option.value) +
                                                      "\n            "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.SPIDType,
                                          callback: function ($$v) {
                                            _vm.SPIDType = $$v
                                          },
                                          expression: "SPIDType",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("b-input", {
                              staticClass: "spid-number",
                              attrs: {
                                state: errors[0] ? false : valid ? true : null,
                                disabled: !_vm.editing,
                                placeholder: "Enter SPID number.",
                              },
                              model: {
                                value: _vm.meter.spid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.meter, "spid", $$v)
                                },
                                expression: "meter.spid",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "inputLiveFeedback" } },
                          [
                            _vm._v(
                              "\n        " + _vm._s(errors[0]) + "\n        "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }