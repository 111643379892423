var render, staticRenderFns
import script from "./FeatherIcon.vue?vue&type=script&lang=js"
export * from "./FeatherIcon.vue?vue&type=script&lang=js"
import style0 from "./FeatherIcon.vue?vue&type=style&index=0&id=fdd37702&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/dev21enduapp/dev.endu.app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fdd37702')) {
      api.createRecord('fdd37702', component.options)
    } else {
      api.reload('fdd37702', component.options)
    }
    
  }
}
component.options.__file = "frontend/src/@core/components/feather-icon/FeatherIcon.vue"
export default component.exports