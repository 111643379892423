<template>
    <b-row class = "single-note">
        <b-col cols = "1">

            <b-avatar
                slot = "avatar"
                v-b-tooltip.hover.bottom = "Name"
                :text = "initials"
            >
            </b-avatar>

        </b-col>
        <b-col cols = "11">
            <b-row class = "d-block">
                <!--                <b-col class = "title font-weight-bold" cols = "12" v-text = "Name">-->
                <!--                </b-col>-->
                <b-col class = "note" v-text = "Note">
                </b-col>
                <b-col class = "timestamp text-right text-muted" v-text = "TimeStamp">
                </b-col>
            </b-row>
            <div class = "row border-bottom"></div>
        </b-col>
    </b-row>
</template>
<style lang = "scss">
@import "./_single-note";
</style>
<script>

import {BAvatar, BCol, BRow} from 'bootstrap-vue';

export default {
    name: "SingleNote",
    components: {
        BAvatar,
        BRow,
        BCol
    },
    props: {
        value: {
            type: Object,
            required: true
        }
    },

    computed: {
        initials() {
            return this.Name.split(" ").map((n) => n[0]).join("");
        }
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        }
    },
    data() {
        return {
            TimeStamp: this.value.created_at,
            Note: this.value.note,
            Name: this.value.created_by
        }
    }
}
</script>


