<template>
    <b-row class="NotesAdd">

        <b-input-group class="col input-group-merge">
            <b-form-input v-model="note" v-on:keyup.enter="saveNote" />
            <b-input-group-append>
                <b-button variant="outline-primary" @click="saveNote">Save Note</b-button>
            </b-input-group-append>
        </b-input-group>
    </b-row>
</template>
<script>
import axios from "axios";
import store from "@/store"

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
    BRow, BCol, BCard, BButton, BModal, BInputGroup, BFormInput,
    BInputGroupAppend,
} from 'bootstrap-vue';
import Swal from 'sweetalert2';
export default {
    name: "add-notes",
    components: {
        BRow,
        BCol,
        BFormInput,
        BButton,
        BInputGroup,
        BInputGroupAppend,
    },
    props: ["company_id"],  // Receiving company_id as prop

    methods: {
        saveNote() {
            var self = this;
            console.log(self.activeUserInfo())
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            axios.post("/api/notes", {
                note: self.note,
                company_id: this.client_id,  // Use client_id if available, else fallback to company_id prop
                id: self.activeUserInfo().sub
            }).then(response => {
                store.dispatch("company/setNotes");
                self.note = "";
                // TODO: Add validation for Blank
            }
            ).catch(error => {
                if (error.data.status === 422) {
                    self.errors = error.data.errors || {};

                }
            })
        },
    },
    props: ["company_id"],
    computed: {
        activeUserInfo() {
            return getUserData
        }
    },
    data() {
        return {
            client_id: this.company_id || store.getters["company/getCompany"].id || null,
            note: "",
            user_id: null,
            errors: {}
        }
    }

}
</script>

<style lang="scss">
@import "./_add-notes";
</style>
