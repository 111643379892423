var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-code",
    {
      attrs: { title: "Gradient" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function () {
            return [_vm._v("\n    " + _vm._s(_vm.codeButtonGradient) + "\n  ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("use ")]),
        _vm._v(" "),
        _c("code", [_vm._v('variant="gradient-{color}"')]),
        _vm._v(" "),
        _c("span", [_vm._v(" prop create gradient buttons.")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-button", { attrs: { variant: "gradient-primary" } }, [
            _vm._v("\n      Primary\n    "),
          ]),
          _vm._v(" "),
          _c("b-button", { attrs: { variant: "gradient-secondary" } }, [
            _vm._v("\n      Secondary\n    "),
          ]),
          _vm._v(" "),
          _c("b-button", { attrs: { variant: "gradient-success" } }, [
            _vm._v("\n      Success\n    "),
          ]),
          _vm._v(" "),
          _c("b-button", { attrs: { variant: "gradient-danger" } }, [
            _vm._v("\n      Danger\n    "),
          ]),
          _vm._v(" "),
          _c("b-button", { attrs: { variant: "gradient-warning" } }, [
            _vm._v("\n      Warning\n    "),
          ]),
          _vm._v(" "),
          _c("b-button", { attrs: { variant: "gradient-info" } }, [
            _vm._v("\n      Info\n    "),
          ]),
          _vm._v(" "),
          _c("b-button", { attrs: { variant: "gradient-dark" } }, [
            _vm._v("\n      Dark\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }