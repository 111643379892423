var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-0", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                  _vm.loading
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("b-spinner", {
                            staticStyle: {
                              width: "3rem",
                              height: "3rem",
                              "vertical-align": "middle",
                            },
                            attrs: { label: "Loading...", variant: "primary" },
                          }),
                        ],
                        1
                      )
                    : _c("div", [
                        _vm.recordingUrl !== null
                          ? _c("div", [
                              _c("audio", {
                                ref: "audioPlayer",
                                attrs: { controls: "", src: _vm.recordingUrl },
                              }),
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-alert",
                                  { attrs: { show: "", variant: "danger" } },
                                  [_vm._v("Call recording is not available.")]
                                ),
                              ],
                              1
                            ),
                      ]),
                ]),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { cols: "6", md: "6" } },
                  [
                    _vm.canPermittedByRoleOrUser("delete-call", "calls")
                      ? _c(
                          "b-button",
                          {
                            staticClass: "float-right m-1 btn btn-secondary",
                            attrs: { variant: "outline-secondary" },
                            on: { click: _vm.deleteCall },
                          },
                          [_vm._v("\n            Delete\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canPermittedByRoleOrUser("download-call", "calls")
                      ? _c(
                          "b-button",
                          {
                            staticClass: "float-right m-1 btn-primary",
                            attrs: { variant: "outline-secondary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fetchAndDownloadRecording.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "DownloadIcon", size: "16" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }