<template>
<div class="AddContact">
        <div class="vs-row">
<form-wizard>
    <vs-input label="Title"></vs-input>
    <vs-input label="First Name"></vs-input>
    <vs-input label="Last Name"></vs-input>
    <vs-input label="Position"></vs-input>
    <vs-input label="Landline"></vs-input>
    <vs-input label="Mobile"></vs-input>
    <vs-input label="Email Address"></vs-input>

</form-wizard>
        </div>
</div>

</template>
<style lang="scss">
@import "_AddContact";
</style>

<script>


import FormWizard from "@/views/forms/form-wizard/FormWizard";
export default {
    name: "AddContact",
    components: {FormWizard},
    data() {
        return {

        }
    }
};

</script>

