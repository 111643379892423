var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("form-wizard-number"),
          _vm._v(" "),
          _c("form-wizard-vertical"),
          _vm._v(" "),
          _c("form-wizard-icon"),
          _vm._v(" "),
          _c("form-wizard-vertical-icon"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }