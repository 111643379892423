var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-code",
    {
      attrs: { title: "Block Level Buttons" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function () {
            return [_vm._v("\n    " + _vm._s(_vm.codeBlockButton) + "\n  ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "Create block level buttons — those that span the full width of a parent — by setting the"
          ),
        ]),
        _vm._v(" "),
        _c("code", [_vm._v("block")]),
        _vm._v(" "),
        _c("span", [_vm._v("prop.")]),
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "demo-inline-spacing" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { 400: true },
                      },
                    ],
                    attrs: { block: "", variant: "primary" },
                  },
                  [_vm._v("\n          Block level buttton\n        ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("b-col", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "demo-inline-spacing" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(113, 102, 240, 0.15)",
                        expression: "'rgba(113, 102, 240, 0.15)'",
                        modifiers: { 400: true },
                      },
                    ],
                    attrs: { block: "", variant: "outline-primary" },
                  },
                  [_vm._v("\n          Block level buttton\n        ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }