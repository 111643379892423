var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Sites" },
    _vm._l(_vm.sites, function (site) {
      return _c(
        "b-card",
        {
          key: site.id,
          staticClass: "m-0",
          attrs: { id: "site-" + site.id, "no-body": "" },
        },
        [
          _c(
            "b-card-header",
            {
              staticClass: "p-05",
              attrs: { "header-tag": "header", role: "tab" },
            },
            [
              _c(
                "b-button-group",
                { staticClass: "btn-block" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: "accordion-" + site.id,
                          expression: "'accordion-'+site.id",
                        },
                      ],
                      attrs: { block: "", variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSite("site-" + site.id)
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(site.address1)),
                      site.address2
                        ? _c("span", [_vm._v(" ," + _vm._s(site.address2))])
                        : _vm._e(),
                      site.address3
                        ? _c("span", [_vm._v("," + _vm._s(site.address3))])
                        : _vm._e(),
                      _vm._v(
                        "," + _vm._s(site.postcode) + "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "delete-site",
                      attrs: {
                        "aria-label": "Remove",
                        variant: "outline-danger",
                        disabled: _vm.isDeletable(site),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteSite(site)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "trash"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              attrs: {
                id: "accordion-" + site.id,
                name: "sites-accordion-" + site.id,
                accordion: "Sites",
                role: "tabpanel",
              },
            },
            [_c("Meters", { attrs: { meters: site.meters } })],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }