export default [
    // =============================================================================
    // Complaince /apps/complaince/
    // =============================================================================
    {
        name: 'complaince-sales-inbox',
        path: '/apps/complaince/sales-inbox/',
        component: () => import('@/views/apps/complaince/sales-inbox/sales.vue'),
        meta: {
            authRequired: true,
            breadcrumb: [
                {title: 'Home', url: '/'},
                {title: 'Sales Inbox', active: true},
            ],
            pageTitle: 'Sales Inbox',
            rule: 'editor',
            no_scroll: true,
            Navigation: 'Default',
            action: 'sales-inbox',
            resource: 'complaince',
        },
    },


]
