<template>
    <div class = "SiteDetails">
        <ValidationObserver
            ref = "SiteObserver"
        >
            <b-form-group>
                <b-row>
                    <b-col cols = "4">
                        <label for = "input-none">Address Line 1:</label>
                    </b-col>
                    <b-col cols = "8">
                        <ValidationProvider
                            v-slot = "{ valid, errors }"
                            rules = "required"
                            name = "Address 1"
                            immediate
                        >

                            <b-form-input
                                id = "readOnlyInput"
                                v-model = "SiteDetails.address1"
                                :disabled = "readonly"
                                :readonly = "readonly"
                                :state = "errors[0] ? false : (valid ? true : null)"
                                placeholder = "Address 1"
                            />
                            <b-form-invalid-feedback id = "inputLiveFeedback">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group>
                <b-row>
                    <b-col>
                        <label for = "input-none">Address Line 2:</label>
                    </b-col>
                    <b-col cols = "8">
                        <b-form-input
                            id = "readOnlyInput"
                            v-model = "SiteDetails.address2"
                            :disabled = "readonly"
                            :readonly = "readonly"
                            placeholder = "Address 2"
                        />
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group>
                <b-row>
                    <b-col cols = "4">
                        <label for = "input-none">City:</label>
                    </b-col>
                    <b-col cols = "8">
                        <ValidationProvider
                            v-slot = "{ valid, errors }"
                            rules = "required"
                            name = "City"
                            immediate
                        >

                            <b-form-input
                                id = "readOnlyInput"
                                v-model = "SiteDetails.city"
                                :disabled = "readonly"
                                :readonly = "readonly"
                                :state = "errors[0] ? false : (valid ? true : null)"
                                placeholder = "City"
                            />
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group>
                <b-row>
                    <b-col cols = "4">
                        <label for = "input-none">Postcode:</label>
                    </b-col>
                    <b-col cols = "8">
                        <ValidationProvider
                            v-slot = "{ valid, errors }"
                            rules = "required"
                            name = "Postcode"
                            immediate
                        >

                            <b-form-input
                                v-model = "SiteDetails.postcode"
                                :disabled = "readonly"
                                :state = "errors[0] ? false : (valid ? true : null)"
                                :readonly = "readonly"
                                placeholder = "Post Code"
                            />
                            <b-form-invalid-feedback id = "inputLiveFeedback">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </b-form-group>
        </ValidationObserver>
    </div>
</template>
<style lang = "scss">
@import 'SiteDetails';

</style>

<script>
import store from '@/store'
import vSelect from 'vue-select'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import './Rules/Validation'

// import draggable from 'vuedraggable'
// import Prism from 'vue-prism-component'
import {BButton, BCol, BFormGroup, BFormSelect, BFormSelectOption, BRow, VBTooltip,} from 'bootstrap-vue'
import {getUserData} from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {bus} from '@/main'

export default {
    name: 'SiteDetails',
    components: {
        BButton,
        // 'v-select': vSelect,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        vSelect,
        BFormSelect,
        BFormSelectOption,
        ToastificationContent,
        VBTooltip,
        ValidationObserver,
        ValidationProvider,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,

    },
    data() {
        return {
            title: 'Site',
            readonly: false,
            transitions: {
                showNumber: true,
                showOutcome: true,
            },
            ts: 0,
            SiteDetails: {
                address1: null,
                address2: null,
                city: null,
                postcode: null,
            },

        }
    },
    computed: {

        activeUserInfo() {
            return getUserData
        },
        companyLoading() {
            return store.getters['company/getStatus']
        },

        companyDetails: {
            get() {
                return store.getters['company/getCompany']
            },
            set(newDetails) {
                store.commit("company/SET_COMPANY_INFO", newDetails)
            },
        },
        primarySiteDetails() {
            return store.getters['company/getPrimarySite']
        },

    },
    watch: {
        'SiteDetails.address1': function (newSite) {
            // TODO: Work on validation, this is just a proof of concept - Vuexy uses Vee-validate
            switch (true) {
                case newSite.length > 0: // checks length of address 1
                    bus.$emit('SiteDetails:SiteValid', true) // return this for valid address
                    break
                default:

                    bus.$emit('SiteDetails:SiteValid', false) // return this if invalid
                    break
            }
        },
    },
    mounted() {
        const self = this
        store.dispatch('dataManager/setSectors')
        store.dispatch('company/setCompanyTypes')
        bus.$emit('pageTitle:set', this.companyDetails.business_name)
        bus.$on('prospect:edit', edit => {
            self.readonly = !edit
            if (edit === false) {
                // region Data Save
                self.$http.put(`/companies/${self.companyDetails.id}`, {

                    site: {
                        id: self.SiteDetails.id,
                        address1: self.SiteDetails.address1,
                        address2: self.SiteDetails.address2,
                        town: self.SiteDetails.town,
                        city: self.SiteDetails.city,
                        postcode: self.SiteDetails.postcode,

                    },
                })

                // self.store.dispatch("company/setCompany", self.companyDetails.id);
                // self.$http.put("/sites/" + self.primarySiteDetails.id, {
                //     site: {
                //         address1: self.primarySiteDetails.address1,
                //         address2: self.primarySiteDetails.address2,
                //         town: self.primarySiteDetails.town,
                //         city: self.primarySiteDetails.city,
                //         postcode: self.primarySiteDetails.postcode,
                //
                //
                //     }
                // })
                // //endregion
            }
        })
        // debugger;
    },
    methods: {
        validateState(name) {
            // debugger;
            if (this[name]) {
                const {$dirty, $error} = this[name]
                return $dirty ? !$error : null
            }
        },
        toggleEdit(event) {
            // debugger;
            this.readonly = !this.readonly
        },
    },

}

</script>
