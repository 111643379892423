<template>
  <div :class="{ 'open': isSoftPhoneOpen }" class="softphone-sidebar d-none d-md-block">
    <!-- Toggler -->
    <b-link class="softphone-sidebar-toggle d-flex align-items-center justify-content-center" @click="openSoftPhone">
      <feather-icon icon="PhoneIcon" size="15" />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="softphone-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          Phone Dialer
        </h4>
        <small>Dial number with country code</small>
      </div>
      <feather-icon class="cursor-pointer" icon="XIcon" size="18" @click="isSoftPhoneOpen = !isSoftPhoneOpen" />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-customizer-area scroll-area">
      <b-card>

        <b-row>
          <b-col cols="12">

            <h1>
              <!--                                Show Phone Number & Outcome Box If Call Button Hit-->

              <template>
                <font-awesome-icon :icon="['fas', 'phone']" />
                <b v-text="input" />
                <div v-if="parent_sid !== null && call_status !== null">
                  <span>Call Status : {{ call_status }}</span>
                </div>
                <!--                                    Show number for primary contact by default-->
              </template>
            </h1>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="media d-flex align-items-center">
              <b-form-checkbox :checked="gdpr" switch @change="gdprToggle()" />
              <h6 class="font-weight-bolder mr-auto mb-0">
                GDPR Compliance
              </h6>
            </div>
          </b-col>
        </b-row>
        <input type="tel" v-model="input" placeholder="Enter phone number" class="form-control mb-3"
          @input="onInputChange" />
        <SimpleKeyboard :input="input" :theme="theme" @onChange="onChange" @onKeyPress="onKeyPress" />
      </b-card>

      <div class="softphone-section">
        <SoftPhone ref="phoneInfo" />
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BFormCheckbox, BFormGroup, BFormRadioGroup, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SimpleKeyboard from '@core/layouts/components/soft-phone/SimpleKeyboard'
import { bus } from '@/main'
import SoftPhone from '@/views/components/SoftPhone/SoftPhone'
import useSoftPhone from './useSoftPhone'

import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    SoftPhone,
    // region Bootstrap Vue
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,
    // endregion

    // region 3rd party
    SimpleKeyboard,
    vSelect,
    VuePerfectScrollbar,
    // endregion

  },
  data() {
    return {
      input: '',
      gdpr: true,
      theme: 'hg-theme-default myTheme1',
      remoteInput: false,
      isValidMobileNumber: false,
      parent_sid: null,
      call_status: null,
      status_count: 0,
      intervalId: null,
      userData: null,
      from_number: null,
      calltype: 'unknown',
      company_id: null,
      twilio_number: null,
    }
  },
  mounted() {
    const self = this
    this.userData = JSON.parse(localStorage.getItem('userDetails'));
    this.from_number = this.userData.extension;
    this.twilio_number = this.userData.twilio_phone_number;

    console.log(self.$route.params);
    self.company_id = (self.$route.params.clientid) ? Number(self.$route.params.clientid) : null;
    bus.$on('phone:Call', telephone => {
      // self.$refs.phoneInfo.$forceUpdate()
      self.input = telephone
      // self.remoteInput = true
      // self.isSoftPhoneOpen = true
    })
    bus.$on('phone:OpenDialer', payload => {
      const { phone_number, type } = payload; // Destructure the payload to get phone_number and name

      self.call_status = null;
      if (phone_number !== null && phone_number !== undefined) {
        self.calltype = type
        self.input = phone_number
        self.remoteInput = true
      }
      self.isSoftPhoneOpen = true
    })
    bus.$on('phone:OpenSms', telephone => {
      self.call_status = null;
      if (telephone !== null && telephone !== undefined) {
        self.input = telephone
        self.remoteInput = true
      }
      self.isSoftPhoneOpen = true
    })
  },
  watch: {
    'call_status': function () {
      if (this.call_status != 'completed') {
        this.checkCallStatus();
      }
    },
    'isSoftPhoneOpen': function () {
      if (this.isSoftPhoneOpen == false) {
        this.call_status = null;
        // this.status_count = 0;
        // clearInterval(this.intervalId);
      }
    },
  },
  beforeDestroy() {
    this.call_status = null;
    this.status_count = 0;
    // clearInterval(this.intervalId); 
  },
  methods: {
    gdprToggle() {
      this.gdpr = (this.gdpr == true) ? false : true;
    },
    validateMobileNumber() {
      // const validationRegex = /^\+[1-9]{1}[0-9]{7,11}$/;
      // if (this.input.match(validationRegex)) {
      //   this.isValidMobileNumber = true;
      // } else {
      //   this.isValidMobileNumber = false;
      // }
      // return this.isValidMobileNumber;
      return true;
    },
    onChange(input) {
      this.input = input
    },
    checkCallStatus() {
      if (this.status_count <= 10) {
        setTimeout(this.getCallDetails, 5000);
      }
      else {
        if (this.call_status == 'initiated') {
          console.log('no action');
        }
        else if (
          this.call_status == 'completed' ||
          this.call_status == 'busy' ||
          this.call_status == 'failed' ||
          this.call_status == 'no-answer' ||
          this.call_status == 'canceled'
        ) {
          console.log('no action');
          // Swal.fire({
          //   title:'Failed!',
          //   text:this.call_status,
          //   icon:'info',
          //   timer: 2000
          // });
          // this.isSoftPhoneOpen = !this.isSoftPhoneOpen;
          // this.call_status= null;
          // this.input = '';
        }
        else {
          setTimeout(this.getCallDetails, 5000);
        }
      }
    },
    getCallDetails() {
      axios.get("/api/details-call/" + this.parent_sid).then((response) => {
        this.parent_sid = response.data.data.parent_sid;
        this.call_status = response.data.data.status;
        if (response.data.data.status == 'initiated') {
          if (this.status_count < 10) {
            this.status_count++;
            this.checkCallStatus();
          }
          else {
            this.call_status = 'No Answer';
            // this.status_count = 0;
            // Swal.fire({
            //   title:'Call Information!',
            //   text:'Call Status : '+this.call_status,
            //   icon:'info',
            //   timer: 2000
            // });
            // this.isSoftPhoneOpen = !this.isSoftPhoneOpen;
            // this.call_status= null;
            // this.input = '';
          }
        }
        else if (
          response.data.data.status == 'completed' ||
          response.data.data.status == 'busy' ||
          response.data.data.status == 'failed' ||
          response.data.data.status == 'no-answer' ||
          response.data.data.status == 'canceled'
        ) {
          console.log('no action');
          // Swal.fire({
          //   title:'Call Information!',
          //   text:'Call Status : '+response.data.data.status,
          //   icon:'info',
          //   timer: 2000
          // });
          // this.isSoftPhoneOpen = !this.isSoftPhoneOpen;
          // this.call_status= null;
          // this.input = '';
        }
        else {
          this.status_count++;
          this.checkCallStatus();
        }

      }).catch(error => {
        if (error.data.status === 422) {
          this.errors = error.data.errors || {};
          // Swal.fire(
          //     'Error!',
          //     response.data.message,
          //     'Error'
          // );
        }
      });
    },
    onKeyPress(button) {
      if (button == '{clear}') {
        this.input = ''
      }
      if (button == '{call}') {
        if (this.validateMobileNumber()) {
          this.status_count = 0;
          axios.post("/api/initiate-call", {
            "from": this.from_number,
            "to": this.input,
          }).then(response => {
            // console.log(response);
            this.parent_sid = response.data.data.parent_sid;
            this.call_status = response.data.data.call_status;
            this.checkCallStatus();
            // this.intervalId = setInterval(this.checkCallStatus, 5000);
            // Swal.fire(
            //     'Success!',
            //     response.data.response,
            //     'success'
            // );
          }).catch(error => {
            if (error.data.status === 422) {
              this.errors = error.data.errors || {};
              // Swal.fire(
              //     'Error!',
              //     response.data.message,
              //     'Error'
              // );
            }
          })
        }
        else {
          console.log('Number not valid');

          Swal.fire(
            'Error!',
            'Number not valid',
            'Error'
          );
        }
      }
    },
    onInputChange(input) {

      this.input = input.target.value
    },
    openSoftPhone() {
      this.isSoftPhoneOpen = !this.isSoftPhoneOpen
      if (this.isSoftPhoneOpen === false) {
        this.remoteInput = false
      }
    },
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isSoftPhoneOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useSoftPhone()

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isSoftPhoneOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Perfect Scrollbar
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import "./SoftPhone";
</style>
