
import state from './moduleLOAState.js'
import mutations from './moduleLOAMutations.js'
import actions from './moduleLOAActions.js'
import getters from './moduleLOAGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
