export default [

  {
    name: 'finance-dashboard',
    path: '/apps/finance-suite/dashboard',
    component: () => import('@/views/apps/finance-suite/FinanceDashboard.vue'),
    meta: {
      authRequired: true,
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Finance Suite', active: true },
      ],
      pageTitle: 'Finance Suite',
      // rule: 'editor',
      no_scroll: true,

    },
  },
  {
    name: 'sales-ledger',
    path: '/apps/finance-suite/ledger',
    component: () => import('@/views/apps/finance-suite/sales-ledger/SalesLedger.vue'),
    meta: {
      resource: 'finance-suite',
      action: 'view',
      authRequired: true,
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Sales Ledger', active: true },
      ],
      pageTitle: 'Sales Ledger',
      // rule: 'editor',
      no_scroll: true,
    },
  },

]
