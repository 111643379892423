<template>
    <div class="Meters">

        <b-card slot="no-body" v-if="meters.length">
            <b-row>
                <b-col cols="2">
                    Type
                </b-col>
                <b-col cols="3">
                    Identifier
                </b-col>
                <b-col cols="2">
                    CED
                </b-col>
                <b-col cols="2">
                    EAC (kWh)
                </b-col>
                <b-col cols="3">
                    Supplier
                </b-col>

                <!-- <b-col cols="3" icon="filter">
                                               External -In contract - Not arranged by broker
                    Acquisition Pending
                    Acquisition LockedIn
                    Acquisition Live
                    Acquisition Lost (We presented quotes but did not win the business - link to closer loss notes in closer panel)
                    Renewal Pending
                    Renewal LockedIn-
                    Renewal Live
                    Renewal Pending
                    Renewal Delayed (We have presented quotes but the client has said they want to wait til closer to the time (link to closer panel returned lead "Reason")
                    Renewal Lost (We have presented quotes for the renewal but did not secure the next contract)
                    Confirmed Lost - After all sales have reached their end the known contract end date has passed  (Must have a minimum of one recorded sale-->

                <!-- </b-col> -->

            </b-row>
                <MeterRecord v-for="meter in meters" :key="meter.id" :meterid="meter.id" class="meter-record">
                </MeterRecord>
        </b-card>
        <!--        <b-card v-if="meters.length">-->
        <!--            <MeterRecord v-for="meter in meters" :key="meter.id" :meterid="meter.id" class="meter-record"></MeterRecord>-->
        <!--        </b-card>-->
    </div>
</template>
<style lang="scss">
@import "_Meters.scss";
</style>

<script>
import {
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BCollapse,
    BFormGroup,
    BRow,
    VBToggle,
    VBTooltip
} from 'bootstrap-vue';
import MPAN from '@/views/services/broker/electric/identifier/MPAN';
// import CardTerminal from '@/views/services/products/CardTerminal/CardTerminal';
import MeterRecord from "@/views/components/SitesMeters/Meters/MeterRecord/MeterRecord";

export default {
    name: "Meters",
    components: {
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        VBTooltip,
        BCollapse,
        BCard,
        BCardHeader,
        BCardBody,
        VBToggle,
        MPAN,
        MeterRecord,
        // CardTerminal
    },
    props: {
        meters: [],
    },
    data() {
        return {
            page: 1,
            title: "Meters",
            record: {
                id: null,
                business_name: " ",
                public_id: " ",
            },
            home: true
        }
    },


}
</script>
