<template>
    <base-modal id="SortSave" no-close-on-backdrop @cancel="resetDoc" @close="resetDoc" @ok="saveSortData">
        <template #modal-title>
            Sort & Save
        </template>
        <template #default>
            <b-row>
                <b-col cols="4">
                    Document Type
                </b-col>
                <b-col cols="8">
                    <v-select v-model="saveData.DocType" :options="docs" :reduce="doc => doc.value" dense label="text"
                        outlined placeholder="Please select a document type" @open="resetDoc"></v-select>
                </b-col>
            </b-row>

            <!-- HH Data -->
            <b-row v-if="saveData.DocType === 'hh'">
                <b-col cols="4">
                    Select Meter
                </b-col>
                <b-col cols="8">
                    <v-select v-model="saveData.Entity" :options="getHH(meters)" :reduce="meter => meter.name" chips
                        dense label="name" multiple outlined searchable></v-select>
                </b-col>
            </b-row>

            <!-- Bill Data -->
            <b-row v-else-if="saveData.DocType === 'bill'">
                <b-col cols="4">
                    Select Utility
                </b-col>
                <b-col cols="8">
                    <v-select v-model="saveData.UtilityType" :options="utility_type" :reduce="util => util.value" chips
                        dense label="text" outlined searchable></v-select>
                </b-col>
                <b-col cols="4" v-if="saveData.UtilityType">
                    Bill Date
                </b-col>
                <b-col cols="8" v-if="saveData.UtilityType">
                    <flat-pickr v-model="saveData.BillDate" :config="configMonthYPicker" class="form-control col-12"
                        placeholder="Select the date of bill" />
                </b-col>

                <b-col cols="4" v-if="saveData.BillDate">
                    Select Meter
                </b-col>
                <b-col cols="8" v-if="saveData.BillDate">
                    <v-select v-model="saveData.Entity" :options="meters" chips dense label="name" multiple outlined
                        searchable></v-select>
                </b-col>
            </b-row>

            <!-- LOA Data -->
            <b-row v-else-if="saveData.DocType === 'loa'">
                <b-col cols="4">
                    Select Version
                </b-col>
                <b-col cols="8">
                    <v-select v-model="saveData.Version" :options="loa_vers" dense label="text" outlined></v-select>
                </b-col>
            </b-row>

            <!-- Tenancy Data -->
            <b-row v-else-if="saveData.DocType === 'tenancy'">
                <b-col cols="4">
                    Incoming or Outgoing
                </b-col>
                <b-col cols="8">
                    <v-select v-model="saveData.Direction" :options="tenancy_direction" :reduce="dir => dir.value" dense
                        label="text" outlined></v-select>
                </b-col>

                <b-col cols="4" v-if="saveData.Direction">
                    Select Site
                </b-col>
                <b-col cols="8" v-if="saveData.Direction">
                    <v-select v-model="saveData.Entity" :options="sites" closeOnSelect dense label="address1"
                        multiple></v-select>
                </b-col>
            </b-row>

            <!-- Other Document Type -->
            <b-row v-else-if="saveData.DocType === 'other'">
                <b-col cols="4">
                    Enter A Name
                </b-col>
                <b-col cols="8">
                    <b-input v-model="saveData.filename"></b-input>
                </b-col>

                <b-col cols="4">
                    Enter Description
                </b-col>
                <b-col cols="8">
                    <b-input v-model="saveData.Description"></b-input>
                </b-col>
            </b-row>

            <!-- Expiry Date for LOA -->
            <b-row v-if="saveData.Version">
                <b-col cols="4">
                    Expiry Date
                </b-col>
                <b-col cols="8">
                    <flat-pickr v-model="saveData.ExpiryDate" class="form-control col-12"
                        placeholder="Set LOA Expiry" />
                </b-col>
            </b-row>
        </template>
    </base-modal>
</template>

<script>
import { BAvatar, BCol, BModal, BRow } from 'bootstrap-vue'
import store from "@/store";
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import featherIcon from "@core/components/feather-icon/FeatherIcon";
import { bus } from "@/main";
import BaseModal from "@/views/components/modal/BaseModal.vue";

export default {
    components: {
        BaseModal,
        BRow,
        BCol,
        BModal,
        BAvatar,
        vSelect,
        flatPickr,
        featherIcon
    },
    name: "SortSave",
    data() {
        return {
            computeRefresh: false,
            tenancy_direction: [{ text: "Incoming", value: "incoming" }, { text: "Outgoing", value: "outgoing" }],
            utility_type: [{ text: "Gas", value: "gas" }, { text: "Electric", value: "electric" }, { text: "Water", value: "water" }],
            loa_vers: [{ text: "Version 1", value: 1 }, { text: "Version 2", value: 2 }],
            docs: [
                { text: "Consumption Data (HH)", value: "hh" },
                { text: "Utility Bill(s)", value: "bill" },
                { text: "Letter Of Authority", value: "loa" },
                { text: "Change of Tenancy", value: "tenancy" },
                { text: "Other", value: "other" }
            ],


            saveData: {
                DocType: null,
                Entity: null,
                BillDate: null,
                Direction: null,
                Description: null,
                Version: null,
                UtilityType: null,
                filename: null,
                ExpiryDate: null
            },
            configMonthYPicker: {
                dateFormat: 'M-Y',
                altFormat: "F Y",
                monthSelectorType: "static"
            }
        }
    },
    computed: {
        sites() {
            return store.getters["company/getSites"];
        },
        meters() {
            console.log("Company object:", this.company);  // Check company object
            console.log("All meters:", this.company.all_meters);  // Check all_meters array
            console.log("Selected UtilityType:", this.saveData.UtilityType);  // Check UtilityType value

            let meterType = ""; // Declare meterType variable


            if (this.saveData.UtilityType == "electric") {
                meterType = "App\\Models\\ElecMeters";
            } else if (this.saveData.UtilityType == "gas") {
                meterType = "App\\Models\\GasMeters";
            } else {
                meterType = "App\\Models\\WaterMeters";
            }
            console.log("Changed UtilityType:", meterType);
            return this.company.all_meters.filter(meter => meter.type === this.saveData.UtilityType);
        },
        company() {
            return store.getters["company/getCompany"];
        }
    },
    methods: {
        getHH(meters) {
            console.log("hh" + meters);
            return this.company.all_meters.filter(meter => meter.type === "electric");

            //return meters.filter(meter => meter.profile === "00");
        },
        saveSortData() {
            let obj = { obj: this.selectedFile.id, data: this.saveData };

            // Set filename based on DocType
            switch (this.saveData.DocType) {
                case "bill":
                    obj.data.filename = "Utility Bill - " + this.saveData.UtilityType.toUpperCase() + " - " + this.saveData.Entity.map(meter => meter.name).join(", ");
                    break;
                case "hh":
                    obj.data.filename = "HH Data - " + this.saveData.Entity.join(", ");
                    break;
                case "loa":
                    obj.data.filename = "Letter of Authority - " + this.saveData.Version.text;
                    break;
                case "tenancy":
                    obj.data.filename = "Change of Tenancy - " + this.saveData.Direction.toUpperCase() + " - " + this.saveData.Entity.map(site => site.address1).join(", ");
                    break;
                case "other":
                    obj.data.filename = this.saveData.filename;
                    break;
            }

            bus.$emit('SaveSortModal:saveData', { obj, fileId: this.selectedFile.id });
        },
        resetDoc() {
            this.saveData = {
                DocType: this.saveData.DocType,
                Entity: null,
                BillDate: null,
                Direction: null,
                Description: null,
                Version: null,
                UtilityType: null,
                filename: null,
                ExpiryDate: null
            }
        }
    },
    mounted() {
        bus.$on("SaveSortModal:prepareData", (file, metadata) => {
            this.selectedFile = file;
            this.saveData = metadata;
            this.computeRefresh = !this.computeRefresh;
        });
    }
}
</script>

<style lang="scss">
@import "./_SortSave";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
