var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "single-note" },
    [
      _c(
        "b-col",
        { attrs: { cols: "1" } },
        [
          _c("b-avatar", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.bottom",
                value: _vm.Name,
                expression: "Name",
                modifiers: { hover: true, bottom: true },
              },
            ],
            attrs: { slot: "avatar", text: _vm.initials },
            slot: "avatar",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "11" } },
        [
          _c(
            "b-row",
            { staticClass: "d-block" },
            [
              _c("b-col", {
                staticClass: "note",
                domProps: { textContent: _vm._s(_vm.Note) },
              }),
              _vm._v(" "),
              _c("b-col", {
                staticClass: "timestamp text-right text-muted",
                domProps: { textContent: _vm._s(_vm.TimeStamp) },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row border-bottom" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }