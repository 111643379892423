var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.checkPermission()
    ? _c(
        "b-button",
        {
          staticClass: "callButton col",
          attrs: { icon: "phone", target: "", variant: "primary" },
          on: { click: _vm.triggerCall },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: ["fa", "phone"] } }),
          _vm._v("\n    Interact\n\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }