var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", {
    ref: "refFormObserver",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ handleSubmit }) {
          return [
            _vm.apiError != null
              ? _c(
                  "div",
                  [
                    _c(
                      "b-alert",
                      {
                        staticClass: "m-1 p-2",
                        attrs: {
                          show: _vm.dismissCountDown,
                          dismissible: "",
                          variant: "danger",
                        },
                        on: {
                          dismissed: function ($event) {
                            _vm.dismissCountDown = 0
                          },
                          "dismiss-count-down": _vm.countDownChanged,
                        },
                      },
                      [_vm._v("\n      " + _vm._s(_vm.apiError) + "\n    ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-form",
              {
                staticClass: "p-2",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.saveChanges)
                  },
                  reset: function ($event) {
                    $event.preventDefault()
                    return _vm.resetForm.apply(null, arguments)
                  },
                },
              },
              [
                _c("validation-provider", {
                  ref: "nameValidator",
                  attrs: { name: "supplier_id", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (validationContext) {
                          return [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Supplier",
                                  "label-for": "supplier_id",
                                  state:
                                    _vm.getValidationState(validationContext),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v("\n          Supplier "),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v(" *")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    "input-id": "supplier_id",
                                    options: _vm.supplierOptions,
                                    reduce: (val) => val.id,
                                    clearable: false,
                                    searchable: "",
                                  },
                                  model: {
                                    value: _vm.createData.supplier_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.createData,
                                        "supplier_id",
                                        $$v
                                      )
                                    },
                                    expression: "createData.supplier_id",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    attrs: {
                                      state:
                                        _vm.getValidationState(
                                          validationContext
                                        ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(validationContext.errors[0]) +
                                        "\n        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "Start date", "label-for": "start_date" } },
                  [
                    _c("flat-pickr", {
                      staticClass: "form-control",
                      attrs: {
                        config: _vm.dateTimeConfig,
                        placeholder: "DD-MM-YYYY",
                      },
                      model: {
                        value: _vm.createData.start_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.createData, "start_date", $$v)
                        },
                        expression: "createData.start_date",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("validation-provider", {
                  ref: "dateTimeValidator",
                  attrs: { name: "End date", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (validationContext) {
                          return [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "End Date",
                                  "label-for": "end_date",
                                  state:
                                    _vm.getValidationState(validationContext),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v("\n          End Date "),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v(" *")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("flat-pickr", {
                                  staticClass: "form-control",
                                  attrs: {
                                    config: _vm.dateTimeConfig,
                                    placeholder: "DD-MM-YYYY",
                                  },
                                  model: {
                                    value: _vm.createData.end_date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.createData, "end_date", $$v)
                                    },
                                    expression: "createData.end_date",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    attrs: {
                                      state:
                                        _vm.getValidationState(
                                          validationContext
                                        ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(validationContext.errors[0]) +
                                        "\n        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("validation-provider", {
                  attrs: { name: "consumption", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (validationContext) {
                          return [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "consumption",
                                  state:
                                    _vm.getValidationState(validationContext),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n          Consumption\n        "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    id: "consumption",
                                    placeholder: "Consumption in kWh",
                                  },
                                  model: {
                                    value: _vm.createData.consumption,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.createData,
                                        "consumption",
                                        $$v
                                      )
                                    },
                                    expression: "createData.consumption",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    attrs: {
                                      state:
                                        _vm.getValidationState(
                                          validationContext
                                        ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(validationContext.errors[0]) +
                                        "\n        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex mt-2" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { 400: true },
                          },
                        ],
                        staticClass: "mr-2",
                        attrs: { variant: "primary", type: "submit" },
                      },
                      [_vm._v("\n        Add Contract\n      ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(186, 191, 199, 0.15)",
                            expression: "'rgba(186, 191, 199, 0.15)'",
                            modifiers: { 400: true },
                          },
                        ],
                        attrs: { type: "button", variant: "outline-secondary" },
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v("\n        Cancel\n      ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }