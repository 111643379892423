var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ContactDetails" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "detail-options", attrs: { cols: "6" } }, [
            _c("b", [_vm._v("Main Contact:")]),
          ]),
          _vm._v(" "),
          _c("b-col", { staticClass: "detail-options", attrs: { cols: "6" } }, [
            _c("b", [
              _vm._v(
                _vm._s(_vm.primaryContactDetails.title || "") +
                  " " +
                  _vm._s(_vm.primaryContactDetails.full_name || "")
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "detail-options", attrs: { cols: "6" } }, [
            _c("b", [_vm._v("Next Callback:")]),
          ]),
          _vm._v(" "),
          _c("b-col", { staticClass: "detail-options", attrs: { cols: "6" } }, [
            _vm.latestCallback
              ? _c("b", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.latestCallback) + "\n      "
                  ),
                ])
              : _c("b", [_vm._v("No Callback Found")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }