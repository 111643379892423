var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-0", attrs: { "no-body": "" } }, [
        _vm.sms_details !== null
          ? _c(
              "div",
              { staticClass: "ContactDetails" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "3" } },
                      [_c("b", [_vm._v("To:")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.sms_details.data.to) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "3" } },
                      [_c("b", [_vm._v("Content:")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.sms_details.data.message) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "3" } },
                      [_c("b", [_vm._v("Status:")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.sms_details.data.status) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "3" } },
                      [_c("b", [_vm._v("Message Sent on:")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "detail-options", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.sms_details.data.message_date_created) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _vm.canPermittedByRoleOrUser("delete-sms", "sms")
                          ? _c("b-button", { on: { click: _vm.deleteSms } }, [
                              _vm._v("\n            Delete\n          "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }