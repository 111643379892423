export default {
    getProviders: (state) => {
        return state.providers;
    },
    getSectors: (state) => {
        return state.sectors;
    },
    getFile: (state) => {
        return state.file;
    },
    getHeaders: (state) => {
        return state.headers;
    },
    getRecords: (state) => {
        return state.records;
    },
    getBatchInfo: (state) => {
        return state.batchinfo;
    },
    getSectorChartData: (state) => {
        return state.charts.sector;
    },
    getProviderChartData: (state) => {
        return state.charts.provider;
    },
    getTotalData: (state) => {
        return state.totals;
    }
}
