var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "softphone-sidebar d-none d-md-block",
      class: { open: _vm.isSoftPhoneOpen },
    },
    [
      _c(
        "b-link",
        {
          staticClass:
            "softphone-sidebar-toggle d-flex align-items-center justify-content-center",
          on: { click: _vm.openSoftPhone },
        },
        [_c("feather-icon", { attrs: { icon: "PhoneIcon", size: "15" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "softphone-section d-flex justify-content-between align-items-center",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "XIcon", size: "18" },
            on: {
              click: function ($event) {
                _vm.isSoftPhoneOpen = !_vm.isSoftPhoneOpen
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass: "ps-customizer-area scroll-area",
          attrs: { settings: _vm.perfectScrollbarSettings },
        },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h1",
                      [
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "phone"] },
                          }),
                          _vm._v(" "),
                          _c("b", {
                            domProps: { textContent: _vm._s(_vm.input) },
                          }),
                          _vm._v(" "),
                          _vm.parent_sid !== null && _vm.call_status !== null
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    "Call Status : " + _vm._s(_vm.call_status)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "media d-flex align-items-center" },
                      [
                        _c("b-form-checkbox", {
                          attrs: { checked: _vm.gdpr, switch: "" },
                          on: {
                            change: function ($event) {
                              return _vm.gdprToggle()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "h6",
                          { staticClass: "font-weight-bolder mr-auto mb-0" },
                          [
                            _vm._v(
                              "\n              GDPR Compliance\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input,
                    expression: "input",
                  },
                ],
                staticClass: "form-control mb-3",
                attrs: { type: "tel", placeholder: "Enter phone number" },
                domProps: { value: _vm.input },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.input = $event.target.value
                    },
                    _vm.onInputChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c("SimpleKeyboard", {
                attrs: { input: _vm.input, theme: _vm.theme },
                on: { onChange: _vm.onChange, onKeyPress: _vm.onKeyPress },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "softphone-section" },
            [_c("SoftPhone", { ref: "phoneInfo" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "text-uppercase mb-0" }, [
        _vm._v("\n        Phone Dialer\n      "),
      ]),
      _vm._v(" "),
      _c("small", [_vm._v("Dial number with country code")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }