var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SiteDetails" },
    [
      _c(
        "ValidationObserver",
        { ref: "SiteObserver" },
        [
          _c(
            "b-form-group",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("label", { attrs: { for: "input-none" } }, [
                      _vm._v("Address Line 1:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "required",
                          name: "Address 1",
                          immediate: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ valid, errors }) {
                              return [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "readOnlyInput",
                                    disabled: _vm.readonly,
                                    readonly: _vm.readonly,
                                    state: errors[0]
                                      ? false
                                      : valid
                                      ? true
                                      : null,
                                    placeholder: "Address 1",
                                  },
                                  model: {
                                    value: _vm.SiteDetails.address1,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.SiteDetails, "address1", $$v)
                                    },
                                    expression: "SiteDetails.address1",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  { attrs: { id: "inputLiveFeedback" } },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(errors[0]) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("label", { attrs: { for: "input-none" } }, [
                      _vm._v("Address Line 2:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "readOnlyInput",
                          disabled: _vm.readonly,
                          readonly: _vm.readonly,
                          placeholder: "Address 2",
                        },
                        model: {
                          value: _vm.SiteDetails.address2,
                          callback: function ($$v) {
                            _vm.$set(_vm.SiteDetails, "address2", $$v)
                          },
                          expression: "SiteDetails.address2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("label", { attrs: { for: "input-none" } }, [
                      _vm._v("City:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "required",
                          name: "City",
                          immediate: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ valid, errors }) {
                              return [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "readOnlyInput",
                                    disabled: _vm.readonly,
                                    readonly: _vm.readonly,
                                    state: errors[0]
                                      ? false
                                      : valid
                                      ? true
                                      : null,
                                    placeholder: "City",
                                  },
                                  model: {
                                    value: _vm.SiteDetails.city,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.SiteDetails, "city", $$v)
                                    },
                                    expression: "SiteDetails.city",
                                  },
                                }),
                                _vm._v(" "),
                                _c("b-form-invalid-feedback", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(errors[0]) +
                                      "\n                        "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("label", { attrs: { for: "input-none" } }, [
                      _vm._v("Postcode:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "required",
                          name: "Postcode",
                          immediate: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ valid, errors }) {
                              return [
                                _c("b-form-input", {
                                  attrs: {
                                    disabled: _vm.readonly,
                                    state: errors[0]
                                      ? false
                                      : valid
                                      ? true
                                      : null,
                                    readonly: _vm.readonly,
                                    placeholder: "Post Code",
                                  },
                                  model: {
                                    value: _vm.SiteDetails.postcode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.SiteDetails, "postcode", $$v)
                                    },
                                    expression: "SiteDetails.postcode",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  { attrs: { id: "inputLiveFeedback" } },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(errors[0]) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }