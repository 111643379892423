var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Collaborators" },
    [
      !_vm.removeCollabSwitch
        ? [
            _c("b-row", { attrs: { cols: "12" } }, [
              _c(
                "h6",
                { staticClass: "align-self-center cursor-pointer ml-1 mb-0" },
                [_vm._v("\n                Collaborators:\n            ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { xs: "12" } },
                  [
                    _c(
                      "b-avatar-group",
                      { staticClass: "mt-2", attrs: { size: "lg" } },
                      [
                        _vm._l(_vm.sortedCollaborators, function (avatar) {
                          return _c("b-avatar", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.bottom",
                                value: avatar.name,
                                expression: "avatar.name",
                                modifiers: { hover: true, bottom: true },
                              },
                            ],
                            key: avatar.id,
                            class: [
                              "pull-up",
                              { "custom-badge-class": avatar.pivot.is_primary },
                            ],
                            attrs: {
                              text: avatar.initials,
                              badge: "",
                              "badge-offset": "-3px",
                              "badge-top": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                !avatar.pivot.is_primary
                                  ? {
                                      key: "badge",
                                      fn: function () {
                                        return [
                                          _vm.canPermittedByRoleOrUser(
                                            "delete",
                                            "collaborator"
                                          )
                                            ? _c("b-icon", {
                                                attrs: {
                                                  variant: "danger",
                                                  icon: "x-circle-fill",
                                                  "font-scale": "1.2",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showRemoveCollaborator(
                                                      avatar
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.canPermittedByRoleOrUser(
                                            "reassign",
                                            "datamanager"
                                          )
                                            ? _c("b-icon", {
                                                attrs: {
                                                  "font-scale": "1.2",
                                                  icon: "check-circle-fill",
                                                  variant: "warning",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showInfoModal(
                                                      avatar
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        _vm._v(" "),
                        _c("b-avatar", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottom",
                              value: _vm.addCollab.fullName,
                              expression: "addCollab.fullName",
                              modifiers: { hover: true, bottom: true },
                            },
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.add_collaborator",
                              modifiers: { add_collaborator: true },
                            },
                          ],
                          staticClass: "pull-up addColButton",
                          attrs: { icon: _vm.addCollab.icon, button: "" },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-modal",
              {
                attrs: {
                  id: "add_collaborator",
                  "hide-footer": "",
                  centered: "",
                  size: "sm",
                  title: "Add Collaborator",
                },
              },
              [_c("addCollaborator")],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.removeCollabSwitch
        ? [
            _vm.removeCollabSwitch
              ? _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _vm._v(
                        "\n                Are you sure you want to remove "
                      ),
                      _c(
                        "b",
                        { staticClass: "text-primary bold font-medium-2" },
                        [_vm._v(_vm._s(_vm.removeCollabPerson.name))]
                      ),
                      _vm._v(" as a collaborator?\n            "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "removeNo",
                                    on: {
                                      click: function ($event) {
                                        _vm.removeCollabSwitch = false
                                      },
                                    },
                                  },
                                  [_vm._v("No")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "text-right", attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger" },
                                    on: { click: _vm.permRemove },
                                  },
                                  [_vm._v("Yes")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "base-modal",
        {
          attrs: {
            id: "make_primary_collaborator",
            title: _vm.infoModal.title,
            centered: "",
            size: "sm",
          },
          on: { hide: _vm.resetInfoModal },
        },
        [
          _c("p", {
            staticClass: "float-left",
            domProps: { innerHTML: _vm._s(_vm.infoModal.message) },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-3 float-right",
                  on: {
                    click: function ($event) {
                      return _vm.cancel("make_primary_collaborator")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              !_vm.infoModal.is_primary
                ? _c(
                    "b-button",
                    {
                      staticClass: "mt-3 float-right",
                      staticStyle: { "margin-right": "10px" },
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.makePrimaryCollabarator(
                            _vm.infoModal.content
                          )
                        },
                      },
                    },
                    [_vm._v("Yes")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "base-modal",
        {
          attrs: {
            id: "remove_collaborator",
            title: _vm.infoModal.title,
            centered: "",
            size: "sm",
          },
          on: { hide: _vm.resetInfoModal },
        },
        [
          _c("p", {
            staticClass: "float-left",
            domProps: { innerHTML: _vm._s(_vm.infoModal.message) },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-3 float-right",
                  on: {
                    click: function ($event) {
                      return _vm.cancel("remove_collaborator")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              !_vm.infoModal.is_primary
                ? _c(
                    "b-button",
                    {
                      staticClass: "mt-3 float-right",
                      staticStyle: { "margin-right": "10px" },
                      attrs: { variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.removetheCollaborator(
                            _vm.infoModal.content
                          )
                        },
                      },
                    },
                    [_vm._v("Yes")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }