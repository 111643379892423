var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "viewContract" },
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { md: "4", xl: "4" } },
            [
              _c(
                "b-card",
                [
                  _c("h1", [_vm._v("Contract Information")]),
                  _vm._v(" "),
                  _vm.record
                    ? _c("contractDetails", { attrs: { contract: _vm.record } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4", xl: "4" } },
            [
              _c("b-card", { staticClass: "card" }, [
                _c("h1", [_vm._v("PDF VIEW")]),
                _vm._v(" "),
                _c("p", [_vm._v("NA")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4", xl: "4" } },
            [
              _c("b-card", { staticClass: "card" }, [
                _c("h1", [_vm._v(" Deal Information")]),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "span",
                    [
                      _c("b-badge", { attrs: { variant: "secondary" } }, [
                        _vm._v("External"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }