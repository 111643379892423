var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SendLOA" },
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c("v-select", {
                staticClass: "selectOptions col-12",
                attrs: {
                  options: _vm.contacts,
                  reduce: (full_name) => full_name.id,
                  "close-on-select": "",
                  label: "full_name",
                  placeholder: "Select Contact to Send Document to.",
                  searchable: "",
                },
                model: {
                  value: _vm.selectContact,
                  callback: function ($$v) {
                    _vm.selectContact = $$v
                  },
                  expression: "selectContact",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("v-select", {
                staticClass: "selectOptions col-12",
                attrs: {
                  options: _vm.LoaType,
                  "close-on-select": "",
                  placeholder: "Select Document Template",
                  searchable: "",
                },
                model: {
                  value: _vm.selectDocument,
                  callback: function ($$v) {
                    _vm.selectDocument = $$v
                  },
                  expression: "selectDocument",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-button",
                { staticClass: "text-center", on: { click: _vm.SendViaEmail } },
                [_vm._v("\n                Generate Document\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }